import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const DEBUG = false;

interface TTrefferCountMessage {
  treffer: number
  lines: [number, number]  
}

interface TTrefferCountInfo {
  treffer: string
  lines: string
}

@Injectable({
  providedIn: 'root'
})
export class TrefferCountService {
  
  private trans = {
    treffer: $localize`:@@page.ergebnis.treffer.count.treffer:`,
    zeilen:  $localize`:@@page.ergebnis.treffer.count.zeilen:`,
  };
  
  private infoMessage$ = new BehaviorSubject<TTrefferCountInfo>({ treffer: '', lines: ''});
  
  public info$ = this.infoMessage$.asObservable();

  public message (msg: TTrefferCountMessage) {
    DEBUG && console.log('TrefferCountService.message', msg);
    this.infoMessage$.next({
      treffer: `${msg.treffer} ${this.trans.treffer}`,
      lines:   msg.treffer ? this.trans.zeilen + ` ${msg.lines[0]}-${msg.lines[1]}` : ''
    });
  }

}
