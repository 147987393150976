import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

// https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle

interface ICoords {
  x: number
  y: number
}

const DEBUG = false;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'pr-atom-quality-circle',
  template: `
    <span
      role="meter"
      [title]="title"
      [attr.aria-valuenow]="quality"
      [attr.aria-valuemin]="0"
      [attr.aria-valuemax]="100"
      [attr.aria-label]="title"
      >
      @if (!lightVersion) {
        <svg xmlns="http://www.w3.org/2000/svg"
          attr.viewBox="0 0 {{ size }} {{ size }}"
          [attr.width]="size"
          [attr.height]="size"
          style="margin: 0; padding: 0;"
          >
          <path fill="none"        stroke="#C8C8C8" [attr.stroke-width]="stroke" [attr.d]="circle_back"/>
          <path fill="none" [attr.stroke]="color"   [attr.stroke-width]="stroke" [attr.d]="circle_fore"/>
          <text
            [attr.x]="text.x" [attr.y]="text.y"
            dominant-baseline="middle" text-anchor="middle"
            [attr.font-size]="text.size"
            [attr.fill]="textcolor"
          >
            {{ text.label }}
          </text>
        </svg>
      }
      @if (lightVersion) {
        <svg xmlns="http://www.w3.org/2000/svg"
          attr.viewBox="0 0 {{ size }} {{ size }}"
          [attr.width]="size"
          [attr.height]="size"
          style="margin: 0; padding: 0;"
          >
          <path fill="none"        stroke="#C8C8C8" [attr.stroke-width]="stroke"   [attr.d]="circle_back"/>
          <path fill="none"        stroke="#888888" [attr.stroke-width]="stroke"   [attr.d]="circle_middle"/>
          <path fill="none" [attr.stroke]="color"   [attr.stroke-width]="stroke-1" [attr.d]="circle_fore"/>
          <text
            [attr.x]="text.x" [attr.y]="text.y"
            dominant-baseline="middle" text-anchor="middle"
            [attr.font-size]="text.size"
            [attr.fill]="textcolor"
          >
            {{ text.label }}
          </text>
        </svg>
      }
    </span>
    `,
  styleUrls: ['./quality-circle.atom.scss']
})
export class QualityCircleAtom implements OnChanges {

  public trans = {
    aria_label: $localize`:@@app.atom.quality.aria.label:`
  };

  public circle_back = '';
  public circle_fore = '';
  public circle_middle = '';
  public text = {
    x: 0,
    y: 0,
    label: '',
    size: '100%',
  }

  @Input() public quality: string | number | undefined;
  @Input() public title   = this.trans.aria_label;
  @Input() public size    = 60;
  @Input() public stroke  = 6;
  @Input() public color   = '#446688';
  @Input() public textcolor    = '#000';
  @Input() public lightVersion = false;

  ngOnChanges (): void {

    if (this.quality !== null) {

      const quality = parseInt(String(this.quality), 10);

      // 359.9 avoids non visible if 100%
      this.circle_fore = this.renderArc(this.size / 2, this.size / 2 + 2, this.size / 2 - this.stroke, 0, quality / 100 * 359.9);
      this.circle_back = this.renderArc(this.size / 2, this.size / 2 + 2, this.size / 2 - this.stroke, 0, 359.9);

      if(this.lightVersion){
        this.circle_middle =  this.renderArc(this.size / 2, this.size / 2 + 2, this.size / 2 - this.stroke, 0, 359.9);
      }

      this.text = {
        x:     this.size / 2,
        y:     this.size / 2 + 6,
        label: this.size > 40 ? String(quality) : '',
        size: `${this.size / 60 * 100}%`
      };

    }

  }

  polarToCartesian (centerX: number, centerY: number, radius: number, angleInDegrees: number): ICoords {

    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };

  }

  renderArc (x: number, y: number, radius: number, startAngle: number, endAngle: number): string {

      const start = this.polarToCartesian(x, y, radius, endAngle);
      const end   = this.polarToCartesian(x, y, radius, startAngle);
      const flag  = endAngle - startAngle <= 180 ? '0' : '1';

      return [
        'M', start.x, start.y,
        'A', radius, radius, 0, flag, 0, end.x, end.y
      ].join(' ');

  }

}
