<div class="page page-feedback drv-mt-sm--01">

  <!-- BACK-LINK -->
  <pr-atom-page-backlink
    [route]="[i18n.locale, 'start']"
  >
    {{ trans.back_start_page }}
  </pr-atom-page-backlink>

  <!-- TITLE -->
  <pr-atom-page-title i18n="@@page.feedback.title.long">
    &#64;&#64;page.feedback.title.long
  </pr-atom-page-title>

  <form [formGroup]="form">

    <div drv-row class="drv-mt-sm--02">
      <div drv-col [sm]="4" [md]="{ span: 10, offset: 1 }" [lg]="{ span: 8, offset: 2 }" >

        <h2 i18n="@@page.feedback.formular.header" class="drv-mb-sm--02">
          &#64;&#64;page.feedback.formular.header
        </h2>

        <div [formGroupName]="trans.f1_short" class="drv-mt-sm--03">

          <drv-inputgroup
            grouplabel="{{ trans.f1_question1 }}"
            formControlName="checked"
            layout="block"
            backgroundUI="light"
            [required]="false"
          >
            <drv-checkbox id="f1_answer1" [value]="trans.f1_answer1">{{ trans.f1_answer1 }}</drv-checkbox>
            <drv-checkbox id="f1_answer2" [value]="trans.f1_answer2">{{ trans.f1_answer2 }}</drv-checkbox>
            <drv-checkbox id="f1_answer3" [value]="trans.f1_answer3">{{ trans.f1_answer3 }}</drv-checkbox>
            <drv-checkbox id="f1_answer4" [value]="trans.f1_answer4">{{ trans.f1_answer4 }}</drv-checkbox>
            <drv-checkbox id="f1_answer5" [value]="trans.f1_answer5">{{ trans.f1_answer5 }}</drv-checkbox>
          </drv-inputgroup>

        </div>

        <div [formGroupName]="trans.f2_short" class="drv-mt-sm--04">

          <drv-inputgroup
            grouplabel="{{ trans.f2_question1 }}"
            formControlName="checked"
            layout="block"
            backgroundUI="light"
            [required]="false"
          >
            <drv-checkbox id="f2_answer1" [value]="trans.f2_answer1">{{ trans.f2_answer1 }}</drv-checkbox>
            <drv-checkbox id="f2_answer2" [value]="trans.f2_answer2">{{ trans.f2_answer2 }}</drv-checkbox>
            <drv-checkbox id="f2_answer3" [value]="trans.f2_answer3">{{ trans.f2_answer3 }}</drv-checkbox>
            <drv-checkbox id="f2_answer4" [value]="trans.f2_answer4"
                        [ariaAttributes]="{ controls: 'textarea' }">{{ trans.f2_answer4 }}</drv-checkbox>
          </drv-inputgroup>
          <drv-textarea class="drv-mt-sm--02 drv-ml-sm--04"
            backgroundUI="light"
            [autogrow]="true"
            [visuallyHiddenLabel]="true"
            [rows]="1"
            [required]="false"
            (focus)="focus()"
            formControlName="other"
          ></drv-textarea>

        </div>

        <div [formGroupName]="trans.f3_short" class="drv-mt-sm--03">

          <drv-inputgroup
            grouplabel="{{ trans.f3_question1 }}"
            layout="block"
            backgroundUI="light"
            formControlName="checked"
            [required]="false"
          >
            <drv-checkbox id="f3_answer1" [value]="trans.f3_answer1">{{ trans.f3_answer1 }}</drv-checkbox>
            <drv-checkbox id="f3_answer2" [value]="trans.f3_answer2">{{ trans.f3_answer2 }}</drv-checkbox>
            <drv-checkbox id="f3_answer3" [value]="trans.f3_answer3">{{ trans.f3_answer3 }}</drv-checkbox>
            <drv-checkbox id="f3_answer4" [value]="trans.f3_answer4">{{ trans.f3_answer4 }}</drv-checkbox>
            <drv-checkbox id="f3_answer5" [value]="trans.f3_answer5"
                        [ariaAttributes]="{ controls: 'textarea' }">{{ trans.f3_answer5 }}</drv-checkbox>
          </drv-inputgroup>
          <drv-textarea class="drv-mt-sm--02 drv-ml-sm--04"
            backgroundUI="light"
            [autogrow]="true"
            [visuallyHiddenLabel]="true"
            [rows]="1"
            formControlName="other"
            [required]="false"
            (focus)="focus()"
          ></drv-textarea>

        </div>

      </div>
    </div>

    <div drv-row class="drv-mt-sm--03 drv-mb-sm--02">
      <div drv-col [sm]="4" [md]="{ span: 10, offset: 1 }" [lg]="{ span: 8, offset: 2 }" >

        <h2 i18n="@@page.feedback.datenschutz.header">
          &#64;&#64;page.feedback.datenschutz.header
        </h2>

        <p>
          <span i18n="@@page.feedback.datenschutz.text1">
            &#64;&#64;page.feedback.datenschutz.text1
          </span>
          &nbsp;
          <a [routerLink]="[ '/', 'de', 'datenschutz']">
            <span i18n="@@page.feedback.datenschutz.text2.label">
              &#64;&#64;page.feedback.datenschutz.text2.label
            </span>
          </a>
          <span i18n="@@page.feedback.datenschutz.text3">
            &#64;&#64;page.feedback.datenschutz.text3
          </span>
        </p>

        <div class="drv-mt-sm--02">
          <drv-inputgroup
            layout="block"
            backgroundUI="light"
            formControlName="agreed"
            [required]="true"
            [errorText]="[{ message: trans.form_error }]"
          >
            <drv-checkbox
              formControlName="agreed"
              [required]="true"
            >
              <span i18n="@@page.feedback.datenschutz.checkbox.label">
                &#64;&#64;page.feedback.datenschutz.checkbox.label
              </span>
            </drv-checkbox>
          </drv-inputgroup>
        </div>

      </div>
    </div>

    <div drv-row class="drv-mt-sm--03">
      <div drv-col [sm]="4" [md]="{ span: 10, offset: 1 }" [lg]="{ span: 8, offset: 2 }" >

        <drv-button
          [disabled]="!form.value.agreed"
          label="{{ trans.senden }}"
          iconPosition="left"
          iconType="info"
          backgroundUI="light"
          (onClick)="send()"
        ></drv-button>

      </div>
    </div>

  </form>

</div>
