import { DomSanitizer } from '@angular/platform-browser';
import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  SecurityContext,
  SimpleChanges
} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[prHighlight]'
})
export class HighlightDirective implements OnChanges {

  @Input() prHighlight = '';
  @Input() caseSensitive = false;

  @HostBinding('innerHtml') content: string | undefined;

  constructor(private el: ElementRef, private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges) {

    if (this.el?.nativeElement) {

      if ('prHighlight' in changes || 'caseSensitive' in changes) {

        const text = (this.el.nativeElement as HTMLElement).textContent;

        if (text) {

          if (!this.prHighlight) {
            this.content = text;

          } else {
            const regex = new RegExp(
              this.prHighlight,
              this.caseSensitive ? 'g' : 'gi'
            );

            const newText = text.replace(regex, (match: string) => {
              return `<mark class='pr-highlight'>${match}</mark>`;
            });

            const sanitized = this.sanitizer.sanitize(
              SecurityContext.HTML,
              newText
            );

            this.content = sanitized ?? undefined;

          }

        }

      }

    }

  }

}
