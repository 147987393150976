<drv-searchbar
  #pr_autocomplete_searchbar
  id="pr-autocomplete-searchbar"
  name="pr-autocomplete-searchbar"
  buttonTextClear=""
  data-e2e="searchbarAutocomplete"
  [labelText]="labelText"
  [buttonText]="buttonText"
  [placeholderText]="placeholderText"
  [hasHint]="hasHint"
  [hintText]="hintText"
  [required]="true"
  [toggletipTemplate]="toggletipTemplate"
  (onSearch)="onSearch($event)"
  (input)="onInput($event)"
></drv-searchbar>

<drv-dropdown
  #pr_autocomplete_dropdown
  id="pr-autocomplete-dropdown"
  class="drv-mb-sm--04"
  data-e2e="comboAutocomplete"
  label=""
  [style.display]="enabled ? 'block' : 'none'"
  [options]="autoproposals"
  [required]="true"
></drv-dropdown>
