import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FullscreenOptions, DivIcon, Point, MarkerClusterGroup, Map, Marker, Layer, control, MapOptions, MarkerClusterGroupOptions, LeafletEvent, MarkerCluster, Circle, Control, FeatureGroup} from 'leaflet';
import { Subscription } from 'rxjs';

import { IDrvComboSortOption, IErgFachabteilung, IErgebnisResults, IMapHomeFeature, IPREventPayload, IPRMarker, IPagination, TMapEvent, TTableSort, TTableSortIndex, TTableSortOrder } from '@pr/core/interfaces';
import { I18n } from '@pr/core/i18n.module';
import { StateService } from '@pr/core/state.service';
import { ConfigService } from '@pr/core/config.service';
import { TablePaginationCell } from '@pr/cells/table-pagination/table-pagination.cell';
import { NgxFullscreenDirective } from '@pr/core/directives/ngx-fullscreen.directive';

import { AppService } from 'src/app/app.service';
import { DrvDropdownOption } from '@drv-ds/drv-design-system-ng';
import { ClusterMapPopupService } from './cluster-map-popup.service';

const DEBUG = false;

interface TFeatures {
  umkreis: { label: string, layer: Circle | undefined }
  home:    { label: string, layer: Marker | undefined }
}

@Component({
  selector: 'pr-cell-cluster-map',
  templateUrl: './cluster-map.cell.html',
  styleUrls: ['./cluster-map.cell.scss']
})
export class ClusterMapCell  implements OnChanges, OnDestroy { // AfterViewInit, 
  
  public comboSortOptions: IDrvComboSortOption[] = [
    { label: $localize`:@@page.ergebnis.mobile.name.auf:`,      value: '0,asc'  },
    { label: $localize`:@@page.ergebnis.mobile.name.ab:`,       value: '0,desc' },
    { label: $localize`:@@page.ergebnis.mobile.plz.auf:`,       value: '1,asc'  },
    { label: $localize`:@@page.ergebnis.mobile.plz.ab:`,        value: '1,desc' },
    { label: $localize`:@@page.ergebnis.mobile.qual.auf:`,      value: '3,asc'  },
    { label: $localize`:@@page.ergebnis.mobile.qual.ab:`,       value: '3,desc' },
    { label: $localize`:@@page.ergebnis.mobile.warte.auf:`,     value: '4,asc'  },
    { label: $localize`:@@page.ergebnis.mobile.warte.ab:`,      value: '4,desc' },
  ];
  
  public styleClusterContainerLayout = {
    hidden:  false,
    width:  '100%',
    height: '80vh',
    backgroundColor: 'white', // turns black in FS
  }
  
  public styleMapLayout = {
    hidden:  false,
    width:  '100%',
    height: '80vh'
  }
  
  public styleTableLayout = {
    display:  'flex',
    flex:     '0 0 320px',  // sm
    padding:  '0',          // not fullscreen
  }
  
  private mediaSubscriber$: Subscription;
  
  public map: undefined | Map = undefined;
  public leafletFeatures: Layer[] = [];
  public leafletOptions: MapOptions;
  public leafletLayersControl: any;
  public leafletFitBoundsOptions: any;
  public fullscreenOptions: FullscreenOptions;
  private zoomControlOptions: Control.ZoomOptions;
  private markerCluster: MarkerClusterGroup;
  private markerClusterOptions: MarkerClusterGroupOptions;
  private popupMarker: Marker | undefined;
  private onClusterClick: (event: any) => false | void;
  
  public selected = {
    sort:        [],
    rowsperpage: [],
  } as Record<'rowsperpage' | 'sort', DrvDropdownOption[] | []>;
  
  public readonly trans = {
    standort:           $localize`:@@app.map.standort:`,
    einrichtungen:      $localize`:@@app.map.einrichtungen:`,
    accessibility_hint: $localize`:@@app.map.accessibility.hint:`,
    oepnv:              $localize`:@@app.map.oepnv:`,
    railway:            $localize`:@@app.map.railway:`,
    umkreis:            $localize`:@@app.map.umkreis:`,
    km:                 $localize`:@@app.map.km:`,
    osm:                $localize`:@@app.map.osm:`,
    zoom_in_title:      $localize`:@@app.map.zoom.in:`,
    zoom_out_title:     $localize`:@@app.map.zoom.out:`,
    fullscreen:         $localize`:@@app.map.fullscreen:`,
    fullscreen_out:     $localize`:@@app.map.fullscreen.out:`,
    qualitaet_nodata:   $localize`:@@page.ergebnis.table.qualitaet.nodata:`,
    title_quality_fa:   $localize`:@@page.ergebnis.circle.title.fachabteilung:`,
    title_quality_vg:   $localize`:@@page.ergebnis.circle.title.vergleichsgruppe:`,
    sort_placeholder:   $localize`:@@page.ergebnis.sort.placeholder:`,
    zeilen:             $localize`:@@page.ergebnis.treffer.count.zeilen:`,
    please_choose:      'bitte auswählen',
    label_sort:         'Sortierung',
  };

  private features: TFeatures = {
    home:    { label: this.trans.standort, layer: undefined },
    umkreis: { label: this.trans.umkreis,  layer: undefined }
  };

  private pagination: IPagination | undefined = undefined;
  
  private resizeTimeout = 0;
  private resizeThrottleTimeout = 100;
  
  // all results of user search
  public data: IErgFachabteilung[] | undefined;
  // all results filtered by map
  public dataTable: IErgFachabteilung[] | undefined;
  // all results filtered by map for current page
  public dataTablePage: IErgFachabteilung[] | undefined;
  
  @Input() public results: IErgebnisResults | undefined;
  @Output() private events: EventEmitter<TMapEvent> = new EventEmitter<TMapEvent>();
  @ViewChild('fullscreen') private fullscreen!: NgxFullscreenDirective;
  @ViewChild('container') private container!: ElementRef;
  @ViewChild('paginator') private paginator!: TablePaginationCell;
  @HostListener('window:resize', ['$event']) onWindowResize() { this.onResize(); }
  
  constructor (
    private readonly popupService: ClusterMapPopupService,
    private readonly cd:    ChangeDetectorRef,
    private readonly app:   AppService,
    public readonly i18n:   I18n,
    public readonly state:  StateService,
    public readonly config: ConfigService,
  ) {
    
    this.leafletOptions = {
      layers: [this.config.mapConfig.layerDefault],
      zoom:   this.config.mapConfig.zoomDefault,
      center: this.config.mapConfig.centerDefault,
      zoomControl: false,
    };

    this.leafletLayersControl = {
      baseLayers: {
        [this.trans.osm]:     this.config.mapConfig.layerDefault,
        [this.trans.oepnv]:   this.config.mapConfig.layerOEPNV,
        [this.trans.railway]: this.config.mapConfig.layerRailway
      },
      overlays: { } as Record<string, Layer>
    };

    
    this.leafletFitBoundsOptions = { 
      maxZoom: 12, 
      animate: false 
    };
    
    this.zoomControlOptions = {
      position: 'topleft',
      zoomInTitle:  this.trans.zoom_in_title,
      zoomOutTitle: this.trans.zoom_out_title,
    };
    
    this.fullscreenOptions = {
      position: 'topleft'
    };
    
    // init empty markercluster
    this.markerClusterOptions = {
      spiderfyOnMaxZoom:   false,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: false,
      iconCreateFunction:  this.iconCreateFunction.bind(this),
    };
    this.markerCluster  = new MarkerClusterGroup(this.markerClusterOptions);
    this.onClusterClick = this.clusterClick.bind(this);  
    
    // adjust table width to window width
    this.mediaSubscriber$ = this.app.media$.subscribe(breakpoint => {
      
      if (breakpoint === 'sm' || breakpoint === 'ts') {
        this.styleTableLayout = { flex: '0 0 0', padding: '0', display: 'none' };
        
      } else if (breakpoint === 'md' ) {
        this.styleTableLayout = { flex: '0 0 320px', padding: '0', display: 'flex'  };
        
      } else {
        this.styleTableLayout = { flex: '0 0 400px', padding: '0', display: 'flex'  };

      }
      
      DEBUG && console.log('ClusterMap.media$', breakpoint);
      
    });

  }
  
  ngOnDestroy(): void {
    this.mediaSubscriber$?.unsubscribe();
  }
  
  public ngOnChanges (changes: SimpleChanges): void {
    
    if (changes['results'] && changes['results']['currentValue']) {
      DEBUG && console.log('ClusterMap.changes.cluster.in', changes['results']);
      if (this.results) {
        this.updateCluster(this.results.fachabteilungen);
        this.updateHome(this.results.homeFeature);
      }
    } 

  }
    
  private resize () {
    
    const t0 = Date.now();
      
    if (this.fullscreen.isFullscreen) {
      // more width + padding
      this.container.nativeElement.style.height = window.innerHeight + 'px';
      this.styleMapLayout.height    = window.innerHeight + 'px';
      this.styleTableLayout.padding = '0 0 0 1rem';
    
    } else {
      this.container.nativeElement.style.height = '80vh';
      this.styleMapLayout.height    = '80vh';
      
    }
    
    this.cd.detectChanges();
    this.map?.invalidateSize(true);
    
    if ( (this.map?.getZoom() ?? 0) < 10) {
      this.fitCluster();
    }     
      
    false && console.log('ClusterMap.resize', Date.now() - t0, 'ms', this.fullscreen.isFullscreen ? 'Full' : 'Small', this.styleMapLayout.height);    
    
  }
  
  public onResize () {
    
    if (this.resizeTimeout) {
      window.clearTimeout(this.resizeTimeout);
    }
    
    this.resizeTimeout = window.setTimeout(this.resize.bind(this), this.resizeThrottleTimeout)
        
  }
    
  private updateCluster (data: IErgFachabteilung[]): void { 
    
    const map = this.map;
    let counter = 0;
    
    this.markerCluster.clearLayers();
    this.data = data;
    
    data.forEach( item => {
      
      counter += 1;
    
      const markerEinrichtung: IPRMarker = new Marker (
        [item.lat, item.lon], {
          alt: '',
          keyboard: false,
          riseOnHover: true,
          icon: this.config.mapConfig.iconCluster,
        }
      );
      
      // assign fachabteilung to marker for popup
      markerEinrichtung.data = item;
      
      markerEinrichtung.on('click', (event: LeafletEvent) => {
        
        DEBUG && console.log('markerClick', event, this, markerEinrichtung);

        markerEinrichtung.unbindPopup();
        markerEinrichtung.bindPopup(this.popupService.htmlMultiPopup([markerEinrichtung]), {})
        markerEinrichtung.openPopup();
        
        this.events.emit({ map, type: 'markerclick', payload: event } as TMapEvent );
        
      });
            
      this.markerCluster.addLayer(markerEinrichtung);
      
    });
    
    // UI map toggle for Fachabteilungen
    const labelOverlay = this.trans.einrichtungen;
    this.leafletLayersControl.overlays[labelOverlay]  = this.markerCluster;
    
    // update sort dropdown
    const value = this.state.get<TTableSort>('sort');
    const label = this.comboSortOptions.find( option => option.value === value )?.label ?? 'unknown';
    this.selected.sort = [{ label, value }];
    
    // zoom & pan map to show all markers
    this.fitCluster();
    
    // reduce tableData to what is within map and paginated
    this.filterTableRows();
    
    DEBUG && console.log('ClusterMap.updateCluster.out', counter);
    
  }
  
  private updateHome (homeFeature: IMapHomeFeature  | undefined): void { 

    // remove previous features from map and layer control
    this.features.home.layer    && this.map?.removeLayer(this.features.home.layer);
    this.features.umkreis.layer && this.map?.removeLayer(this.features.umkreis.layer);
    delete this.leafletLayersControl.overlays[this.features.home.label];
    delete this.leafletLayersControl.overlays[this.features.umkreis.label];
        
    if (homeFeature) {
      
      const { lat, lon, plz, radius } = homeFeature;

      if (plz && radius) {
        
        const htmlPopup  = `<p>${this.trans.standort} (${plz})</p>`;
        
        const icon = new DivIcon({
          html: `
            <svg style="color: darkblue" role="img" focusable="false" aria-label="Standort" width="32" height="32" viewBox="0 0 32 32">
              <use xlink:href="assets/images/sprites/sprite.svg#position-finder"></use>
            </svg>`,
          className: '', iconSize: new Point(32, 32) 
          
        });
        
        this.features.home.label = `${this.trans.standort} (${plz})`;
        this.features.home.layer = new Marker ([lat, lon], { 
            icon: this.config.mapConfig.iconRed, 
            // icon,
            zIndexOffset: 1000, 
            alt: this.features.home.label
          })
          .bindPopup(htmlPopup)
          .openPopup()
        ;
        
        this.map?.addLayer(this.features.home.layer);
        this.leafletLayersControl.overlays[this.features.home.label] = this.features.home.layer;
        
        this.features.umkreis.label = `${this.trans.umkreis} (${radius}${this.trans.km})`;
        this.features.umkreis.layer = new Circle ( [ lat, lon ], { 
          radius:       radius * 1_000,
          fillColor :   '#083163',
          fillOpacity : 0.1,
          stroke:       true,
          color:        '#6983a1'
        });

        this.map?.addLayer(this.features.umkreis.layer);
        this.leafletLayersControl.overlays[this.features.umkreis.label] = this.features.umkreis.layer;
        
      }

    } 
      
  }
  
  private shareLatLon (markers: IPRMarker[]) {
    
    if (markers.length > 32) { return false; }
    
    const precision = 3;
    const lat = markers[0].data.lat.toFixed(precision);
    const lon = markers[0].data.lon.toFixed(precision);
    
    return markers.every( (m: any) => {
      return (
        m.data.lat.toFixed(precision) === lat && 
        m.data.lon.toFixed(precision) === lon
      );
    });
    
  }
  
  private iconCreateFunction (cluster: MarkerCluster) {
    
    const markers = cluster.getAllChildMarkers();
    const length  = markers.length.toString().length;
    
    function createDivIcon (html: string, className: string, pointX: number, pointY: number): DivIcon {
      return new DivIcon({ html, className, iconSize: new Point(pointX, pointY) })
    }
    
    if (this.shareLatLon(markers)) {
      const html  = '<div class="cluster-marker-single">' + markers.length + '</div>';
      return createDivIcon(html, 'cluster-back-single', 34, 43);
        
    } else {
      const html    = '<div class="cluster-circle-' + length + '">' + markers.length + '</div>';
      return (
        length === 1 ? createDivIcon(html, 'cluster-back-1', 40, 40) :
        length === 2 ? createDivIcon(html, 'cluster-back-2', 48, 48) :
        length === 3 ? createDivIcon(html, 'cluster-back-3', 56, 56) :
                       createDivIcon(html, 'cluster-back-4', 64, 64)
      );
      
    }
    
  }
  
  private fitCluster (group?: FeatureGroup) {
    
    const padding = this.config.cluster.padding;
    
    if (this.map && this.data?.length) {
      
      false && DEBUG && console.log('ClusterMap.fitCluster', this.data?.length);
      
      const bounds = (group ?? this.markerCluster).getBounds().pad(padding);
      if (bounds.isValid()) {
        this.map?.fitBounds(bounds);
      }
      
    }
    
  }
      
  private clusterClick (event: LeafletEvent): false | void {
    
    const layer: MarkerClusterGroup = event.propagatedFrom;
    const markers: IPRMarker[] = layer.getAllChildMarkers();
    const same    = this.shareLatLon(markers);
    const zoom    = this.map?.getZoom();
    const group   = new FeatureGroup(markers);
    
    DEBUG && console.log('clusterClick', same ? 'same' : 'notsame', 'zoom', zoom);
    
    // just zoom min
    if (!same && this.map) {
      this.fitCluster(group);
      return;
      
    // show popup
    } else if (this.map) {
    
      const { lon, lat } = markers[0].data;
      
      this.popupMarker && this.popupMarker.unbindPopup();
      this.popupMarker = new Marker([lat, lon], { 
        opacity: 0,
        icon: new DivIcon({
          // invisible icon
          className: 'leaflet-mouse-marker',
          iconAnchor: [20, 20],
          iconSize:   [40, 40]
        })
      }).addTo(this?.map);
      
      this.popupMarker.bindPopup(this.popupService.htmlMultiPopup(markers), {
        maxWidth:  320,
        minWidth:  320,
        maxHeight: 320,
        autoPan:   true,
      }).openPopup();
      
    }
    
  }

  // public onSelectSort (param: [string]) {
  public onSelectSort (param: DrvDropdownOption[]) {
    
    // debugger;
    
    // const sort = param[0].value;
    
    // send new sort upstream
    this.events.emit({
      type: 'sort',
      map: this.map,
      // payload: { sort: param } as IPREventPayload,
      payload: { sort: param } as IPREventPayload,
    });

  }

  public onMapReady (map: Map): void {
    
    this.map = map;
  
    const removeMarkerPopup = () => {
      this.map?.closePopup();
      this.popupMarker && this.map?.removeLayer(this.popupMarker);
      this.popupMarker = undefined;
    };
    
    this.markerCluster.addTo(this.map);
    this.markerCluster.on('clusterclick', this.onClusterClick);

    this.map.addControl(control.zoom(this.zoomControlOptions));
    
    this.map.on('startfullscreen', () => {
      DEBUG && console.log('startfullscreen', this.map?.isFullscreen());
      this.fullscreen.enter();
    });
    
    this.map.on('stopfullscreen', () => {
      DEBUG && console.log('stopfullscreen', this.map?.isFullscreen());
      this.fullscreen.exit();
    });
    
    this.map.on('moveend', (event: LeafletEvent) => {
      
      const payload = Object.assign(event, { hits: this.dataTable?.length });
      
      this.filterTableRows();
      
      this.events.emit({
        map: this.map, type: 'moveend', payload
      } as TMapEvent);
      
      this.cd.detectChanges();
      false && DEBUG && console.log('moveend', this.dataTable?.length, 'marker');
      
    });
    
    this.map.on('zoomend', (event: LeafletEvent) => {
      
      const payload = Object.assign(event, { hits: this.dataTable?.length });
      const zoom    = this.map?.getZoom();
      
      // remove any popup
      removeMarkerPopup();      
            
      this.filterTableRows();
      
      this.events.emit({
        map: this.map, type: 'zoomend', payload
      } as TMapEvent);
      
      this.cd.detectChanges();
      false && DEBUG && console.log('zoomend', 'marker', this.dataTable?.length, 'zoom', zoom);
      
    });
    
    this.events.emit({
      map, type: 'mapready', payload: {}
    } as TMapEvent);
    
  }
  
  private filterTableRows () {
    
    const t0     = Date.now();
    const bounds = this.map?.getBounds();
  
    if (this.data && this.paginator) {
      this.dataTable = this.data
        .filter( item => bounds?.contains( { lat: item.lat, lng: item.lon } ))
      ;
      this.pagination = this.paginator.calulate(this.dataTable?.length);
      this.paginateTableRows();
    }
    
    DEBUG && console.log('ClusterMap.filterTableRows', 
      this.data?.length, this.dataTable?.length, Date.now() - t0, 'ms'
    );
    
  }
  
  private paginateTableRows () {
    
    if (this.dataTable) {
    
      if (!this.pagination) {
        this.pagination = this.paginator.calulate(this.dataTable.length)
      }
      
      const pos1   = this.pagination.first;
      const pos2   = this.pagination.last +1;
      this.dataTablePage = this.dataTable?.slice(pos1, pos2);    
      
      DEBUG && console.log('ClusterMap.paginateTableRows', 
        this.data?.length, this.dataTable?.length, this.dataTablePage?.length
      );
      
    }
    
  }
  
  public updatePagination (pagination: IPagination) {
    this.pagination = pagination;
    this.paginateTableRows();
  }

}
