import { Component } from '@angular/core';

// TODO: remove me

@Component({
  selector: 'pr-atom-map',
  templateUrl: './map.atom.html',
  styleUrls: ['./map.atom.scss']
})
export class MapAtom {


}
