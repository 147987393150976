<ng-template #template>

  <tr
    tabindex="0"
    class="hover pointer"
    aria-describedby="description"
    (keyup.enter)="onRowClick(fa?.idFach)"
    (click)="onRowClick(fa?.idFach)"
  >

    <td class="lh-130 einrichtung">
      <a [routerLink]="fa?.detailRoute" class="permalink">{{ fa?.einrichtungname }}</a>
      <div class="fasc-name">{{ fa?.name_ergebnis }}</div>
      <div class="fasc-spec">{{ fa?.spec_ergebnis }}</div>
    </td>

    <td class="adresse">
      <div class="nowrap">{{ fa?.plz }} {{ fa?.ort }}</div>
      <div class="nowrap">{{ fa?.strasse }} {{ fa?.hausnummer }}</div>
    </td>

    <td>{{ i18n.translate(fa?.durchfuehrung ?? '') }}</td>

    <td class="left">
      @if (fa?.qualitaet !== config.no_quality) {
        <pr-atom-quality-circle
          [quality]="fa?.qualitaet"
          [title]="fa?.isGkv ? trans.title_quality_vg : trans.title_quality_fa"
          [lightVersion]="!!fa?.isGkv"
          [color]="fa?.isGkv ? '#FFF' : '#446688'"
        ></pr-atom-quality-circle>
      }
      @if (fa?.qualitaet === config.no_quality) {
        <span class="bold">{{ trans.qualitaet_nodata }}</span>
      }
    </td>

    <td>
      <pr-atom-wartezeit [wzinfo]="[fa?.wartezeit, 'long']"></pr-atom-wartezeit>
    </td>

  </tr>

</ng-template>
