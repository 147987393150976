import { inject, Injectable } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
class DevelopmentService {
  canActivate (): boolean {
    return !environment.production;
  }
}


export const DevelopmentGuard: CanActivateFn = (): boolean => {
  return inject(DevelopmentService).canActivate();
}