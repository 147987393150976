import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
// import { Title } from '@angular/platform-browser';
import { ApiService } from '@pr/core/api.service';

import { I18n } from '@pr/core/i18n.module';
import { IFeedback } from '@pr/core/interfaces';
import { MetaService } from '@pr/core/meta.service';
import { StateService } from '@pr/core/state.service';

@Component({
  selector: 'pr-page-feedback',
  templateUrl: './feedback.page.html',
  styleUrls: ['./feedback.page.scss']
})
export class FeedbackPage {

  public trans = {
    'back_start_page':        $localize`:@@app.backlink.start.label:`,
    'title':                  $localize`:@@app.page.feedback.title:`,
    'senden':                 $localize`:@@page.feedback.form.button.label:`,
    'form_error':             $localize`:@@page.feedback.form.error:`,

    'f1_question1':           $localize`:@@page.feedback.frage1:`,
    'f1_short':               $localize`:@@page.feedback.frage1.short:`,
    'f1_answer1':             $localize`:@@page.feedback.frage1.antwort1:`,
    'f1_answer2':             $localize`:@@page.feedback.frage1.antwort2:`,
    'f1_answer3':             $localize`:@@page.feedback.frage1.antwort3:`,
    'f1_answer4':             $localize`:@@page.feedback.frage1.antwort4:`,
    'f1_answer5':             $localize`:@@page.feedback.frage1.antwort5:`,

    'f2_question1':           $localize`:@@page.feedback.frage2:`,
    'f2_short':               $localize`:@@page.feedback.frage2.short:`,
    'f2_answer1':             $localize`:@@page.feedback.frage2.antwort1:`,
    'f2_answer2':             $localize`:@@page.feedback.frage2.antwort2:`,
    'f2_answer3':             $localize`:@@page.feedback.frage2.antwort3:`,
    'f2_answer4':             $localize`:@@page.feedback.frage2.antwort4:`,

    'f3_question1':           $localize`:@@page.feedback.frage3:`,
    'f3_short':               $localize`:@@page.feedback.frage3.short:`,
    'f3_answer1':             $localize`:@@page.feedback.frage3.antwort1:`,
    'f3_answer2':             $localize`:@@page.feedback.frage3.antwort2:`,
    'f3_answer3':             $localize`:@@page.feedback.frage3.antwort3:`,
    'f3_answer4':             $localize`:@@page.feedback.frage3.antwort4:`,
    'f3_answer5':             $localize`:@@page.feedback.frage3.antwort5:`,

  }

  public form: FormGroup;
  
  // TODO: DS 4.0
  // public form = this.fb.group({
  //   [this.trans.f1_short]: this.fb.group({
  //     checked: [],
  //     other:   '',
  //   }),
  //   [this.trans.f2_short]: this.fb.group({
  //     checked: [],
  //     other:   '',
  //   }),
  //   [this.trans.f3_short]: this.fb.group({
  //     checked: [],
  //     other:   '',
  //   }),
  //   agreed: false,
  //   date: (new Date()).toLocaleString(),
  //   origin: location.host
  // });

  constructor (
    public readonly i18n:   I18n,
    public readonly state:  StateService,
    private readonly fb:    FormBuilder,
    private readonly api:   ApiService,
    private readonly meta:  MetaService
  ) {
    this.meta.addTags('feedback');
    
    this.form = this.fb.group({
      [this.trans.f1_short]: this.fb.group({
        checked: [],
        other:   '',
      }),
      [this.trans.f2_short]: this.fb.group({
        checked: [],
        other:   '',
      }),
      [this.trans.f3_short]: this.fb.group({
        checked: [],
        other:   '',
      }),
      agreed: false,
      date: (new Date()).toLocaleString(),
      origin: location.host
    });
  
  }

  public focus (): void {
    // do nothing
  }

  public send (): void {

    const body = this.form.value;
    const lang = this.i18n.locale;

    // TODO: ShowMessage
    // Clear Form after Send
    // Avoid to many clicks

    void this.api.post<IFeedback>('/feedback', body, { lang })
      .subscribe( res => {
        // console.log('Feedback.send', JSON.stringify(res, null, 2));
      })
    ;

  }

  public toggle (): void {
    // console.log('Feedback.toggle');
  }

}
