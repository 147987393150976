import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subject } from 'rxjs';
import { ConfigService } from './config.service';
import { ModalService } from '@drv-ds/drv-design-system-ng';
import { MatomoService } from './matomo.service';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root'
})
export class NpsTimeOutService {

  private notShown = true;
  private countdownId: any;
  private countdownValue: number = 0;

  userInactive: Subject<boolean> = new Subject();

  constructor(
    private readonly config: ConfigService,
    private readonly modalService: ModalService,
    private readonly matomo: MatomoService,
    private readonly state:  StateService,
  ) { }

  public init (router: Router) {

    clearTimeout(this.countdownId);

    const consent       = this.matomo.consent ?? false;


    // LIVE time span = 30 days; TEST = 60 seconds
    const minutesPerDay = 24 * 60;
    const minTimeSpanDays = this.config.isTestDomain
      ? (1 / minutesPerDay) // 0.00069
      : this.config.nps_timespan
    ;

    const npsDiffDays   = this.state.readNpsDiffDays();

    const npsFormAllowed = true; // consent && npsDiffDays > minTimeSpanDays;


    if (this.config.nps.enabled && npsFormAllowed) {

      router.events.pipe(
        filter( (e: any) => e instanceof NavigationEnd),
      ).subscribe((event: any) => {

        const path = event.urlAfterRedirects;
        const doMeasure = this.config.nps.routefragments.some( (frag) => path.includes(frag));

        clearTimeout(this.countdownId);

        if (doMeasure && this.notShown) {
          this.continueMeasure();
        }

        console.log('IDLE', event.urlAfterRedirects, doMeasure)

      })

    }

  }

  private openModal (){

    this.modalService.updateModal({
      id: 'nps_modal',
      open: true
    });

  }

  private continueMeasure() {

    this.countdownId = setInterval(() => {

      this.countdownValue++;
      console.log('Showing NPS in:', this.config.nps.timeout_secs - this.countdownValue, 'seconds');

      if (this.countdownValue > this.config.nps.timeout_secs) {
        clearInterval(this.countdownId);
        console.log('Showing NPS');
        // this.userInactive.next(true);
        this.notShown = false;
        this.openModal()
      }

    }, 1000);

  }

}
