import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { I18n } from '@pr/core/i18n.module';
import { ApiService } from '@pr/core/api.service';
import { ConfigService } from '@pr/core/config.service';
import { StateService } from '@pr/core/state.service';
import { MetaService } from '@pr/core/meta.service';

const DEBUG = false;

@Component({
  selector: 'pr-page-barrierefreiheit',
  templateUrl: './barrierefreiheit.page.html',
  styleUrls: ['./barrierefreiheit.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarrierefreiheitPage implements OnDestroy {

  public trans = {
    'app_title':          $localize`:@@app.title:`,
    'back_link':          $localize`:@@app.backlink.start.label:`,
    'title':              $localize`:@@app.page.barrierefreiheit.title:`,
    'back_start_page':    $localize`:@@app.backlink.start.label:`,
  };

  public markdown = '';

  private subscriber$: Subscription | undefined;

  constructor (
    public readonly config: ConfigService,
    public readonly i18n:   I18n,
    public readonly state:  StateService,
    private readonly route: ActivatedRoute,
    private readonly api:   ApiService,
    private readonly meta:  MetaService,
    private readonly cd:    ChangeDetectorRef
  ) {

    this.meta.addTags('barrierefreiheit');

    this.subscriber$ = this.route.data.subscribe(data => {

      const root   = this.config.environment.markdownroot;
      const locale = this.i18n.locale;
      const url    = `${root}/${locale}/${data['markdown'] as string}`;

      this.api.markdown(url).subscribe( markdown => {
        this.markdown = markdown;
        this.cd.detectChanges()
      });

    });

  }

  public ngOnDestroy (): void {
    this.subscriber$?.unsubscribe();
  }

  // from markdown helpers
  onFile (markdown: string | ArrayBuffer | null) {
    DEBUG && console.log('Barrierefreiheit.onFile', String(markdown).length);
    this.markdown = String(markdown);
  }

}
