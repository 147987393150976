import { Component } from '@angular/core';

@Component({
  selector: 'pr-cell-legend-wartezeit',
  templateUrl: './legend-wartezeit.cell.html',
  styleUrls: ['./legend-wartezeit.cell.scss']
})
export class LegendWartezeitCell {

  public trans = {

    header:    $localize`:@@app.wartezeitquality.legende.header:`,
    short:     $localize`:@@app.wartezeit.legende.short:`,
    moderat:   $localize`:@@app.wartezeit.legende.moderat:`,
    long:      $localize`:@@app.wartezeit.legende.long:`,
    gesperrt:  $localize`:@@app.wartezeit.legende.gesperrt:`,

  };

}
