// <reference types='./index.d.ts'/>
import { Map, Control, FullscreenOptions } from 'leaflet';
import { Component, OnDestroy, Input } from '@angular/core';

import './Leaflet.fullscreen.js';

@Component({
  selector: 'leaflet-fullscreen-control',
  template: '',
  styleUrls: []
})
export class FullscreenControlPlugin implements OnDestroy {
  
  private minsize = 1024;
  private _options: FullscreenOptions = {};
  private _map?: Map;
  public control?: Control.Fullscreen;

  @Input() set options(opt: FullscreenOptions) {
    this._options = opt;
    this.control = new Control.Fullscreen(this._options)
    if (this._map && this.enabled) {
        this._map.addControl(this.control);
    }
  }

  @Input() set map(map: Map | undefined) {
    if (map && this.enabled) { 
      this._map = map;
      if (this.control) {
        map.addControl(this.control)
      }
    };
  };

  ngOnDestroy() {
    if (this.control) {
      this._map?.removeControl(this.control);
      this._map?.off('enterFullscreen');
      this._map?.off('exitFullscreen');
    }
  }

  get map(): Map | undefined {
    return this._map;
  };
  
  get enabled (): boolean {
    return screen.availHeight >= this.minsize && screen.availWidth >= this.minsize;
  }
};
