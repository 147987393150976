import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

import { I18n } from './i18n.module';
import { ConfigService } from './config.service';
import { IAPIDetails, IFachgruppe, IMetaTags } from './interfaces';

const DEBUG = false;
const DEBUGSTYLE = 'color: #7777cc; font-weight: 800';
const TRENNER = ' | ';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  public tags: IMetaTags | undefined;

  public readonly metaUpdated$ = new BehaviorSubject<IMetaTags | undefined>(undefined);

  constructor (
    private readonly config: ConfigService,
    private readonly title:  Title,
    private readonly meta:   Meta,
    private readonly i18n:   I18n,
  ) { }

  public addTags (page: string, info?: string | IAPIDetails | any) {

    const metaImg  = '/assets/site/meta.public-reporting.png';
    const metaSite = this.config.all.domain_url;

    if (typeof info === 'string' && info === 'einrichtungssuche') {
      this.tags = {
        page_title:  'Einrichtungssuche | Meine Rehabilitation',
        meta_title:  'Meine Rehabilitation | Einrichtungssuche',
        meta_descpt: 'Hier finden Sie passende Reha-Fachabteilungen per Einrichtungssuche.',
        meta_image:  metaSite + metaImg,
        meta_robots: this.i18n.tokens[`meta.${page}.robots`],
      };

    } else {
      this.tags = {
        page_title:  this.pageTitle(page, info),
        meta_title:  this.metaTitle(page, info),
        meta_descpt: this.metaDesc(page, info),
        meta_image:  metaSite + metaImg,
        meta_robots: this.i18n.tokens[`meta.${page}.robots`],
      };

    }

    // page title
    this.title.setTitle(this.tags.page_title);

    // meta
    this.meta.updateTag( { name: 'title'       , content: this.tags.meta_title } );
    this.meta.updateTag( { name: 'image'       , content: this.tags.meta_image } );
    this.meta.updateTag( { name: 'robots'      , content: this.tags.meta_robots } );
    this.meta.updateTag( { name: 'description' , content: this.tags.meta_descpt } );

    DEBUG && console.log('%cMeta.tags', DEBUGSTYLE, page, this.tags);

    this.metaUpdated$.next(this.tags);

  }

  private pageTitle (page: string, info: string | IAPIDetails  | IFachgruppe): string {

    let title = this.i18n.tokens[`meta.${page}.tab.title`];

    if (page === 'detail') {
      title = title.replace('%s', (info as IAPIDetails).einrichtung.name);
      return title

    } else if (page === 'ergebnis') {
      title = title.replace('%s', (info as IFachgruppe).tab_title);
      return title


    } else {
      return title;

    }

  }

  private metaTitle (page: string, info: string | IAPIDetails | IFachgruppe): string {

    let title = this.i18n.tokens[`meta.${page}.title`];

    if (page === 'detail') {
      title = title.replace('%s', (info as IAPIDetails).einrichtung.name);
      return title;

    } else if (page === 'ergebnis') {
      title = title.replace('%s', (info as IFachgruppe).meta_title);
      return title;


    } else {
      return title;

    }

  }
  private metaDesc (page: string, info: string | IAPIDetails | any): string {

    if (page === 'detail') {
      // Seute-Deern-Ring 30 | 24351 Damp | 04352 808303 | info.rda@vamed-gesundheit.de | www.klinik.de
      return [
          info.fachabteilung.plz        ?? '',
          info.fachabteilung.ort        ?? '',
          info.fachabteilung.strasse    ?? '',
          info.fachabteilung.hausnummer ?? '',
          // info.fachabteilung.telefon    ?? '', // Removed tel bc spam // tbe: 23-09-05
          // info.fachabteilung.email      ?? '', // Removed email bc spam
          info.fachabteilung.web        ?? '',
        ]
        .map(s => s.trim())
        .filter(Boolean)
        .join(TRENNER)
      ;

    } else if (page === 'ergebnis') {
      let desc = this.i18n.tokens[`meta.${page}.description`];
      desc = desc.replace('%s', (info as IFachgruppe).meta_description);
      return desc

    } else {
      return this.i18n.tokens[`meta.${page}.description`];

    }

  }

}
