import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';
import { StorageKey, StorageService } from './storage.service';
import { IAPIErgebnis, IErgebnisResults, ISelFachabteilung, ISelSchwerpunkt, TTableSort } from './interfaces';

const DEBUG = false;

export interface IState {

  suche:           string    // Klinik Suche

  // Ergebnisseite Filter
  plz:             string    // Filter
  radius:          number    // Filter, in km
  merkmale:        string    // Filter, comma seperated
  
  // Ergebnisseite View Options
  sort:            TTableSort, //string    // Anzeige, column index, asc or desc
  view:            'liste' | 'karte' // Anzeige

  // Startseite
  fachabteilung:   ISelFachabteilung
  schwerpunkt:     ISelSchwerpunkt

  // Pagination Ergebnisse
  rowsperpage:      string
  activepage:       string

}

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private state: IState = {} as IState;

  constructor (
    private readonly config:   ConfigService,
    private readonly storage:  StorageService,
  ) {
    this.init();
  }

  private init (): void {

    // const state = this.storage.get(StorageKey.State);
    // query params can override state for debugging purposes
    const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));

    // inherit and remember
    this.state = Object.assign( 
      {}, 
      this.config.defaultState, 
      this.storage.get(StorageKey.State), 
      queryParams
    );
    this.storage.set(StorageKey.State, this.state);

    DEBUG && console.log('State.init', this.state);

  }

  public clear (): void {
    this.storage.remove(StorageKey.ApiErgebnis);
    this.storage.remove(StorageKey.ErgebnisResult);
    this.storage.remove(StorageKey.State);
    this.init();
    DEBUG && console.log('State.cleared')
  }

  public get<T>(key: keyof IState): T {
    return this.state[key] as T;
  }

  public all (): IState {
    return this.state;
  }

  public update ( params: Partial<IState> ): IState {
    this.state = Object.assign( this.state, params );
    this.storage.set(StorageKey.State, this.state);
    DEBUG && console.log('State.update', params);
    return this.state;
  }

  /**
   * Search results are saved locally and in storage to ensure
   * they are availbale in details to browse with prev/next
   * selecting new fagrp in start clears all results, also
   * click on logo in header
   */

  public saveApiErgebnis (apiErgebnis: IAPIErgebnis): void {
    this.storage.set(StorageKey.ApiErgebnis, apiErgebnis);
    DEBUG && console.log('State.saveApiErgebnis', apiErgebnis.fachabteilungen.length);
  }
  public clearApiErgebnis (): void {
    this.storage.remove(StorageKey.ApiErgebnis);
    DEBUG && console.log('State.clearApiErgebnis');
  }

  public saveErgebnisResult ( ergebnisse: IErgebnisResults ): void {
    this.storage.set(StorageKey.ErgebnisResult, ergebnisse);
    DEBUG && console.log('State.saveErgebnisResult', ergebnisse.fachabteilungen.length);
  }
  public clearErgebnisResult (): void {
    this.storage.remove(StorageKey.ErgebnisResult);
    DEBUG && console.log('State.clearErgebnisResult');
  }
  public readErgebnisResult ( ): IErgebnisResults | null {
    const ergebnisse = this.storage.get(StorageKey.ErgebnisResult);
    DEBUG && console.log('State.readErgebnisResult', ergebnisse ? ergebnisse.fachabteilungen.length : 0);
    return ergebnisse;
  }
  
  // Diff in seconds to last form send
  public readNpsDiffDays () : number {
    
    let npsDate         = new Date('1970-01-01T00:00:00.000Z');
    const npsDateString = this.storage.get(StorageKey.NpsDate) ?? '';
    const today         = new Date();
    const msecsToDays   = 24 * 60 * 60 * 1000;
    const testDate      = new Date(npsDateString);
    
    // validate date from storage
    npsDate = isNaN(testDate.getTime()) ? npsDate : testDate;
    
    const diffDays = (today.getTime() - npsDate.getTime()) / msecsToDays;
    
    DEBUG && console.log('State.readNpsDiffDays', diffDays, npsDateString);
    
    return diffDays;

  }
  
  public saveNpsDate ( npsDate: string ): void {
    this.storage.set(StorageKey.NpsDate, npsDate);
    DEBUG && console.log('State.saveNpsDate', npsDate);
  }

  // useful for debugging
  public posFachabteilung ( idfach: number): string {

    const ergebnisse = this.storage.get(StorageKey.ErgebnisResult);

    if (ergebnisse) {
      const len = ergebnisse.fachabteilungen.length;
      const pos = ergebnisse.fachabteilungen.findIndex( item => item.idFach === idfach);
      return `${pos + 1}/${len}`;

    } else {
      return '0/0';
    }

  }

}
