<div drv-row class="drv-mt-sm--01 drv-mt-md--02 drv-bg--ui-05" style="padding: 1rem 0; margin-right: 0; margin-left: 0;">
  <div drv-col [sm]="4" [md]="6" class="group">
    <pr-atom-quality-circle
      style="margin-right: 1rem"
      [quality]="100"
      [size]="44"
      [stroke]="5"
      [title]="''"
      [color]="'#446688'"
      [lightVersion]="false"
      [textcolor]="'transparent'"
    ></pr-atom-quality-circle>
    <div style="vertical-align: middle; margin-top: -3px">
      <div>
        {{ trans.fachabteilung }}
      </div>
    </div>
  </div>

  <div drv-col [sm]="4" [md]="6" class="group">
    <pr-atom-quality-circle
      style="margin-right: 1rem;"
      [quality]="100"
      [size]="44"
      [stroke]="5"
      [title]="''"
      [color]="'#FFF'"
      [lightVersion]="false"
      [textcolor]="'transparent'"
    ></pr-atom-quality-circle>
    <div style="vertical-align: middle; margin-top: -3px">
      <div>
        {{ trans.vergleichsgruppe }}
      </div>
    </div>
  </div>
</div>
