import { Component } from '@angular/core';

@Component({
  selector: 'pr-atom-claim',
  template: `
    <div drv-row class="pr-claim-container">
      <div drv-col class="drv-pt-sm--03 drv-mb-sm--03" [sm]="{ span: 4, offset: 0}">
        <span class="pr-claim" i18n="@@app.title">app.title</span>
      </div>
    </div>
  `,
  styles: ['']
})
export class ClaimAtom { }
