<div class="page page-ergebnis drv-mt-sm--01">

  <pr-cell-header-bar>

    <drv-button
      class="back"
      style="margin-left: -1rem"
      [label]="trans.back_start_page"
      kind="tertiary"
      type="anchor"
      iconPosition="left"
      iconType="arrow-left"
      backgroundUI="light"
      [route]="['/', 'de']"
    ></drv-button>

    <!-- <drv-button
      class="antrag"
      label="{{ trans.btn_antrag_label }}"
      iconPosition="left"
      iconType="document-saved"
      backgroundUI="light"
      (onClick)="onNavigate('antrag')"
    ></drv-button> -->

  </pr-cell-header-bar>

  <!-- CLAIM -->
  <pr-atom-claim></pr-atom-claim>

  <!-- TITLE dynamic -->
  <pr-atom-page-title> {{ results?.header }} </pr-atom-page-title>

  <!-- BEISPIELE-SCHWERPUNKT oder SUCHTERM-->
  <div drv-row>
    <div class="fplus" drv-col [sm]="4">{{ results?.beispiele }}</div>
  </div>

  <!-- FILTER-BOX -->
  <div drv-row
    class="drv-mt-sm--04 drv-pb-md--02 drv-bg--ui-05 drv-col-sm-bleed--left drv-col-sm-bleed--right"
  >
    <div drv-col [sm]="4">

      <!-- FILTER DESKTOP/MOBILE (projected) -->
      <pr-cell-filter>

        <!-- FILTERMÖGLICHKEITEN -->
        <h2 *pr-hide="['ts', 'sm']"
          class="drv-mb-sm--01 drv-mt-sm--03"
          i18n="@@page.ergebnis.header.filter"
        >
          page.ergebnis.header.filter
        </h2>

        <!-- ANZAHL AKTIVER FILTER -->
        @if (countActiveFilter) {
          <span class="drv-btn__counter"
            style="background-color: #083163; color: white; padding: 0 6px 2px"
          >
            {{ countActiveFilter }}
          </span>
        }

        <!-- ALLE FILTER -->
        <pr-cell-filtercollection
          [activeFilter]="activeFilter"
          [config]="filterConfig"
          [showLoader]="!dataReady"
          (updateFilter)="onUpdateFilter($event)"
        ></pr-cell-filtercollection>

        <!-- HINWEIS ANSCHLUSS REHA -->
        <p class="drv-mt-sm--01 bold">
          <span i18n="@@page.ergebnis.filters.hinweis.anschlussrehabilitation">
            page.ergebnis.filters.hinweis.anschlussrehabilitationn
          </span>
        </p>

        <!-- BEI FRAGEN... -->
        <p class="drv-mt-sm--01 drv-mb-sm--01">
          <span i18n="@@page.ergebnis.filters.help">
            page.ergebnis.filters.help
          </span>
          <a class="pr-link-intern"
            [routerLink]="[ '/', 'de', trans.link_hilfe ]"
            i18n="@@page.ergebnis.filters.link.label"
          >page.ergebnis.filters.link.label</a>
        </p>

      </pr-cell-filter>

    </div>
  </div>

  <!-- BUTTON-BAR MOBILE/DESKTOP-->
  <div drv-row class="drv-mt-sm--02 drv-mb-sm--02">
    <div drv-col [sm]="4">

      <div pr-intersection (intersection)="onIntersection($event)"></div>

      <div class="pr-item-bar fxrow">

        <!-- ANZAHL TREFFER + PAGING INFO-->
        <pr-cell-treffer-count></pr-cell-treffer-count>

        <!-- ACTIVE SORT -->
        <div *pr-hide="['ts', 'sm']" class="fxshrink">
          <span class="nowrap"> {{ trans.sort_by }} : </span><br />
          <span class="bold nowrap">
            {{ activeSort[0] }}, {{ activeSort[1] }}
          </span><br />
        </div>

        <!-- LISTE/KARTE -->
        @if (results?.fachabteilungen?.length) {
          <drv-segmentedbutton
            role="tablist"
            [attr.aria-label]="trans.aria_map_toggle"
            [activeIndex]="state.get('view') === 'liste' ? 0 : 1"
            backgroundUI="light"
            (click)="onToggleView($event)"
            >
            <drv-button
              id="tab-liste"
              role="tab"
              data-e2e="viewListe"
              aria-controls="panel-liste"
              [attr.aria-selected]="state.get('view') === 'liste'"
              label="{{ (app.media$ | async ) === 'sm' ? '' : trans.toggle_liste }}"
              iconType="burger"
              kind="secondary"
              iconOnly="only-sm"
              [tooltip]="false"
            ></drv-button>
            <drv-button
              id="tab-karte"
              role="tab"
              data-e2e="viewKarte"
              aria-controls="panel-karte"
              [attr.aria-selected]="state.get('view') === 'karte'"
              label="{{ (app.media$ | async ) === 'sm' ? '' : trans.toggle_karte }}"
              iconType="location"
              kind="secondary"
              iconOnly="only-sm"
              [tooltip]="false"
            ></drv-button>
          </drv-segmentedbutton>
        }

      </div>

    </div>
  </div>

  <!-- LOADER MOBILE -->
  <div *pr-show="['ts', 'sm']" class="loader-container" [ngStyle]="{ 'display': !dataReady ? 'block' : 'none' }">
    <pr-atom-loading></pr-atom-loading>
  </div>

  <!-- DROPDOWN SORTIERUNG MOBILE -->
  <div drv-row *pr-show="['ts', 'sm']" class="drv-mt-sm--00">
    @if (results?.fachabteilungen?.length && state.get('view') === 'liste') {
      <div drv-col [sm]="4">
        <drv-dropdown
          #drv_dropdown_sortierung
          id="drv-dropdown-sortierung"
          data-e2e="comboSortierung"
          [label]="trans.label_sort"
          [required]="true"
          [options]="comboSortOptions"
          [placeholder]="trans.please_choose"
          (ngModelChange)="onSelectSort($event)"
          [ngModel]="selected.sort"
        ></drv-dropdown>
      </div>
    }
  </div>

  <!-- TABLE/MAP SM/DESKTOP-->
  <div drv-row class="drv-mt-sm--01 drv-mt-md--02">

    <!-- TABLE DEKTOP + MOBILE -->
    <pr-cell-table drv-col [sm]="4"
      #pr_cell_table
      id="panel-liste"
      role="tabpanel"
      aria-labelledby="tab-liste"
      [style.display]="state.get('view') !== 'karte' ? 'block' : 'none'"
      [results]="results"
      (rowclick)="onNavigateDetails($event)"
      (sort)="onUpdateSort($event)"
      (page)="onUpdatePagination($event)"
    ></pr-cell-table>


    <!-- CLUSTER MAP WITH TABLE -->
    <pr-cell-cluster-map drv-col [sm]="4"
      #pr_cell_cluster_map
      [style.display]="state.get('view') === 'karte' ? 'block' : 'none'"
      [results]="results"
      (events)="onMapEvent($event)"
    ></pr-cell-cluster-map>

  </div>

  <pr-cell-legend-wartezeit></pr-cell-legend-wartezeit>

  <pr-cell-legend-quality></pr-cell-legend-quality>

  <pr-cell-quality-tile></pr-cell-quality-tile>

  <!-- SCROLL TO TOP FLOATING BUTTON -->
  <div class="floater" [ngClass]="{ floating: isFloating, hidden: !isFloating }" >
    <drv-button
      iconType="arrow-to-top"
      [label]="trans.btn_float_label"
      (onClick)="onTopScroll()"
    ></drv-button>
  </div>

</div>
