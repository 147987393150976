import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';

import { DSModule } from '@pr/core/ds.module';
import { AtomsModule } from '@pr/atoms/atoms.module';
import { DirectivesModule } from '@pr/core/directives/directives.module'

import { BlockCell } from './markdown/block.cell';
import { FilterCell } from './filter/filter.cell';
import { HeaderBarCell } from './header-bar/header-bar.cell';
import { InlineCell } from './markdown/inline.cell';
import { MapCell } from './map/map.cell';
import { MarkdownCell } from './markdown/markdown.cell';
import { NodesCell } from './tooltips/nodes.cell';
import { QualityDetailsCell } from './quality-details/quality-details.cell';
import { SearchCell } from './search/search.cell';
import { TableCell } from './table/table.cell';
import { TooltipsCell } from './tooltips/tooltips.cell';
import { ClusterMapCell } from './cluster-map/cluster-map.cell';
import { ClusterMapPopupCell } from './cluster-map/cluster-map-popup.cell';
import { SearchAutocompleteCell } from './search-autocomplete/search-autocomplete.cell';
import { FilterCollectionCell } from './filtercollection/filtercollection.cell';
import { FilterUmkreisCell } from './filterumkreis/filterumkreis.cell'

import { FullscreenControlPlugin } from './cluster-map/cluster-map-fullscreen.plugin';
import { TablePaginationCell } from './table-pagination/table-pagination.cell';
import { TrefferCountCell } from './treffer-count/treffer-count.cell';
import { LegendWartezeitCell } from './legend-wartezeit/legend-wartezeit.cell';
import { NpsFormCell } from './nps-form/nps-form.cell';
import { QualityTileCell } from './quality-tile/quality-tile.cell';
import { ButtonTileCell } from './button-tile/button-tile.cell';

import { LegendQualityCell } from './legend-quality/legend-quality.cell';

@NgModule({
  declarations: [
    BlockCell,
    ClusterMapCell,
    ClusterMapPopupCell,
    FilterCell,
    FilterCollectionCell,
    FilterUmkreisCell,
    FullscreenControlPlugin,
    HeaderBarCell,
    InlineCell,
    LegendWartezeitCell,
    LegendQualityCell,
    MapCell,
    MarkdownCell,
    NodesCell,
    NpsFormCell,
    QualityDetailsCell,
    QualityTileCell,
    SearchAutocompleteCell,
    SearchCell,
    TableCell,
    TablePaginationCell,
    TooltipsCell,
    TrefferCountCell,
    ButtonTileCell,
  ],
  imports: [
    AtomsModule,
    CommonModule,
    DirectivesModule,
    DSModule,
    FormsModule,
    LeafletMarkerClusterModule,
    LeafletModule,
    RouterModule,
  ],
  exports: [
    BlockCell,
    ClusterMapCell,
    ClusterMapPopupCell,
    FilterCell,
    FilterCollectionCell,
    FilterUmkreisCell,
    FullscreenControlPlugin,
    HeaderBarCell,
    InlineCell,
    LegendWartezeitCell,
    LegendQualityCell,
    MapCell,
    MarkdownCell,
    NodesCell,
    NpsFormCell,
    QualityDetailsCell,
    QualityTileCell,
    SearchAutocompleteCell,
    SearchCell,
    TableCell,
    TablePaginationCell,
    TooltipsCell,
    TrefferCountCell,
    ButtonTileCell,
  ]
})
export class CellsModule { }
