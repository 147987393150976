<ul>

  @for (item of data; track item) {
    <li>
      <div class="nowrap">
        {{ item.label }}
      </div>
      @if (item.childs.length) {
        <ul>
          @for (feature of item.childs; track feature) {
            <li>
              {{ feature }}
            </li>
          }
        </ul>
      }
    </li>
  }

</ul>
