import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';

import { IErgFachabteilung, IPRMarker } from '@pr/core/interfaces';
import { ClusterMapPopupCell } from './cluster-map-popup.cell';

const DEBUG = false;

@Injectable({ providedIn: 'root' })
export class ClusterMapPopupService {

  constructor (
    private readonly injector: Injector,
    private readonly applicationRef: ApplicationRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver
  ) {}

  htmlMultiPopup (markers: IPRMarker[]): HTMLElement  {

    // Create element
    const popup = document.createElement('popup-component');

    // Create the component and wire it up with the element
    const factory = this.componentFactoryResolver.resolveComponentFactory(ClusterMapPopupCell);
    const popupComponentRef = factory.create(this.injector, [], popup);

    // Attach to the view so that the change detector knows to run
    this.applicationRef.attachView(popupComponentRef.hostView);

    // Set the message
    popupComponentRef.instance.markers = markers;

    DEBUG && console.log('ClusterMapPopupService', markers);
    
    // Return rendered Component
    return popup;

  }

}
