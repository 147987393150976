
<drv-filter 
  #compFilterUmkreis
  id="distance"
  name="distance"
  label="trans.search_radius"
  backgroundUI="color"
  [disabled]="false"
  [labelVisible]="false"
  [buttonText]="buttonText ?? ''"
  [showResetButton]="true"
  (onClear)="onUpdate(['deleteplzradius', 'onClear'])"
  (change)="onUpdate(['plzradius', 'change'])"
>
  <drv-textinput
    name="zip" id="zip"
    optionalText=""
    [maskOptions]="plzMask"
    [disabled]="false"
    [label]="trans.plz_label"
    [hasHint]="plzHasHint"
    [required]="false"
    [hintText]="plzHintText"
    [(ngModel)]="activeFilter!.plz"
  ></drv-textinput>
  
  <drv-slider class="drv-mt-sm--02 drv-mb-sm--02"
    id="range"
    name="range"
    variant="small"
    optionalText=""
    [label]="trans.slider_label"
    [disabled]="!activateSlider"
    [required]="true"
    [min]="10"
    [max]="1000"
    [(ngModel)]="activeFilter!.radius"
  ></drv-slider>
  
</drv-filter>
