<!-- LOAD TOOLTIPS -->
<pr-cell-tooltips #tooltipTemplates file="tooltips.md"></pr-cell-tooltips>

<!-- https://jira.service.zd.drv/browse/ZVWBG2258-431 -->

<!-- SEARCH SM -->
<div *pr-show="['ts', 'sm', 'md']">
  
  <!-- SEARCH BY DROPDOWN / INDIKATION -->
  <div drv-row>
    <div drv-col [sm]="4">
      <ng-container *ngTemplateOutlet="h2_indikation_header"></ng-container>
      <ng-container *ngTemplateOutlet="p_indication_absatz"></ng-container>
      <div drv-col [sm]="4" class="drv-mt-sm--02">
        <ng-container *ngTemplateOutlet="pr_indication_dropdown"></ng-container>
      </div>
      <div drv-col [sm]="4" class="drv-mt-sm--03">
        <ng-container *ngTemplateOutlet="pr_indication_submit"></ng-container>
      </div>
    </div>
  </div>

  <!-- DECORATION -->
  <div drv-row class="drv-mt-sm--02">
    <div drv-col [sm]="4">
      <ng-container *ngTemplateOutlet="img_decoration"></ng-container>
    </div>
  </div>
      
  <!-- SEARCH BY INPUT / AUTOCOMPLETE -->
  <div drv-row>
    <div drv-col [sm]="4">
      <ng-container *ngTemplateOutlet="h2_search_header"></ng-container>
      <ng-container *ngTemplateOutlet="p_search_absatz"></ng-container>
      <ng-container *ngTemplateOutlet="pr_search_autocomplete"></ng-container>
    </div>
  </div>

</div>

<!-- SEARCH MD -->
<!-- <div *pr-show="['md']">
  
</div> -->

<!-- SEARCH LG, XLG, MAX -->
<div *pr-hide="['ts', 'sm', 'md']">
  
  <!-- SEARCH BY DROPDOWN / INDIKATION -->
  <div drv-row>
    <div drv-col [lg]="12">
      <ng-container *ngTemplateOutlet="h2_indikation_header"></ng-container>
      <ng-container *ngTemplateOutlet="p_indication_absatz"></ng-container>
    </div>
  </div>

  <div drv-row>
    <div drv-col [lg]="8" class="drv-mt-sm--00">
      <div drv-row>
        <div drv-col [lg]="12" class="drv-mt-sm--02">
          <ng-container *ngTemplateOutlet="pr_indication_dropdown"></ng-container>
        </div>
        <div drv-col [lg]="12" class="drv-mt-sm--04">
          <ng-container *ngTemplateOutlet="pr_indication_submit"></ng-container>
        </div>
      </div>
    </div>
    <div drv-col [lg]="4" class="drv-mt-sm--02">
      <ng-container *ngTemplateOutlet="img_decoration"></ng-container>
    </div>
  </div>
  
  <!-- SEARCH BY INPUT / AUTOCOMPLETE -->
  <div drv-row class="drv-mt-sm--04">
    <div drv-col [sm]="4">
      <ng-container *ngTemplateOutlet="h2_search_header"></ng-container>
      <ng-container *ngTemplateOutlet="p_search_absatz"></ng-container>
      <ng-container *ngTemplateOutlet="pr_search_autocomplete"></ng-container>
    </div>
  </div>
  
</div>
      


<!-- TEMPLATES -->

<!-- HEADERS -->
<ng-template #h2_search_header>
  <h2 i18n="@@page.start.search.header.1">page.start.search.header.1</h2>
</ng-template>
<ng-template #h2_indikation_header>
  <h2 i18n="@@page.start.search.header.2">page.start.search.header.2</h2>
</ng-template>

<!-- INFOS -->
<ng-template #p_search_absatz>
  <p class="drv-mt-sm--02 drv-mb-sm--02">
    <span i18n="@@page.start.search.absatz.1">page.start.search.absatz.1></span>&nbsp;
    <span i18n="@@page.start.search.absatz.2">page.start.search.absatz.2></span>&nbsp;
    <span i18n="@@page.start.search.absatz.3">page.start.search.absatz.3></span>
  </p>
</ng-template>
<ng-template #p_indication_absatz>
  <p class="drv-mt-sm--02 drv-mb-sm--00">
    <span i18n="@@page.start.search.indikation.absatz.1">page.start.search.indikation.absatz.1</span>&nbsp;
    <span i18n="@@page.start.search.indikation.absatz.2">page.start.search.indikation.absatz.2</span>&nbsp;
    <span i18n="@@page.start.search.indikation.absatz.3">page.start.search.indikation.absatz.3</span>
  </p>
</ng-template>

<!-- CONTROLS -->
<ng-template #pr_search_autocomplete>
  <pr-cell-search-autocomplete
    id="pr-search-autocomplete"
    data-e2e="searchAutocomplete"
    [labelText]="trans.search_suche_header_1"
    [buttonText]="trans.search_suche_button"
    [placeholderText]="trans.search_suche_placeholder"
    [hasHint]="suche.hasHints"
    [hintText]="suche.hints"
    (hints)="onHints($event)"
    [toggletipTemplate]="(tooltipTemplates.get('start.suche') | async) || undefined"
    (searchEinrichtung)="onSearchEinrichtung($event)"
  ></pr-cell-search-autocomplete>
</ng-template>

<ng-template #pr_indication_dropdown>
  <div drv-row>
    <div drv-col>

      <drv-dropdown
        #drv_dropdown_fachabteilung
        id="drv-dropdown-fachabteilung"
        class="drv-mb-sm--04"
        data-e2e="comboFachabteilung"
        [label]="trans.search_indikation_header_1"
        [required]="true"
        [options]="fachabteilungen"
        [toggletipTemplate]="(tooltipTemplates.get('start.dropdown.1') | async) || undefined"
        [placeholder]="trans.search_indikation_placeholder_1"
        (ngModelChange)="onSelect('fachabteilung', $event)"
        [ngModel]="selected.fachabteilung"
      ></drv-dropdown>

    </div>
  </div>

  @if (!!schwerpunkte.length) {
    <div drv-row class="drv-mt-sm--03">
      <div drv-col>
        <drv-dropdown
          #drv_dropdown_schwerpunkt
          id="drv-dropdown-schwerpunkt"
          class="drv-mb-sm--04"
          data-e2e="comboSchwerpunkt"
          [required]="true"
          [options]="schwerpunkte"
          [toggletipTemplate]="(tooltipTemplates.get('start.dropdown.2') | async) || undefined"
          [label]="trans.search_indikation_header_2"
          [placeholder]="trans.search_indikation_placeholder_2"
          (ngModelChange)="onSelect('schwerpunkt', $event)"
          [ngModel]="selected.schwerpunkt"
        ></drv-dropdown>
      </div>
    </div>
  }
</ng-template>
<ng-template #pr_indication_submit>
  <drv-button
    data-e2e="searchIndikation"
    iconPosition="left"
    iconType="magnifier"
    kind="secondary"
    [label]="trans.search_indikation_button"
    [disabled]="searchDisabled"
    (onClick)="onSearchIndikation()"
  ></drv-button>
</ng-template>

<!-- DECORATION -->
<ng-template #img_decoration>
  <img class="flipx" src="assets/images/start-2.png" alt="" />
</ng-template>



@if (false) {


<!-- SEARCHBAR ALL SCCREENS -->
<div drv-row>
  <div drv-col [sm]="4" [lg]="{ span: 9 }">

    <h2 i18n="@@page.start.search.header.1">page.start.search.header.1</h2>
    
    <p class="drv-mt-sm--02 drv-mb-sm--02">
      <span i18n="@@page.start.search.absatz.1">page.start.search.absatz.1></span>&nbsp;
      <span i18n="@@page.start.search.absatz.2">page.start.search.absatz.2></span>&nbsp;
      <span i18n="@@page.start.search.absatz.3">page.start.search.absatz.3></span>
    </p>

    <pr-cell-search-autocomplete
      id="pr-search-autocomplete"
      data-e2e="searchAutocomplete"
      [labelText]="trans.search_suche_header_1"
      [buttonText]="trans.search_suche_button"
      [placeholderText]="trans.search_suche_placeholder"
      [hasHint]="suche.hasHints"
      [hintText]="suche.hints"
      [toggletipTemplate]="(tooltipTemplates.get('start.suche') | async) || undefined"
      (searchEinrichtung)="onSearchEinrichtung($event)"
    ></pr-cell-search-autocomplete>

  </div>
</div>

<!-- IMAGE SM -->
<div drv-row *pr-show="['ts', 'sm']"  class="drv-mt-sm--02">
  <div drv-col [sm]="4">
    <img class="flipx" src="assets/images/start-2.png" alt="" />
  </div>
</div>

<!-- INFO SEARCH INDIKATION -->
<div drv-row class="drv-mt-sm--04 drv-mt-md--07 drv-mt-lg--08 drv-mb-sm--00">
  <div drv-col [sm]="4" [md]="{ span: 9 }">

    <h2 i18n="@@page.start.search.header.2">page.start.search.header.2</h2>
    <p class="drv-mt-sm--02 drv-mb-sm--00">
      <span i18n="@@page.start.search.indikation.absatz.1">page.start.search.indikation.absatz.1</span>&nbsp;
      <span i18n="@@page.start.search.indikation.absatz.2">page.start.search.indikation.absatz.2</span>&nbsp;
      <span i18n="@@page.start.search.indikation.absatz.3">page.start.search.indikation.absatz.3</span>
    </p>

  </div>
</div>

<!-- INDIKATION DESKTOP -->
<div drv-row *pr-hide="['ts', 'sm']" class="drv-mt-md--01">

  <div drv-col [sm]="4" [md]="{ span: 6 }">

    <div drv-row>
      <div drv-col>

        <drv-dropdown
          #drv_dropdown_fachabteilung
          id="drv-dropdown-fachabteilung"
          class="drv-mb-sm--04"
          data-e2e="comboFachabteilung"
          [label]="trans.search_indikation_header_1"
          [required]="true"
          [options]="fachabteilungen"
          [toggletipTemplate]="(tooltipTemplates.get('start.dropdown.1') | async) || undefined"
          [placeholder]="trans.search_indikation_placeholder_1"
          (ngModelChange)="onSelect('fachabteilung', $event)"
          [ngModel]="selected.fachabteilung"
        ></drv-dropdown>

      </div>
    </div>

    @if (!!schwerpunkte.length) {
      <div drv-row class="drv-mt-sm--03">
        <div drv-col>
          <drv-dropdown
            #drv_dropdown_schwerpunkt
            id="drv-dropdown-schwerpunkt"
            class="drv-mb-sm--04"
            data-e2e="comboSchwerpunkt"
            [required]="true"
            [options]="schwerpunkte"
            [toggletipTemplate]="(tooltipTemplates.get('start.dropdown.2') | async) || undefined"
            [label]="trans.search_indikation_header_2"
            [placeholder]="trans.search_indikation_placeholder_2"
            (ngModelChange)="onSelect('schwerpunkt', $event)"
            [ngModel]="selected.schwerpunkt"
          ></drv-dropdown>
        </div>
      </div>
    }

    <div drv-row class="drv-mt-sm--03">
      <div drv-col>

        <drv-button
          data-e2e="searchIndikation"
          iconPosition="left"
          iconType="magnifier"
          kind="secondary"
          [label]="trans.search_indikation_button"
          [disabled]="searchDisabled"
          (onClick)="onSearchIndikation()"
        ></drv-button>

      </div>
    </div>

  </div>

  <div drv-col [sm]="4" [md]="{ span: 6 }">
    <img class="flipx" src="assets/images/start-2.png" />
  </div>

</div>

<!-- INDIKATION MOBILE -->
<div drv-row *pr-show="['ts', 'sm']" class="drv-mt-sm--02">

  <div drv-col [sm]="4">

    <div drv-row class="drv-mt-sm--00">
      <div drv-col>

        <drv-dropdown
          id="drv-dropdown-1"
          class="drv-mb-sm--04"
          data-e2e="comboFachabteilung"
          [label]="trans.search_indikation_header_1"
          [required]="true"
          [options]="fachabteilungen"
          [toggletipTemplate]="(tooltipTemplates.get('start.dropdown.1') | async) || undefined"
          [placeholder]="trans.search_indikation_placeholder_1"
          (ngModelChange)="onSelect('fachabteilung', $event)"
          [ngModel]="selected.fachabteilung"
        ></drv-dropdown>

      </div>
    </div>

    @if (!!schwerpunkte.length) {
      <div drv-row class="drv-mt-sm--03">
        <div drv-col>
          <drv-dropdown
            id="drv-dropdown-2"
            class="drv-mb-sm--04"
            data-e2e="comboSchwerpunkt"
            [required]="true"
            [options]="schwerpunkte"
            [toggletipTemplate]="(tooltipTemplates.get('start.dropdown.2') | async) || undefined"
            [label]="trans.search_indikation_header_2"
            [placeholder]="trans.search_indikation_placeholder_2"
            (ngModelChange)="onSelect('schwerpunkt', $event)"
            [ngModel]="selected.schwerpunkt"
          ></drv-dropdown>
        </div>
      </div>
    }

    <div drv-row class="drv-mt-sm--03">
      <div drv-col>

        <drv-button
          data-e2e="searchIndikation"
          iconPosition="left"
          iconType="magnifier"
          kind="secondary"
          [label]="trans.search_indikation_button"
          [disabled]="searchDisabled"
          (onClick)="onSearchIndikation()"
        ></drv-button>

      </div>
    </div>

  </div>

</div>

}
