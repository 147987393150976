import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges } from '@angular/core';
import { I18n } from '@pr/core/i18n.module';

import { TWartezeit } from '@pr/core/interfaces.api';

type TWZInfo = [TWartezeit | undefined, 'long' | 'short']

@Component({
  selector: 'pr-atom-wartezeit',
  template: `{{ wzText }}`,
  styleUrls: ['./wartezeit.atom.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WartezeitAtom {

  public wzText  = '';
  private data: TWartezeit | undefined;
  
  @Input() set wzinfo (wz: TWZInfo) {
    if (wz[0]) {
      this.data   = wz[0];
      this.wzText = wz[1] === 'long'  
        ? this.i18n.translate(this.data.tokenLong)
        : this.i18n.translate(this.data.tokenShort)
      ;
    }
  }
  
  @HostBinding('class') get classes(): Record<string, boolean> {
    if (!this.data) { return {}; } 
    else {
      return {
        'group1': this.data.gruppe === 1,
        'group2': this.data.gruppe === 2,
        'group3': this.data.gruppe === 3,
        'group4': this.data.gruppe === 4,
        'group5': this.data.gruppe === 5,
      }
    }
  }
  
  constructor ( private i18n: I18n ) {}

}
