import { ChangeDetectionStrategy, AfterViewChecked, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormItemMessage, ModalService } from '@drv-ds/drv-design-system-ng';
import { combineLatest, Subscription } from 'rxjs';

import { IAPIErgebnis, IAPIFachgruppen, IAPIMessage, IEVENTSearchIndikation, IEVENTSearchWord, IFachgruppe } from '@pr/core/interfaces';
import { ApiService } from '@pr/core/api.service';
import { I18n } from '@pr/core/i18n.module';
import { StateService } from '@pr/core/state.service';
import { MetaService } from '@pr/core/meta.service';
import { MatomoService } from '@pr/core/matomo.service';
import { HelperService } from '@pr/core/helper.service';

const DEBUG = false;

@Component({
  selector: 'pr-page-start',
  templateUrl: './start.page.html',
  styleUrls: ['./start.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartPage implements OnInit, OnDestroy, AfterViewChecked {

  public searchDisabled = true;
  private subscriber$: Subscription | undefined;
  private subscriberErgebnisse$: Subscription | undefined;
  public fachgruppen: IFachgruppe[] = [];
  public resultMessages: FormItemMessage[] = [];

  public modal = {
    id: 'popup',
    open: false
  }

  public trans = {

    recht_link:           $localize`:@@page.start.infobox2.info.recht.link.href:`,
    foto_alt:             $localize`:@@page.start.infobox2.info.foto.alttext:`,
    recht_link_href:      $localize`:@@page.start.infobox2.info.recht.link.href:`,
    modal_title:          $localize`:@@page.start.modal.title:`,
    modal_button_label:   $localize`:@@page.start.modal.button.label:`,

    tile1_link_href:      $localize`:@@page.start.tile1.link.href:`,
    tile4_link_href:      $localize`:@@page.start.tile4.link.href:`,
    tile1_header:         $localize`:@@page.start.tile1.header:`,
    tile2_header:         $localize`:@@page.start.tile2.header:`,
    tile3_header:         $localize`:@@page.start.tile3.header:`,
    tile4_header:         $localize`:@@page.start.tile4.header:`,
    tiles: [
      [
        $localize`:@@page.start.tile1.absatz1:`,
        $localize`:@@page.start.tile1.absatz2:`,
      ],
      [
        $localize`:@@page.start.tile2.absatz1:`,
        $localize`:@@page.start.tile2.absatz2:`,
      ],
      [
        $localize`:@@page.start.tile3.absatz1:`,
        $localize`:@@page.start.tile3.absatz2:`,
      ],
      [
        $localize`:@@page.start.tile4.absatz1:`,
        $localize`:@@page.start.tile4.absatz2:`,
      ],
    ]
  };

  @HostBinding('class.hideHint') public hideHint: boolean = true;

  constructor (
    private readonly router: Router,
    private readonly meta:   MetaService,
    public  readonly i18n:   I18n,
    private readonly api:    ApiService,
    private readonly state:  StateService,
    private readonly cd:     ChangeDetectorRef,
    private readonly matomo: MatomoService,
    private readonly helper: HelperService,
    private readonly modalService:  ModalService,
  ) {
    this.meta.addTags('start');
  }

  public ngOnDestroy (): void {
    this.subscriber$?.unsubscribe();
    this.subscriberErgebnisse$?.unsubscribe();
  }

  public ngAfterViewChecked() : void {

    // hack to DS because pictogram name => aria-label
    let svg = document.querySelector("svg[aria-label=selbstverwaltung]");
    svg?.setAttribute("aria-label", "");

    svg = document.querySelector("svg[aria-label=leistung]");
    svg?.setAttribute("aria-label", "");

  }

  public ngOnInit (): void {

    this.subscriber$ = combineLatest([
      this.api.get<IAPIFachgruppen>('/fachgruppen', { lang: this.i18n.locale }),
    ]).subscribe( ([{ data }]) => {
      this.fachgruppen = data;
    });

  }

  public openModal (): void {
    this.modal.open = !this.modal.open;
    this.modalService.updateModal(this.modal);
  }

  public onSearchIndikation (event: IEVENTSearchIndikation): void {

    DEBUG && console.log('onSearchIndikation', event);

    // Need to remove suche from state here, so backlinks work in details
    this.state.update({ suche: '', activepage: '1' });
    this.state.clearErgebnisResult();

    void this.router.navigate(['/', 'de', event.slug, 'indikation']);

  }


  public onSearchString (event: IEVENTSearchWord) {

    DEBUG && console.log('onSearchString', event.suche);

    let abbr = '', suche = '';

    // check for abbreviation
    if (event.suche.includes('(') && event.suche.includes(')')) {
      [ abbr, suche ] = this.helper.splitAbbreviation(event.suche);

    } else {
      suche = event.suche;
    }

    this.subscriberErgebnisse$ = this.api
      .get<IAPIErgebnis>('/einrichtungssuche', { suche })
      .subscribe(response => {

        DEBUG && console.log('onSearchString', response.messages, response.data?.fachabteilungen?.length);

        if (response.messages.length) {

          // display messages
          this.resultMessages = response.messages
            .filter(m => m.severity.toLowerCase() === 'info')
            .map( m => ({ message: this.i18n.interpolate(m).text }) )
          ;
          this.cd.detectChanges();

          // MATOMO
          // results with hits, will be tracked on Ergebnispage
          const hitCount = response.data?.fachabteilungen?.length ?? 0;
          this.matomo.trackSearch(event.suche, 'einrichtungssuche', hitCount);

        } else {
          this.state.update({
            suche:         event.suche,
            activepage:   '1',
            fachabteilung: [],
            schwerpunkt:   [],
          });

          void this.router.navigate(['/', 'de', event.suche, 'suche']);

        }

      })
    ;

  }

}
