<h2 class="drv-mt-sm--04">{{ trans.header }}</h2>
<div drv-row class="drv-mt-sm--01 drv-mt-md--02">

  <div drv-col [sm]="4">
    <drv-tile pr-single-tile 
      pictogramType="perfomance"
      [title]="trans.tile2_header"
      [items]="trans.tile2_items"
      [route]="['/', 'de', 'qualitaet']"
    ></drv-tile>
  
  </div>
</div>
