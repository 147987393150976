import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DevelopmentGuard } from '@pr/core/development-guard.service';

import { BarrierefreiheitPage } from './pages/barrierefreiheit/barrierefreiheit.page';
import { DatenschutzPage } from './pages/datenschutz/datenschutz.page';
import { DetailPage } from './pages/detail/detail.page';
import { ErgebnisPage } from './pages/ergebnis/ergebnis.page';
import { FeedbackPage } from './pages/feedback/feedback.page';
import { HilfePage } from './pages/hilfe/hilfe.page';
import { ImpressumPage } from './pages/impressum/impressum.page';
import { LaborPage } from '@pr/pages/labor/labor.page';
import { NotavailablePage } from '@pr/pages/notavailable/notavailable.page';
import { NotfoundPage } from '@pr/pages/notfound/notfound.page';
import { QualitaetPage } from './pages/qualitaet/qualitaet.page';
import { ResetPage } from './pages/reset/reset.page';
import { StartPage } from '@pr/pages/start/start.page';
import { ZumantragPage } from './pages/zumantrag/zumantrag.page';

const routes: Routes = [

  { path: '',                                component: StartPage },
  { path: 'de',                              component: StartPage },
  { path: 'de/:suche/suche',                 component: ErgebnisPage },
  { path: 'de/:indikation/indikation',       component: ErgebnisPage },
  { path: 'de/:slug/:id/:fagr/details',      component: DetailPage },
  { path: 'de/qualitaet',                    component: QualitaetPage,        data: { markdown: 'qualitaet.md'     } },
  { path: 'de/zumantrag',                    component: ZumantragPage,        data: { markdown: 'zumantrag.def.md' } },
  { path: 'de/zumantrag/def',                component: ZumantragPage,        data: { markdown: 'zumantrag.def.md' } },
  { path: 'de/zumantrag/ahb',                component: ZumantragPage,        data: { markdown: 'zumantrag.ahb.md' } },
  { path: 'de/zumantrag/gkv',                component: ZumantragPage,        data: { markdown: 'zumantrag.gkv.md' } },
  { path: 'de/zumantrag/wzg',                component: ZumantragPage,        data: { markdown: 'zumantrag.wzg.md' } },
  { path: 'de/hilfe',                        component: HilfePage,            data: { markdown: 'hilfe.md'         } },
  { path: 'de/impressum',                    component: ImpressumPage,        data: { markdown: 'impressum.md'     } },
  { path: 'de/datenschutz',                  component: DatenschutzPage,      data: { markdown: 'datenschutz.md'   } },
  { path: 'de/barrierefreiheit',             component: BarrierefreiheitPage, data: { markdown: 'barrierefreiheit.md' } },
  { path: 'de/barrierefreiheit-leicht',      component: BarrierefreiheitPage, data: { markdown: 'barrierefreiheit.leicht.md' } },

  { path: 'de/notavailable',                 component: NotavailablePage},
  { path: 'de/feedback',                     component: FeedbackPage,         data: { features : { feedback: false } } },
  { path: 'de/labor',                        component: LaborPage,            canActivate: [DevelopmentGuard] },

  { path: 'reset',                           component: ResetPage},

  { path: '**',                              component: NotfoundPage,         data: { markdown: 'notfound.md'     } },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling:           'enabled',
    scrollPositionRestoration: 'disabled',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
