import { Component, Injector, Input, ViewChild } from '@angular/core';
import { InputGroupComponent, NotificationService, TextareaComponent } from '@drv-ds/drv-design-system-ng';

import { ConfigService } from '@pr/core/config.service';
import { HelperService } from '@pr/core/helper.service';
import { MatomoService } from '@pr/core/matomo.service';
import { StateService } from '@pr/core/state.service';

const DEBUG = false;

@Component({
  selector: 'pr-cell-nps-form',
  templateUrl: './nps-form.cell.html',
  styleUrls: ['./nps-form.cell.scss']
})
export class NpsFormCell {
  
  public DEBUG = DEBUG;
  
  public readonly notificationId = 'prNpsNotification';

  public npsScore       = undefined;
  public npsSendAllowed = false;
  public npsFormAllowed = false;
  public sendMessage    = '';
  
  protected readonly notification: NotificationService;
  
  public onSend = this.npsSend.bind(this);
  
  public trans = {
    nps_header:               $localize`:@@page.zumantrag.nps.header:`,
    nps_question:             $localize`:@@page.zumantrag.nps.question:`,
    nps_label_comment:        $localize`:@@page.zumantrag.nps.comment.label:`,
    nps_placeholder_comment:  $localize`:@@page.zumantrag.nps.comment.placeholder:`,
    nps_counter_label:        $localize`:@@page.zumantrag.nps.counter.label:`,
    nps_counter_message:      $localize`:@@page.zumantrag.nps.counter.message:`,
    nps_button_label:         $localize`:@@page.zumantrag.nps.button.send.label:`,
    nps_thanks:               $localize`:@@page.zumantrag.nps.message.thanks:`,
  };
      
  @Input() public timespan = 0;
  
  @ViewChild(InputGroupComponent) compInputGroup!: InputGroupComponent;
  @ViewChild(TextareaComponent) compTextArea!: TextareaComponent;
  
  constructor (
    private readonly state:  StateService,
    public  readonly config: ConfigService,
    private readonly matomo: MatomoService,
    public  readonly helper: HelperService,
    injector: Injector
  ) {

    this.notification   = injector.get(NotificationService);
    const consent       = matomo.consent ?? false;
    const npsDiffDays   = this.state.readNpsDiffDays();
    
    // LIVE time span = 30 days; TEST = 60 seconds
    const minutesPerDay = 24 * 60;
    const minTimeSpanDays = this.config.isTestDomain
      ? (1 / minutesPerDay) // 0.00069
      : this.config.nps_timespan
    ;
    
    this.npsFormAllowed = consent && npsDiffDays > minTimeSpanDays; 
    
    this.trans.nps_label_comment = this.trans.nps_label_comment.replace('%n', String(this.config.nps_max_char));
    
    DEBUG && console.log('NPS.form', consent, npsDiffDays, minTimeSpanDays);
      
  }
  
  public npsChange () {
    this.npsSendAllowed = true;
    DEBUG && console.log('npsChange', String(this.npsScore?.[0]));
  }
  
  public npsSend () { 
    
    const npsString = (new Date).toJSON();
    this.state.saveNpsDate(npsString);
    
    this.npsSendAllowed    = false;
    this.npsFormAllowed    = false;
    
    const comment = (this.compTextArea.value ?? '').split('\n').join(' ');
    
    const payload = [
      this.config.isTestDomain ? 'TEST' : 'LIVE', 
      (new Date).toJSON().slice(0, 10),
      String(this.compInputGroup.value[0]),
      String(),
    ].join(' $$ ');
    
    this.matomo.trackEvent(
      'NPS',
      payload,
      'trackname',
      String(this.compInputGroup.value[0]),
    )
    
    this.notification.clear();
    this.notification.update({
      id:'prNpsNotification',
      variant: 'info',
      title: this.trans.nps_thanks,
      text: '',
      duration: 10,
      needsFeedback: false
    });
    
  }
  
}
