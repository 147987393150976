import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { I18n } from '@pr/core/i18n.module';
import { ApiService } from '@pr/core/api.service';
import { MetaService } from '@pr/core/meta.service';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '@pr/core/config.service';
import { StateService } from '@pr/core/state.service';

@Component({
  selector: 'pr-page-notfound',
  templateUrl: './notfound.page.html',
  styleUrls: ['./notfound.page.scss']
})
export class NotfoundPage implements OnDestroy {

  public trans = {
    'app_title':        $localize`:@@app.title:`,
    'back_start_page':  $localize`:@@app.backlink.start.label:`,
    'title':            $localize`:@@page.notfound.title.long:`
  };

  public markdown = '';

  private subscriber$: Subscription | undefined;
  
  constructor (
    public readonly i18n:     I18n,
    public readonly config:   ConfigService,
    public readonly state:    StateService,
    private readonly meta:    MetaService,
    private readonly route:   ActivatedRoute,
    private readonly api:     ApiService,
    private readonly cd:      ChangeDetectorRef,
  ) {

    this.meta.addTags('impressum');

    this.subscriber$ = this.route.data.subscribe(data => {

      const root   = this.config.environment.markdownroot;
      const locale = this.i18n.locale;
      const url    = `${root}/${locale}/${data['markdown'] as string}`;

      this.api.markdown(url).subscribe( markdown => {
        this.markdown = markdown;
        this.cd.detectChanges();
      });

    });

  }
  
  public ngOnDestroy (): void {
    this.subscriber$?.unsubscribe();
  }
  
}
