import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '@drv-ds/drv-design-system-ng';
import { Observable, throwError, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IAPIFachgruppen, IAPIFascs, IAPIFilter, IAPIMarker, IAPIPlzs, IAPIResponse, IAPITokens } from '@pr/core/interfaces';
import { ConfigService } from './config.service';

// import markerJson from '../../assets/api/pr.marker.de.json';
import markerJson from '../../assets/api/pr.marker.small.de.json';
import tokensJson from '../../assets/api/json/pr.tokens.de.json';
import filterJson from '../../assets/api/json/pr.filter.de.json';
import fascsJson from '../../assets/api/json/pr.fasc.de.json';
import plzsJson from '../../assets/api/json/pr.plzs.de.json';
import fachgruppenJson from '../../assets/api/json/pr.fachgruppen.de.json';

type TParams = { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean> }

const DEBUG = false;

const imports: Record<string, IAPIResponse<unknown>> = {
  '/tokens':      tokensJson as IAPIResponse<IAPITokens>,
  '/filter':      filterJson as IAPIResponse<IAPIFilter>,
  '/fascs':       fascsJson as IAPIResponse<IAPIFascs>,
  '/plzs':        plzsJson as IAPIResponse<IAPIPlzs>,
  '/fachgruppen': fachgruppenJson as IAPIResponse<IAPIFachgruppen>,
  '/marker':      markerJson as IAPIResponse<IAPIMarker>,
} as const;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor (
    private readonly http: HttpClient,
    private readonly notification: NotificationService,
    private readonly config: ConfigService,
    private readonly viewportScroller: ViewportScroller,
    private readonly router: Router,
  ) { }

  // Debug, formats and logs api requests to console
  private log<T> (endpoint: string, response: IAPIResponse<T>, t: number): void {

    const shortEndpoint = ' .../' + endpoint.split('/').slice(-2).join('/');
    const ms = `${Date.now() - t} ms`;
    console.log(`%cAPI:${shortEndpoint}`, 'font-weight: 800', ms, response);

  }

  private handleMessages<T> (response: IAPIResponse<T>): void {

    if (response.messages.length) {

      const msg = response.messages.filter( m => m.severity.toLowerCase() !== 'info' )[0];

      if (msg && msg.code === 'zdb.messages.details.einrichtung.notfound') {
        // ZVWBG2258-359
        this.router.navigate(['/', 'de', '404-facility-not-found']);
        return;
        
      } else if (msg) {
        this.notification.clear();
        this.notification.update({
          id:      'prAppNotification',
          variant: msg.severity.toLowerCase() === 'error' ? 'alert' : 'info',
          title:   'API Error aufgetreten',
          text:    msg.text
        });

        // scroll up, so user sees message
        this.viewportScroller.scrollToPosition([0, 0]);

      }

    }

  }

  private handleErrorXX (error: HttpErrorResponse): void {
    // triggers global error
    throwError(() => new Error(String(error)));
  }

  private handleError (this: void, error: HttpErrorResponse): Observable<never> {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => errorMessage);
  }

  private createHeaders (): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Accept':       'application/json',
    });
  }

  private createURL (selector: string, parameter: TParams): string {

    const endpoint = this.config.endpoints[selector];
    const api = this.config.api;
    const base = this.config.environment.basehref;

    // TODO: I18n
    if (endpoint.endsWith('json')) {
      return `${base}/assets/api/${endpoint}`;

    } else {
      return `${api.url}${endpoint}`;

    }

  }

  public markdown(url: string): Observable<string> {

    const headers =  new HttpHeaders({
      'Content-Type': 'text/markdown; charset=utf-8',
    })

    return this.http
      .get( url, { headers, responseType: 'text' })
      .pipe(
        tap( (resp) => {
          const shortEndpoint = ' .../' + url.split('/').slice(-2).join('/');
        })
      )
    ;

  }

  public get<T>( endpoint: string, parameter={} ): Observable<IAPIResponse<T>> {

    const t1      = Date.now();
    // removes props w/ undefined
    const params  = JSON.parse(JSON.stringify(parameter)) as TParams;
    const url     = this.createURL(endpoint, params);
    const headers = this.createHeaders();

    // take either data from imports or API
    const obs = endpoint in imports
      ? of<any>(imports[endpoint])
      : this.http.get<IAPIResponse<T>>( url, { headers, params } )
    ;

    // pipe response and check warnings + log to console
    return obs.pipe<IAPIResponse<T>>(
      tap( (resp: IAPIResponse<T>) => {
        this.handleMessages<T>(resp);
        this.log<T>(endpoint, resp, t1)
      })
    );

  }

  public post<T>(endpoint: string, body = {}, parameter = {}): Observable<IAPIResponse<T>> {

    // removes props w/ undefined
    const params: TParams  = JSON.parse(JSON.stringify(parameter));

    const t1      = Date.now();
    const url     = this.createURL(endpoint, parameter);
    const headers = this.createHeaders();

    console.log(url, body);

    return this.http
      .post<IAPIResponse<T>>( url, { headers, params, body } )
      .pipe<IAPIResponse<T>>(
        tap( (resp: IAPIResponse<T>) => {
          this.handleMessages<T>(resp);
          this.log<T>(endpoint, resp, t1)
        }),
        // catchError( this.handleError )
      )
    ;

  }

}
