<div drv-row (window:resize)="onResize()">

  <!-- DETAILS ONLY  -->
  <ng-container *ngTemplateOutlet="contentBAR"></ng-container>

  <!-- BIG SCREEN -->
  @if (width > 768) {
    <div drv-col [sm]="4" [md]="{ span: 6, offset: 0}" class="" style="margin-top: 7rem; margin-left: -1rem">
      <ng-container *ngTemplateOutlet="contentIMG"></ng-container>
    </div>
  }

  @if (width > 768) {
    <div drv-col [sm]="4" [md]="{ span: 6, offset: 0}">
      <div drv-row>
        <div drv-col class="drv-bg--ui-05 drv-pt-sm--04" [sm]="{ span: 4, offset: 0, bleed: 'both' }">
          <span class="pr-claim" i18n="@@app.title">app.title</span>
        </div>
      </div>
      <div drv-row>
        <div drv-col [sm]="4" [md]="{ span: 12, offset: 0, bleed: 'both' }"
          class="drv-pt-sm--02 drv-pb-sm--04 drv-bg--ui-05">
          <ng-container *ngTemplateOutlet="contentH1"></ng-container>
        </div>
      </div>
      <div drv-row>
        <div drv-col [sm]="4" class="drv-pt-sm--04 drv-pb-sm--00">
          <ng-container *ngTemplateOutlet="contentSPAN"></ng-container>
        </div>
      </div>
    </div>
  }

  <!-- SMALL SCREEN -->
  @if (width < 768) {
    <div drv-col class="drv-bg--ui-05 drv-pt-sm--04" [sm]="{ span: 4, offset: 0, bleed: 'both' }">
      <span class="pr-claim" i18n="@@app.title">app.title</span>
    </div>
  }

  @if (width < 768) {
    <div drv-col [sm]="{ span: 4, offset: 0, bleed: 'both' }"
      class="drv-pt-sm--04 drv-pb-sm--04 drv-bg--ui-05" style="padding-bottom: 12rem">
      <ng-container *ngTemplateOutlet="contentH1"></ng-container>
    </div>
  }

  @if (width < 768) {
    <div drv-col [sm]="4" class="drv-pt-sm--04" style="margin-top: -14rem">
      <ng-container *ngTemplateOutlet="contentIMG"></ng-container>
    </div>
  }

  @if (width < 768) {
    <div drv-col [sm]="4" class="drv-pt-sm--04 ">
      <ng-container *ngTemplateOutlet="contentSPAN"></ng-container>
    </div>
  }

  <ng-template #contentBAR><ng-content select="pr-cell-header-bar"></ng-content></ng-template>

  <ng-template #contentH1><ng-content select="h1"></ng-content></ng-template>
  <ng-template #contentIMG><ng-content select="img"></ng-content></ng-template>
  <ng-template #contentSPAN><ng-content select="span"></ng-content></ng-template>

</div>
