import { Injectable } from '@angular/core';
import { INode } from '@pr/core/interfaces';

@Injectable({
  providedIn: 'root'
})
export class MarkdownService {

  public traverseNodes (
    node: INode,
    test: (node: INode)=>boolean,
    callback: (node: INode)=>boolean|void ) {

    let ignoreChilds = false;

    if ( test(node) ) {
      ignoreChilds = callback(node) ?? false;
    }

    if (!ignoreChilds) {
      node.children?.forEach( (node: INode) => {
        this.traverseNodes(node, test, callback);
      });
    }

  }

  public createProcMast (node: INode): INode {

    const stack: any[] = [ { children: [] } ];

    node.children.forEach( (child: INode) => {

      let collector = stack.slice(-1)[0];

      if (child.type === 'html' && child.value === '<accordion-wrapper>') {
        child.type     = 'accordion-wrapper-start';
        child.children = [];
        stack.push(child);

      } else if (child.type === 'html' && child.value === '</accordion-wrapper>') {
        stack.pop();
        return;

      } else if (child.type === 'html' && child.value === '<accordion-item>') {
        child.type     = 'accordion-item';
        child.children = [];
        stack.push(child);

      } else if (child.type === 'html' && child.value === '</accordion-item>') {
        const title      = collector.children.shift();
        collector.title  = title.children[0].value;
        collector.anchor = this.slugify(collector.title);
        collector.isOpen = false;
        collector.level  = title.depth;
        stack.pop();
        return;

      } else if (child.type === 'html' && child.value === '<details-item>') {
        child.type     = 'details-item';
        child.children = [];
        stack.push(child);

      } else if (child.type === 'html' && child.value === '</details-item>') {
        const title      = collector.children.shift();
        collector.title  = title.children[0].value;
        collector.anchor = this.slugify(collector.title);
        collector.isOpen = false;
        collector.level  = title.depth;
        stack.pop();
        return;

      } else if (child.type === 'html' && child.value.startsWith('<tooltip')) {
        child.type       = 'pr-tooltip';
        child.identifier = child.value.match(/identifier=\"(.*)\"/)![1] ?? undefined;
        child.children   = [];
        stack.push(child);

      } else if (child.type === 'html' && child.value === '</tooltip>') {
        stack.pop();
        return;
      }

      collector.children.push(child);

    });

    node.children = stack[0].children;

    return node;

  }

  private slugify (line: string): string {

    // turns accordion title into valid anchor string a-z, -

    return (line + '')
      .toLowerCase()
      .trim()
      .replace(/ä/g, 'ae')
      .replace(/ö/g, 'oe')
      .replace(/ü/g, 'ue')
      .replace(/ß/g, 'ss')
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '')
    ;

  }

    // strictly debug
  public logNodes (level: number, nodes: any) {

    function firstText (nodes: any) {
      const textNode = nodes.find( (n: any) => n.type === 'text');
      return textNode ? textNode.value : '';
    }

    let tab = '  '.repeat(level < 0 ? 0 : level);

    nodes.forEach( (n: any) => {

      if (n.type === 'heading') {
        console.log(
          `%c${tab}md.${n.type.slice(0, 4)}`, 'font-weight: 800; color: blue',
          n.depth ? n.depth : ' ',
          n.children ? n.children.length : ' ',
          firstText(n.children).slice(0, 20),
        );
        this.logNodes(level + 1, n.children);

      } else if (n.type === 'paragraph') {
        console.log(
          `%c${tab}md.${n.type.slice(0, 4)}`, 'font-weight: 800; color: green',
          n.depth ? n.depth : ' ',
          n.children ? n.children.length : ' ',
        );
        this.logNodes(level + 1, n.children);

      } else if (n.type === 'text') {

        console.log(
          `${tab}md.${n.type.slice(0, 4)}`,
          n.depth ? n.depth : ' ',
          n.children ? n.children.length : ' ',
          n.value.slice(0, 40)
        );

      } else if (n.type === 'strong') {

        console.log(
          `${tab}md.${n.type.slice(0, 4)}`,
          n.depth ? n.depth : ' ',
          n.children ? n.children.length : ' ',
        );
        this.logNodes(level + 1, n.children);

      } else if (n.type === 'list') {

        console.log(
          `${tab}md.${n.type.slice(0, 4)}`,
          n.depth ? n.depth : ' ',
          n.children ? n.children.length : ' ',
        );
        this.logNodes(level + 1, n.children);

      } else if (n.type === 'listItem') {

        console.log(
          `${tab}md.item`,
          n.depth ? n.depth : ' ',
          n.children ? n.children.length : ' ',
        );
        this.logNodes(level + 1, n.children);

      } else if (n.type === 'link') {

        console.log(
          `${tab}md.${n.type.slice(0, 4)}`,
          n.depth ? n.depth : ' ',
          n.children ? n.children.length : ' ',
          n.url.slice(0, 20)
        );
        this.logNodes(level + 1, n.children);


      } else if (n.type === 'html') {

        console.log(
          `%c${tab}md.${n.type.slice(0, 4)}`, 'font-weight: 800; color: darkred',
          n.depth ? n.depth : ' ',
          n.children ? n.children.length : ' ',
          n.value
        );

      } else if (n.type === 'break') {

        console.log(
          `${tab}md.${n.type.slice(0, 4)}`,
            n.depth ? n.depth : ' ',
            n.children ? n.children.length : ' ',

        );

      } else if (n.type === 'accordion-wrapper-start') {

        console.log(
          `%c${tab}md.${n.type}`, 'font-weight: 800; color: darkorange',
          n.depth ? n.depth : ' ',
          n.children ? n.children.length : ' ',
        );
        this.logNodes(level + 1, n.children);

      } else if (n.type === 'accordion-wrapper-end') {

        console.log(
          `%c${tab}md.${n.type}`, 'font-weight: 800; color: darkorange',
          n.depth ? n.depth : ' ',
          n.children ? n.children.length : ' ',

        );

      } else if (n.type === 'accordion-item') {

        console.log(
          `%c${tab}md.${n.type}`, 'font-weight: 800; color: darkorange',
          n.depth ? n.depth : ' ',
          n.children ? n.children.length : ' ',
        );
        this.logNodes(level + 1, n.children);

      } else if (n.type === 'details-item') {

        console.log(
          `%c${tab}md.${n.type}`, 'font-weight: 800; color: darkorange',
          n.depth ? n.depth : ' ',
          n.children ? n.children.length : ' ',
        );
        this.logNodes(level + 1, n.children);

      } else {

        console.log(
          tab,
          n.type,
          n.depth ? n.depth : ' ',
          n.children ? n.children.length : ' ',
          n
        );

      }


    })

  }

}

