import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@drv-ds/drv-design-system-ng';
import { StateService } from '@pr/core/state.service';

@Component({
  selector: 'pr-page-reset',
  template: '',
  styles: []
})
export class ResetPage {

  constructor (
    private readonly state: StateService,
    private readonly router: Router,
    private readonly notification: NotificationService,
  ) {
    this.state.clear();
    this.notification.clear();
    this.router.navigate(['/', 'de']);
  }

}
