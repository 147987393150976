import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { IErgebnisResults } from './interfaces';
import { IAPIErgebnis } from './interfaces.api';
import { IState } from './state.service';

const DEBUG = false;

// https://blog.herodevs.com/interact-with-browser-storage-type-safe-fee0ad07428f

export enum StorageKey {
  State          = 'state',
  Locale         = 'locale',
  BackTarget     = 'backTarget',
  ErgebnisResult = 'ergebnisResult',
  ApiErgebnis    = 'apiErgebnis',
  NpsDate        = 'npsDate',
}

interface Model {
  [StorageKey.State]: IState;
  [StorageKey.Locale]: 'de' | 'en';
  [StorageKey.BackTarget]: 'start' | 'ergebnis';
  [StorageKey.ApiErgebnis]: IAPIErgebnis;
  [StorageKey.ErgebnisResult]: IErgebnisResults;
  [StorageKey.NpsDate]: string;
}

interface IStorageService<T> {
  get<K extends keyof T>(key: K): T[K] | null;
  set<K extends keyof T>(key: K, value: T[K]): void;
  remove<K extends keyof T>(key: K): void;
  clear(): void;
}

@Injectable({
  providedIn: 'root'
})
export class StorageService implements IStorageService<Model> {

  private storage: Storage;
  public type: string = '';

  constructor ( private readonly config: ConfigService) {
    this.type    = this.config.storage
    this.storage = this.type === 'localStorage'
      ? localStorage
      : sessionStorage
    ;
  }

  public set<K extends keyof Model>(key: K, value: Model[K]): void {
    this.storage.setItem(key.toString(), JSON.stringify(value));
    DEBUG && console.log('StorageService.set', key, value);
  }

  public get<K extends keyof Model>(key: K): Model[K] | null {

    const value = this.storage.getItem(key.toString());

    if (value === null || value === 'null' || value === undefined || value === 'undefined') {
      return null;

    } else {
      return JSON.parse(value);

    }

  }

  public remove<K extends keyof Model>(key: K): void {
    this.storage.removeItem(key.toString());
  }

  public clear(): void {
    this.storage.clear();
  }

}
