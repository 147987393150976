@for (child of children; track child) {
  @switch (child.type) {
    <!-- PLAIN TEXT -->
    @case ('text') {
      <span [innerText]="child.value"></span>
    }
    <!-- STRONG -->
    @case ('strong') {
      <strong [nodes]="child"></strong>
    }
    <!-- EMPHASIS -->
    @case ('emphasis') {
      <em   [nodes]="child"></em>
    }
    <!-- PARAGRAPH -->
    @case ('paragraph') {
      <p   [nodes]="child" ></p>
    }
    <!-- LIST -->
    @case ('list') {
      <ul       [nodes]="child"></ul>
    }
    <!-- BREAK -->
    @case ('break') {
      <br />
    }
    <!-- LINKS -->
    @case ('link') {
      @if (linktype(child?.url) === 'intern') {
        <a class="drv-icon-link"
          [routerLink]="makeInternal(child?.url)"
          [fragment]="getFragment(child?.url)"
          [title]="child.title"
          >
          {{ child.children[0].value }}
        </a>
      }
      <!-- e.g. ./leicht -->
      @if (linktype(child?.url) === 'subpage') {
        <a class="drv-icon-link"
          [routerLink]="child?.url"
          [title]="child.title"
          >
          {{ child.children[0].value }}
        </a>
      }
      @if (linktype(child.url ?? '') === 'extern') {
        <drv-iconlink
          icon="arrow-external-small"
          [href]="child.url ?? ''"
          subtitle=""
          target="_blank"
          [title]="child.title"
          >
          {{ child.children[0].value }}
        </drv-iconlink>
      }
      @if (linktype(child.url ?? '') === 'email') {
        <drv-iconlink
          icon="mail"
          [href]="child.url ?? ''"
          [title]="child.title ?? ''"
          >
          {{ child.children[0].value }}
        </drv-iconlink>
      }
    }
    <!-- TOOLTIPS -->
    @case ('pr-tooltip') {
      <div [nodes]="child"></div>
    }
    <!-- DEFAULT & DEBUG -->
    @default {
      <div>
        DEFAULT-BLOCK {{ child | json }}
      </div>
    }
  }
}
