import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { I18n } from '@pr/core/i18n.module';
import { StateService } from '@pr/core/state.service';
import { ConfigService } from '@pr/core/config.service';
import { ApiService } from '@pr/core/api.service';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { MetaService } from '@pr/core/meta.service';
import { MatomoService } from '@pr/core/matomo.service';

const DEBUG = false;

@Component({
  selector: 'pr-page-datenschutz',
  templateUrl: './datenschutz.page.html',
  styleUrls: ['./datenschutz.page.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DatenschutzPage implements OnInit, OnDestroy {

  public trans = {
    'app_title':          $localize`:@@app.title:`,
    'back_link':          $localize`:@@app.backlink.start.label:`,
    'title':              $localize`:@@app.page.datenschutz.title:`,
    'hits':               $localize`:@@app.search.hits:`,
    'search_button':      $localize`:@@page.hilfe.search.button.label:`,
    'search_label':       $localize`:@@page.hilfe.search.input.label:`,
    'search_placeholder': $localize`:@@page.hilfe.search.input.placeholder:`,
    'back_start_page':    $localize`:@@app.backlink.start.label:`,
  };

  public markdown = '';
  public fragment = '';
  public search   = '';
  public toggle   = true;
  public anchors  = false;
  public hits     = 0;
  public pageLink: string[];

  private debouncer: Subject<string> = new Subject<string>();
  private subscriber$: Subscription | undefined;

  constructor (
    public readonly i18n:     I18n,
    public readonly state:    StateService,
    public readonly config:   ConfigService,
    private readonly api:     ApiService,
    private readonly meta:    MetaService,
    private readonly matomo:  MatomoService,
    private readonly route:   ActivatedRoute,
    private readonly cd:      ChangeDetectorRef,
  ) {

    this.meta.addTags('datenschutz');

    this.pageLink = ['/', 'datenschutz'];

    // debounce fast typing users
    this.debouncer
      .pipe(debounceTime(300))
      .subscribe((value) => this.search = value)
    ;

    this.subscriber$ = this.route.data.subscribe(data => {

      const root   = this.config.environment.markdownroot;
      const locale = this.i18n.locale;
      const url    = `${root}/${locale}/${data['markdown'] as string}`;

      this.api.markdown(url).subscribe( markdown => {
        this.markdown = markdown;
        this.cd.detectChanges();
      });

    });

  }

  public ngOnInit(): void {

    this.route.fragment.subscribe( fragment => {
      if (fragment) {
        this.fragment = fragment;
      }
    })

  }

  public ngOnDestroy (): void {
    this.subscriber$?.unsubscribe();
  }

  // user input
  onSearch (e: any | string): void {

    const search = (e.target && typeof e.target.value === 'string') ? String(e.target.value) : String(e);

    if (search.trim().length) {
      // search was disabled
      // this.debouncer.next(search.trim());
      // const trackEvent = ['trackEvent', 'datenschutz.suche', search.trim()];
      // this.matomo.trackEvent(trackEvent);
    }

  }

  // markdown feedback #hits
  onHits (hits: number): void {
    DEBUG && console.log('PageHilfe.onHits', hits);
    this.hits = hits;
    this.cd.detectChanges();
  }

  // probably disabled on this page
  onAntrag (): void {
    DEBUG && console.log('PageHilfe.onAntrag');
  }

  // from markdown helpers
  onFile (markdown: string) {
    DEBUG && console.log('PageHilfe.onFile', markdown.length);
    this.markdown = markdown;
  }

  // from markdown helpers
  onToggle (foldin: boolean) {
    this.toggle = foldin;
  }

  // from markdown helpers
  onAnchors (show: boolean) {
    console.log('PageHilfe.onAnchors', show);
    this.anchors = show;
  }

}
