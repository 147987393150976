import { Component, OnChanges, Input, Output, EventEmitter, ChangeDetectionStrategy, ViewChild, AfterViewInit, ChangeDetectorRef, Inject } from '@angular/core';

import { FilterComponent, FilterOptions, FormItemMessage, SliderComponent, TextinputComponent } from '@drv-ds/drv-design-system-ng';

import plzsJson from '../../../assets/api/json/pr.plzs.de.json';

import { IActiveFilter, IAPIPlzs, IAPIResponse } from '@pr/core/interfaces';
import { ConfigService } from '@pr/core/config.service';
import { I18n } from '@pr/core/i18n.module';
import { DOCUMENT } from '@angular/common';

const DEBUG = true;

const imports: Record<string, IAPIResponse<IAPIPlzs>> = {
  'plzs':        plzsJson as IAPIResponse<IAPIPlzs>,
}

@Component({
  selector: 'pr-cell-filterumkreis',
  templateUrl: './filterumkreis.cell.html',
  styleUrls: ['./filterumkreis.cell.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterUmkreisCell implements OnChanges, AfterViewInit {
  
  public trans = {
    'link_hilfe':           $localize`:@@page.ergebnis.filters.link:`,
    'filter_delete':        $localize`:@@page.ergebnis.filters.delete:`,
    'plz_label':            $localize`:@@page.ergebnis.filters.plz.label:`,
    'plz_error':            $localize`:@@page.ergebnis.filters.plz.error:`,
    'radius_error':         $localize`:@@page.ergebnis.filters.radius.error:`,
    'plzradius_error':      $localize`:@@page.ergebnis.filters.plzradius.error:`,
    'slider_label':          'Umkreis (in km)',
    'filter_umkreis_label':  'Umkreisusche',  // trans missing
    'search_radius':         'Umkreissuche'
  }
  
  public selectCount: number = 0;
  
  public activateSlider = false;
  public options: FilterOptions[] = [];
  
  public plzMask = { mask: /^\d{0,5}$/ };
  public plzHasHint = false;
  public plzHintText: FormItemMessage[] = [];
  public radiusHasHint = false;
  public radiusHintText: string[] = [''];
  
  @Input()  activeFilter: IActiveFilter | undefined;
  @Input() public buttonText: string | undefined;

  @Output() activeFilterChange: EventEmitter<IActiveFilter> = new EventEmitter<IActiveFilter>();
  @Output() events: EventEmitter<[string, string]> = new EventEmitter<[string, string]>();
 
  @ViewChild(FilterComponent)    compFilter!: FilterComponent;
  @ViewChild(SliderComponent)    compSlider!: SliderComponent;
  @ViewChild(TextinputComponent) compInput!: TextinputComponent;

  constructor ( 
    public readonly i18n:  I18n,
    private readonly cfg:  ConfigService,
    private readonly cd:    ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {}
    
  public ngOnChanges (): void {
    
    this.updateCount(
      this.activeFilter && this.activeFilter.radius && this.activeFilter.plz
      ? 1 : 0
    );

  }
  
  public ngAfterViewInit () {

    // listen to changes and remove hints
    this.compInput!.input.nativeElement.addEventListener('input', this.onInputChange.bind(this));
    // this.compInput!.input.nativeElement.addEventListener('blur', () => {
    //   console.log('GOT Blur')
    // });
    
    // Monkey Patch FocusOut on PLZ Input
    const oldButtonFocusout = this.compFilter['buttonFocusout'];
    this.compFilter['buttonFocusout'] = (event: any) => {
      // console.log('filterUmkreis.compFilter.buttonFocusout');
      oldButtonFocusout.bind(this.compInput)(event);
    }
    
    
    // Monkey Patch FocusOut on PLZ Input
    const oldFocusOut = this.compInput['onFocusout'];
    
    this.compInput['onFocusout'] = (event: any) => {
      const value = this.compInput.input.nativeElement.value;
      // console.log('filterUmkreis.compInput.onFocusOut', this.compInput.input.nativeElement.value);
      if (this.existsPlz(value)){
        this.compSlider.input.nativeElement.focus();
      } else {
        this.compInput.input.nativeElement.focus();
      }
      // oldFocusOut.bind(this.compInput)(event);
    }
    
    // no plz - no slider
    this.activateSlider =  this.existsPlz(this.activeFilter?.plz ?? '');

    // update filtercount in gui
    this.ngOnChanges();
          
  }
  
  private updateCount(count: number) {
    
    this.selectCount = count;
    
    const html     = `<span class="drv-btn__counter">${count}<span class="drv-vh">&nbsp;Filter gesetzt</span>`
    const selector = 'pr-cell-filterumkreis span.drv-filter__wrapper > button > span.drv-btn__text';
    const $parent  = this.document.querySelector(selector);
    
    let $counter = $parent?.querySelector('span.drv-btn__counter');
    
    if ($counter) {
      if (count === 0) {
        $counter.parentNode?.removeChild($counter);
        
      } else {
        $counter.innerHTML = html;
        
      }
    
    } else {
      if (count === 0) { } else {
        $counter = this.document.createElement('SPAN')
        $counter.innerHTML = html;
        $parent?.appendChild($counter);
      }
      
    }
    
  }
 
  private onInputChange (e: KeyboardEvent) {
    
    this.resetHints();
    
    const value = this.compInput!.input.nativeElement.value;
    const goodPlz = this.existsPlz(value);
    
    this.activateSlider = goodPlz
    this.cd.detectChanges();
    
  }
  
  public onUpdate (event: [string, string]): void {
    
    /*  
    (change)="onUpdateFilter('plzradius')" =>
      triggert nach neuem Wert in PLZ und enter
      triggert nach neuem Wert in RADIUS und enter
      triggert nach neuem Wert mit slide und MouseUp
      "page.ergebnis.filters.plz.error";"Bitte gültige Postleitzahl angeben"
      "page.ergebnis.filters.plzradius.error";"Bitte gültige Postleitzahl und den Radius angeben"
      "de";"page.ergebnis.filters.radius.error";"Bitte den Radius angeben"
    */
    
    // quick exit
    if (!this.activeFilter) { return; }
    
    const [what, info]  = event;
    const defaultRadius = this.cfg.defaultState.radius;
    
    // assume no errors
    let needsUpdate = true;
       
    // make copy to validate, keep original
    let update = Object.assign({}, this.activeFilter ?? {} as IActiveFilter);

    if (what === 'deleteplzradius') {
      update.plz       = '';
      update.radius    = defaultRadius;
      this.resetHints()
      
    } else if (what === 'plzradius') {
      
      // assmue good values
      let goodPlz      = true;
      let goodRadius   = true;
      // this.updateCount(1);

      // plz not valid, show hint
      if (!this.existsPlz(update.plz)) {
        this.plzHasHint  = true;
        this.plzHintText = [{ message: this.trans.plz_error }];
        goodPlz          = false;
      }

      if (goodPlz && goodRadius) {
        this.updateCount(1);
        this.resetHints();

      } else {
        console.log('FilterUmkreis.bad data', update);
        this.plzHintText = (
          !goodRadius && !goodPlz ? [{ message: this.trans.plzradius_error }] :
          !goodPlz    ? [{ message: this.trans.plz_error }] :
          !goodRadius ? [{ message: this.trans.radius_error }] :
          [{ message: '' }]
        );
        // put focus back
        this.compInput!.input.nativeElement.focus();

        needsUpdate = false;

      }
    
    }
    
    // update parent filtercollection
    if (needsUpdate) {
      this.activeFilterChange.emit(update);
      this.events.emit(event);
    }

    this.cd.detectChanges();

  }
 
  private existsPlz (plz: string): boolean {
    return plz in imports['plzs'].data;
  }
  
  public reset () {
    // const defaultRadius = this.cfg.defaultState.radius;
    // this.activeFilter!.plz = '';
    // this.activeFilter!.radius = defaultRadius;
    this.resetHints();
    this.updateCount(0);
  }
  
  private resetHints () {
    this.plzHasHint     = false;
    this.plzHintText    = [{ message: '' }];
    this.radiusHasHint  = false;
    this.radiusHintText = [''];
  }
  
}
