import { IEnvironment } from './environment.interface'

export const environment: IEnvironment = {
  production: false,
  development: true,
  name: 'DEV',
  enableProdMode: false,
  basehref: '/pr-web/',
  apitype: 'json',
  apiroot: '///localhost:3000/api',
  apifolder: 'assets/api',
  markdownroot: 'assets/api/markdown'
};
