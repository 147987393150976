<div style="position: relative; width: 100%;">

  <div class="loader-container" [ngStyle]="{ 'visibility': showLoader ? 'visible' : 'hidden' }">
    <pr-atom-loading [size]="80" [ngStyle]="{ 'z-index': showLoader ? 1 : 0}"></pr-atom-loading>
  </div>

  <div class="filtercollection-container" 
    [ngStyle]="{ 'visibility': showLoader ? 'hidden' : 'visible' }" 
    style="min-height: 100px;"
  >

    @for (group of filterGroups; track group; let id = $index) {
      <drv-buttonbar 
        #compButtonbar 
        variant="narrow" 
        layoutMobile="block"
      >
        <!-- FILTER -->
        @if (group.type !== 'umkreis' && group.visible) {
          <drv-filter
            [id]="'id-filter-' + id"
            label=""
            [labelVisible]="false"
            backgroundUI="color"
            [disabled]="!!group.disabled"
            [buttonText]="group.label"
            [multiple]="group.multiple"
            [options]="group.options"
            [(ngModel)]="group.model"
            (onClose)="onUpdateFilter(['merkmal', group.label])"
          ></drv-filter>
        }
        
        <!-- PLZ/UMKREIS TODO: LOCALIZE-->
        @if (group.type === 'umkreis' && group.visible) {
          <pr-cell-filterumkreis
            [(activeFilter)]="activeFilter"
            [buttonText]="group.label"
            (events)="onUpdateFilter($event)"
          ></pr-cell-filterumkreis>
        }
      </drv-buttonbar>
    }

  </div>

  <!-- DELETE -->
  <drv-button
    style="margin-left: -1rem; display: inline-block;"
    iconPosition="left"
    iconType="trash"
    kind="tertiary"
    [label]="trans.filter_delete"
    [disabled]="!hasFilters"
    (onClick)="onUpdateFilter(['deleteall', ''])"
  ></drv-button>

</div>
