import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, ElementRef, Inject } from '@angular/core';
import { DrvDropdownOption, TableComponent, TableHeaderItem } from '@drv-ds/drv-design-system-ng';

import { IErgFachabteilung, IErgebnisResults, IPagination, IPaginationInfo, TTableSort, TTableSortArray, TTableSortRecord } from '@pr/core/interfaces';
import { ConfigService } from '@pr/core/config.service';
import { StateService } from '@pr/core/state.service';
import { I18n } from '@pr/core/i18n.module';
import { TrefferCountService } from '../treffer-count/treffer-count.service';
import { DOCUMENT } from '@angular/common';

const DEBUG = false;

@Component({
  selector: 'pr-cell-table',
  templateUrl: './table.cell.html',
  styleUrls: ['./table.cell.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableCell {

  public isLoading = false;
  public data: IErgebnisResults | undefined;
  public tableHeader : { header: TableHeaderItem[] } | undefined = undefined;
  public pagination = {} as IPagination;
  public pageSizes: DrvDropdownOption[] = [];

  public selected = {
    // sort:        [],
    rowsperpage: [],
  } as Record<'rowsperpage' | 'sort', DrvDropdownOption[] | []>;

  public trans = {
    "zeilen":                $localize`:@@page.ergebnis.pagination.lines:`,
    "item":                  $localize`:@@page.ergebnis.pagination.items:`,
    "of":                    $localize`:@@page.ergebnis.pagination.of:`,
    "previous_page":         $localize`:@@page.ergebnis.pagination.previous.page:`,
    "next_page":             $localize`:@@page.ergebnis.pagination.next.page:`,
    "page_text":             $localize`:@@page.ergebnis.pagination.page:`,
    "title_quality_fa":      $localize`:@@page.ergebnis.circle.title.fachabteilung:`,
    "title_quality_vg":      $localize`:@@page.ergebnis.circle.title.vergleichsgruppe:`,
    "reha_anschluss_false":  $localize`:@@page.ergebnis.table.ahb.no:`,
    "reha_anschluss_true":   $localize`:@@page.ergebnis.table.ahb.yes:`,
    "qualitaet_nodata":      $localize`:@@page.ergebnis.table.qualitaet.nodata:`,
  };

  @ViewChild(TableComponent) compTable: TableComponent | undefined;

  @Output() rowclick: EventEmitter<number> = new EventEmitter<number>();
  @Output() sort:     EventEmitter<TTableSortArray> = new EventEmitter<TTableSortArray>();
  @Output() page:     EventEmitter<IPagination> = new EventEmitter<IPagination>();

  @Input()  public set results (data: IErgebnisResults | undefined) {

    if (data?.fachabteilungen) {

      // always first page on new data or new sort
      const activepage  = '1';

      this.data         = data;
      this.tableHeader  = { header: data?.headerTable };
      this.state.update({ activepage });
      this.onSelectActivePage(activepage, false);

      // prep model for rowsperpage dropdown
      const rowsValue = this.state.get<TTableSort>('rowsperpage');
      const rowsLabel = this.pageSizes.find( option => option.value === rowsValue )?.label ?? 'unknown';
      this.selected.rowsperpage = [{ label:rowsLabel , value: rowsValue }];

      DEBUG && console.log('TableCell.setResults', 'state', rowsValue, 'data', this.tableHeader.header);

    }

  }

  constructor (
    public readonly i18n:        I18n,
    public readonly state:       StateService,
    public readonly config:      ConfigService,
    private readonly cd:         ChangeDetectorRef,
    private readonly svcTreffer: TrefferCountService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {

    // translate pageSizes for dropdown
    this.pageSizes = this.config.pageSizes.map((item: DrvDropdownOption) => ({
      label: item.label?.replace('%s', this.trans.zeilen),
      value: String(item.value)
    }));

    DEBUG && console.log('Table.cell.pagesizes', this.pageSizes);
    DEBUG && console.log(this.state.get<string>('rowsperpage'))


  }

  public onSort (e: TTableSortRecord): void {

    // check state of DS Table, ZVWBG2258-248
    // if table sort state is undefined, we need to sort again :(
    // !!! Changes with 4.27.7
// debugger;
    const item: TableHeaderItem | undefined = this.tableHeader?.header[e.index];
    // DS V4.27
    // const sortTable: TableHeaderItem[] | undefined = this.compTable?.data?.header; //  getSort( item as TableHeaderItem );
    const sortTable = 'FAKE'; //this.compTable?.getSort( item as TableHeaderItem );
    const [ index, order ] = this.state.get<TTableSort>('sort').split(',');
    const newSort = sortTable
      ? [String(e.index), e.order] as TTableSortArray
      : [String(index), order] as TTableSortArray
    ;

    this.sort.emit(newSort);

    DEBUG && console.log('TableCell.onSort.event', e.index, e.order, sortTable ? 'SEND' : 'OVERWRITTEN');

  }

  public onRowClick (id: number): void {
    DEBUG && console.log('TableCell.onRowClick', id);
    this.rowclick.emit(id);
  }

  public onSelectRowsPerPage (event: DrvDropdownOption[], scrollTop=false) {

    var rowsperpage = event[0].value
      ? parseInt(event[0].value, 10)
      : undefined
    ;

    if (rowsperpage && String(rowsperpage) !== this.state.get<string>('rowsperpage')) {

      const rows        = this.pagination.rows;

      this.pagination   = Object.assign({}, this.pagination, {
        rowsperpage,
        first:        0,
        last:         Math.min(rowsperpage, rows) -1,
        activePage:   1,
        maxPage:      Math.ceil(rows / rowsperpage),
      });

      Object.assign(this.pagination, this.paginationInfo());
      if (this.state.get('view') === 'liste') {
        this.svcTreffer.message({ treffer: rows, lines: [this.pagination.first +1, this.pagination.last +1]});
      }

      this.state.update({ rowsperpage: String(rowsperpage)});
      this.page.emit(this.pagination);
      this.cd.detectChanges();

      setTimeout( () => {
        scrollTop && this.document.querySelector('h1')
          // ?.scrollIntoView({ behavior: "smooth" });
          ?.scrollIntoView();
      }, 200);

    }

    DEBUG && console.log('onSelectRowsPerPage', 'new', event, 'cur', this.state.get<string>('rowsperpage'), this.pagination);

  }

  // any, because typo in DS PaginationComponent
  public onSelectActivePage (activepage: any, detectChanges=true, scrollTop=false) {

    const activePage  = parseInt(activepage, 10);
    const rows        = this.data?.fachabteilungen.length ?? 0;
    const rowsperpage = parseInt(this.state.get<string>('rowsperpage'), 10);
    const first       = (activePage -1) * rowsperpage;
    const last        = Math.min(rows, first + rowsperpage) -1;
    const maxPage     = Math.ceil(rows / rowsperpage);

    this.pagination   = Object.assign({}, this.pagination, {
      rows, rowsperpage, activePage, first, last, maxPage,
    });

    // now add UI infos
    Object.assign(this.pagination, this.paginationInfo());
    if (this.state.get('view') === 'liste') {
      this.svcTreffer.message({ treffer: rows, lines: [this.pagination.first +1, this.pagination.last +1]});
    }

    this.state.update({ activepage: String(activepage) });
    this.page.emit(this.pagination);

    detectChanges && this.cd.detectChanges();

    scrollTop && this.document.querySelector('h1')
      // ?.scrollIntoView({ behavior: "smooth" });
      ?.scrollIntoView();
    //this.document.querySelector('h1')?.scrollIntoView();

    DEBUG && console.log('onSelectActivePage', this.pagination);

  }

  public paginationInfo (): IPaginationInfo {

    // Einträge 5-10 von 100, base 1
    // Zeilen 3-7, base 1

    const rows     = this.pagination.rows;
    const rowsFrom = this.pagination.first +1;
    const rowsTo   = this.pagination.last  +1;

    return {
      info:      `${this.trans.item} ${rowsFrom}-${rowsTo} ${this.trans.of} ${rows}`,
      infoshort: `${this.trans.zeilen} ${rowsFrom}-${rowsTo}`
    };

  }

  public getActivePageRows (): IErgFachabteilung[] | undefined{
    const pos1 = this.pagination.first;
    const pos2 = this.pagination.last +1;
    return this.data?.fachabteilungen.slice(pos1, pos2);
  }

}
