<div class="page page-zumantrag drv-mt-sm--01">

  <pr-cell-header-bar>
    <drv-button
      class="back"
      style="margin-left: -1rem"
      [label]="pagebacktext"
      kind="tertiary"
      type="anchor"
      iconPosition="left"
      iconType="arrow-left"
      backgroundUI="light"
      (onClick)="location.back()"
    ></drv-button>
  </pr-cell-header-bar>

  <!-- CLAIM -->
  <pr-atom-claim></pr-atom-claim>

  <!-- TITLE -->
  <pr-atom-page-title  i18n="@@page.zumantrag.title.long">
    page.zumantrag.title.long
  </pr-atom-page-title>

  <!-- MARKDOWN -->
  <div drv-row class="drv-mt-sm--00">
    <div drv-col [sm]="4" [md]="{ span: 9, offset: 0 }" [lg]="{ span: 9, offset: 0 }">

      @if (markdown) {
        <cell-markdown
          [rawmarkdown]="markdown"
        ></cell-markdown>
      }

    </div>
  </div>

  <!-- WEITER ZUM ANTRAG BUTTON -->
  <div drv-row class="drv-mt-sm--02 drv-mb-sm--06">
    <div drv-col [sm]="4" style="display: flex; flex-direction: row; justify-content: left; ">

      <drv-button
        iconPosition="left"
        iconType="document-saved"
        backgroundUI="light"
        fullSizeOption='full'
        type="anchor"
        target="_blank"
        [label]="trans.button_text"
        [href]="trans.href_antrag"
      ></drv-button>

    </div>
  </div>

  <!-- <div drv-row class="drv-mt-sm--00">
    <div drv-col [sm]="4" [md]="{ span: 12, offset: 0 }">

      <pr-cell-nps-form></pr-cell-nps-form>

    </div>
  </div> -->

</div>
