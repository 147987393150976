<div class="page page-labor drv-mt-sm--01">

  <!-- BACK-LINK -->
  <pr-atom-page-backlink
    [route]="[i18n.locale, 'start']"
  >
    Zurück zur Startseite
  </pr-atom-page-backlink>

  <!-- TITLE dynamic -->
  <pr-atom-page-title>
    {{ laborTitle }}
  </pr-atom-page-title>

  <!-- CLUSTER_MAP -->
  <div class="drv-row drv-mt-sm--04 drv-mb-sm--04">
    <div class="drv-col" style="background-color: white;">
      
      <h2 style="margin-bottom: 1rem;"><span style="color: #888">Cluster Map</span></h2>
      <h2 style="margin-bottom: 1rem;"> {{ mapTreffer }} Treffer</h2>
      
      <pr-cell-cluster-map
        [results]="mapClusterMarker"
        (events)="mapEventHandler($event)"
      ></pr-cell-cluster-map>
      
      <div class="fx-row" style="background-color: #eee; margin: 1rem">
        <button (click)="mapReset()">clearData</button>
        <button (click)="mapLoadMarker()">loadMarker</button>
        <button (click)="mapLoadClusterMarker()">loadClusterMarker</button>
        <button (click)="mapBoundingRect()">boundingRect</button>
        <button (click)="mapToggleTable()">toggleTable</button>
        <button (click)="mapToggleHome()">toggleHome</button>
        <button (click)="mapShowTestMarker()">TestMarker</button>
      </div>
      <div class="fx-row monospace">
        <span> INFO: {{ mapInfo }}</span>
      </div>
      <div class="fx-row monospace">
        <span> CLICK: {{ markerInfo }}</span>
      </div>
      
      
      
    </div>
  </div>

  <!-- QUALITÄT-STANDORT -->
  <!-- <div drv-row id="qualitaet">
    <div drv-col [sm]="4" [md]="{ span: 8, offset: 0 }" [lg]="{ span: 6, offset: 0 }">

      <h2 class="drv-mb-sm--01 drv-mt-sm--04" i18n="@@page.detail.header.qualitaet">
        page.detail.header.qualitaet
      </h2>

      <pr-cell-quality-details
        style="background-color: #e1e6ec; padding: 1.5rem"
        [quality]="labor_quality()"
      ></pr-cell-quality-details>

    </div>
    <div drv-col [sm]="4" [md]="{ span: 4, offset: 0 }"  [lg]="{ span: 6, offset: 0 }">

      <h2 class="drv-mb-sm--01 drv-mt-sm--04" i18n="@@page.detail.header.standort">
        page.detail.header.standort
      </h2>

      <div>MAP</div>

    </div>
  </div> -->
  

  <div class="drv-row drv-mt-sm--04 drv-mb-sm--04">
    <div class="drv-col">
      
      <h2>Qualitäts Kreis Size 100 Stroke 10</h2>
      <div class="fx-row">
        <pr-atom-quality-circle
          title="title light 446688"
          [quality]="100" [lightVersion]="true" [color]="'#446688'" [size]="100" [stroke]="10"
        ></pr-atom-quality-circle>
        <pr-atom-quality-circle
          title="title light fff"
          [quality]="100" [lightVersion]="true" [color]="'#FFF'" [size]="100" [stroke]="10"
        ></pr-atom-quality-circle>
        <pr-atom-quality-circle
          title="title red"
          [quality]="100" [lightVersion]="false" [color]="'red'" [size]="100" [stroke]="10"
        ></pr-atom-quality-circle>
        <pr-atom-quality-circle
          title="title 446688"
          [quality]="99" [lightVersion]="false" [color]="'#446688'" [size]="100" [stroke]="10"
        ></pr-atom-quality-circle>
        <pr-atom-quality-circle
          title="title light fff"
          [quality]="99" [lightVersion]="true" [color]="'#FFF'" [size]="100" [stroke]="10"
        ></pr-atom-quality-circle>
        <pr-atom-quality-circle
          title="title light red"
          [quality]="99" [lightVersion]="true" [color]="'red'" [size]="100" [stroke]="10"
        ></pr-atom-quality-circle>
      </div>

      <h2>Qualitäts Kreis Size 64</h2>
      <div class="fx-row">
        <pr-atom-quality-circle
          title="title light 446688"
          [quality]="100" [lightVersion]="true" [color]="'#446688'" [size]="64"
        ></pr-atom-quality-circle>
        <pr-atom-quality-circle
          title="title light fff"
          [quality]="100" [lightVersion]="true" [color]="'#FFF'" [size]="64"
        ></pr-atom-quality-circle>
        <pr-atom-quality-circle
          title="title red"
          [quality]="100" [lightVersion]="false" [color]="'red'" [size]="64"
        ></pr-atom-quality-circle>
        <pr-atom-quality-circle
          title="title 446688"
          [quality]="99" [lightVersion]="false" [color]="'#446688'" [size]="64"
        ></pr-atom-quality-circle>
        <pr-atom-quality-circle
          title="title light fff"
          [quality]="99" [lightVersion]="true" [color]="'#FFF'" [size]="64"
        ></pr-atom-quality-circle>
        <pr-atom-quality-circle
          title="title light red"
          [quality]="99" [lightVersion]="true" [color]="'red'" [size]="64"
        ></pr-atom-quality-circle>
      </div>

      <h2>Size 40</h2>
      <div class="fx-row">
        <pr-atom-quality-circle
          title="title light 446688"
          [quality]="100" [lightVersion]="true" [color]="'#446688'" [size]="40"
        ></pr-atom-quality-circle>
        <pr-atom-quality-circle
          title="title light fff"
          [quality]="100" [lightVersion]="true" [color]="'#FFF'" [size]="40"
        ></pr-atom-quality-circle>
        <pr-atom-quality-circle
          title="title red"
          [quality]="100" [lightVersion]="false" [color]="'red'" [size]="40"
        ></pr-atom-quality-circle>
        <pr-atom-quality-circle
          title="title 446688"
          [quality]="99" [lightVersion]="false" [color]="'#446688'" [size]="40"
        ></pr-atom-quality-circle>
        <pr-atom-quality-circle
          title="title light fff"
          [quality]="99" [lightVersion]="true" [color]="'#FFF'" [size]="40"
        ></pr-atom-quality-circle>
        <pr-atom-quality-circle
          title="title light red"
          [quality]="99" [lightVersion]="true" [color]="'red'" [size]="40"
        ></pr-atom-quality-circle>
      </div>

    </div>
  </div>

  <div class="drv-row drv-mt-sm--04 drv-mb-sm--04">
    <div class="drv-col">
      <h2> drv-collapsible </h2>
      <drv-collapsible
        style="width: 100%;"
        [buttonText]="''"
        (click)="onCollapsibleClick($event)"
      >
        HUHUH
      </drv-collapsible>
    </div>
  </div>

  <div class="drv-row drv-mt-sm--04 drv-mb-sm--04">
    <div class="drv-col">

      <h2> drv-accordion </h2>

      <drv-accordion>

        <drv-accordionitem
          title="Accordion Nummer eins"
          [isOpen]="false"
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius omnis repellat ex, eligendi nesciunt, illo architecto ut est, dolor laudantium quibusdam delectus eveniet? Explicabo ullam et qui aliquid. Vero, quis?
        </drv-accordionitem>

        <drv-accordionitem
          title="Accordion Nummer zwei, der etwas länger läuft Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda soluta dolorum omnis aliquam quis."
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius omnis repellat ex, eligendi nesciunt, illo architecto ut est, dolor laudantium quibusdam delectus eveniet? Explicabo ullam et qui aliquid. Vero, quis?
        </drv-accordionitem>

        <drv-accordionitem
          title="Accordion Verschachtelt"
          [isOpen]="true"
        >
          Jetzt kommen zwei weitere Accodions: Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius omnis repellat ex, eligendi nesciunt, illo architecto ut est, dolor laudantium quibusdam delectus eveniet? Explicabo ullam et qui aliquid. Vero, quis?

          <drv-accordion>

            <drv-accordionitem
              title="Inner Accordion Nummer eins"
              [isOpen]="false"
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius omnis repellat ex, eligendi nesciunt, illo architecto ut est, dolor laudantium quibusdam delectus eveniet? Explicabo ullam et qui aliquid. Vero, quis?
            </drv-accordionitem>

            <drv-accordionitem
              [isOpen]="false"
              title="Inner Accordion Nummer zwei"
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius omnis repellat ex, eligendi nesciunt, illo architecto ut est, dolor laudantium quibusdam delectus eveniet? Explicabo ullam et qui aliquid. Vero, quis?
            </drv-accordionitem>

          </drv-accordion>


        </drv-accordionitem>

      </drv-accordion>

    </div>
  </div>

  <div class="drv-row">
    <div class="drv-col">
      <h2>Error Handling</h2>
      <div class="drv-mt-sm--03">
        <button style="width: 25%; display: inline; background-color: #DDD;" (click)="triggerError('error')">TriggerError</button>
        <button style="width: 25%; display: inline; background-color: #DDD;" (click)="triggerError('apierror')">TriggerAPIError</button>
        <button style="width: 25%; display: inline; background-color: #DDD;" (click)="showError('')">Clear</button>
      </div>
      <div class="drv-mt-sm--03">
        <h3>JSON Feedback</h3>
        <pre style="font-family: monospace; font-size: 14px; font-weight: 800; background-color: #e3f2ff;"
          >{{ response | json }}
        </pre>
      </div>
      <div class="drv-mt-sm--03">
        <button style="width: 25%; display: inline; background-color: #DDD;" (click)="showError('error')">ShowError</button>
        <button style="width: 25%; display: inline; background-color: #DDD;" (click)="showError('warning')">ShowInfo</button>
        <button style="width: 25%; display: inline; background-color: #DDD;" (click)="showError('')">Clear</button>
      </div>
      <div class="drv-mt-sm--03">
        <button style="width: 25%; display: inline; background-color: #DDD;" (click)="changeCounter(+1)">+1</button>
        <button style="width: 25%; display: inline; background-color: #DDD;" (click)="changeCounter(-1)">-1</button>
      </div>
      <h2>
        <br />
        TestComponent: {{ laborTitle }}<br />
        TestTemplate: <span i18n="@@app.labor.label">Default Label</span><br />
        <!-- TestICU: <span i18n="@@app.test.icu">ICU</span><br /> -->
        TestDate: {{ now | date:'full' }} <br />
        TestNumber: {{ 123_456_789.87 | number }} <br />
        TestDollars: {{ 987_654_321.12 | currency }} <br /><br />
        TestCounter: <span i18n="@@app.counter"></span> {{ counter }} <br />
        <ng-container [ngPlural]="counter">
          <ng-template ngPluralCase="=0">
            <span i18n="@@app.labor.plural.zero">XX</span>
          </ng-template>
          <ng-template ngPluralCase="=1">
            <span i18n="@@app.labor.plural.one">YY</span>
          </ng-template>
          <ng-template ngPluralCase="other">
            <span i18n="@@app.labor.plural.other">ZZ</span>
          </ng-template>
        </ng-container>
      </h2>
    </div>
  </div>

</div>
