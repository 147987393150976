import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Data, Params } from '@angular/router';
import { Location } from '@angular/common'
import { combineLatest, Subscription } from 'rxjs';

import { I18n } from '@pr/core/i18n.module';
import { ApiService } from '@pr/core/api.service';
import { MetaService } from '@pr/core/meta.service';
import { ConfigService } from '@pr/core/config.service';
import { StateService } from '@pr/core/state.service';

const DEBUG = false;

@Component({
  selector: 'pr-page-zumantrag',
  templateUrl: './zumantrag.page.html',
  styleUrls: ['./zumantrag.page.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ZumantragPage implements OnDestroy {

  public trans = {
    'app_title':              $localize`:@@app.title:`,
    'title':                  $localize`:@@app.page.zumantrag.title:`,
    'back_start_page':        $localize`:@@app.backlink.start.label:`,
    'back_ergebnis_page':     $localize`:@@app.backlink.ergebnis.label:`,
    'back_detail_page':       $localize`:@@app.backlink.detail.label:`,
    'href_antrag':            $localize`:@@page.zumantrag.antrag.link.href:`,
    'button_text':            $localize`:@@page.zumantrag.button.label:`,
  };

  public info         = 'default';
  public pageback     = 'start';
  public pagebacktext = this.trans.back_start_page;
  public markdown     = '';

  private subscriber$: Subscription | undefined;

  constructor (
    public readonly i18n:      I18n,
    public readonly config:    ConfigService,
    public readonly state:     StateService,
    public readonly location:  Location,
    private readonly meta:     MetaService,
    private readonly route:    ActivatedRoute,
    private readonly api:      ApiService,
    private readonly cd:       ChangeDetectorRef,
  ) {

    this.meta.addTags('zumantrag');

    this.subscriber$ = combineLatest([
      this.route.data,
      this.route.queryParams,
    ]).subscribe( (result: [Data, Params]) => {

      const [data, params] = result;

      const root   = this.config.environment.markdownroot;
      const locale = this.i18n.locale;
      const url    = `${root}/${locale}/${data['markdown'] as string}`;

      this.api.markdown(url).subscribe( markdown => {
        this.markdown = markdown;
        this.cd.detectChanges();
      });

      this.pageback = params['back'] ?? ['start'];

      this.pagebacktext = (
        this.pageback === 'detail'   ? this.trans.back_detail_page :
        this.pageback === 'ergebnis' ? this.trans.back_ergebnis_page :
          this.trans.back_start_page
      );

    });

  }

  public ngOnDestroy (): void {
    this.subscriber$?.unsubscribe();
  }

}
