import { Component, Input } from '@angular/core';

import { I18n } from '@pr/core/i18n.module';
import { IPRMarker } from '@pr/core/interfaces';
import { ConfigService } from '@pr/core/config.service';

@Component({
  selector: 'pr-cell-map-popup',
  styles: [`
      div.map-popup {
        min-width: 300px;
        max-height: 400px;
        margin-top: 1.1rem;
        margin-right: -1.2rem;
        overflow-y: auto;
        a {
          color: #083163;
          font-size: 130%;
          font-weight: 600;
          text-decoration: none;
        }
        .spacer {
          margin-top: 0.25rem;
        }
      }
      div.einrichtung {
        padding: 0.5rem 0;
        border-bottom: 1px solid #789789;
        &:last-child {
          padding-bottom: 0;
          border-bottom: none;
        }
      }
  `],
  template: `
    <div class="map-popup" style="min-width: 300px">
      @for (item of markers; track item) {
        <div class="einrichtung">
          <a [routerLink]="item.data.detailRoute">
            {{ item?.data.einrichtungname }}
          </a>
          <div class="fs-110 spacer">
            <div class="bold">
              {{ item?.data.strasse }}&nbsp;
              {{ item?.data.hausnummer }}, {{ item?.data.plz }}&nbsp;
              {{ item?.data.ort }}
            </div>
            <div class="spacer">
              {{ item?.data.name_ergebnis }}&nbsp;・&nbsp;{{ item?.data.spec_ergebnis }}
            </div>
            <div>
              {{ i18n.translate(item?.data.durchfuehrung ?? '') }}@if (item?.data.isAhb) {
              <span>,&nbsp;{{ trans.ahb }}</span>
            }
          </div>
          <div class="nowrap spacer">
            <pr-atom-wartezeit class="no-circle" [wzinfo]="[item.data.wartezeit, 'long']"></pr-atom-wartezeit>
          </div>
          <div class="nowrap">
            {{ prepQuality(item.data.qualitaet) }}
          </div>
        </div>
      </div>
    }
    </div>
    `,
})
export class ClusterMapPopupCell {

  public trans = {
    ahb:                $localize`:@@page.detail.reha.label:`,
    qualitaet_nodata:   $localize`:@@page.ergebnis.table.qualitaet.nodata:`,
    title_quality_vg:   $localize`:@@page.ergebnis.circle.title.vergleichsgruppe:`,
    title_quality_fa:   $localize`:@@page.ergebnis.circle.title.fachabteilung:`,
    days:               $localize`:@@page.ergebnis.wartezeit.dimension.days:`,
    blocked:            $localize`:@@app.wartezeit.blocked:`,
    noinfo:             $localize`:@@app.wartezeit.noinfo:`,
    wartezeit:          $localize`:@@page.ergebnis.map.wartezeit:`,
    durchfuehrung:      $localize`:@@page.ergebnis.map.durchfuehrung:`,
    quality:            $localize`:@@page.ergebnis.map.quality:`,
    points:             $localize`:@@page.ergebnis.map.points:`,
  }
  @Input() markers: IPRMarker[] | null = null;
  
  constructor ( 
    public i18n: I18n,
    public config: ConfigService,
  ) { }
  
  public prepWartezeit (wz: number): string {
    return this.trans.wartezeit + ': ' + (
      wz === 8888 ? this.trans.noinfo  :
      wz === 9999 ? this.trans.blocked :
      String(wz) + ' ' + this.trans.days
    );
  }
  
  public prepQuality (quality: number): string {
    return this.trans.quality + ': ' + (
      quality !== this.config.no_quality 
        ? quality + ' ' + this.trans.points 
        : this.trans.qualitaet_nodata 
    );
  }

}
