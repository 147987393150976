import { Inject, Injectable } from '@angular/core';
import { Router, ActivationStart } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import * as L from 'leaflet';

import { IconType } from '@drv-ds/drv-design-system-ng/lib/types/icons';
import { DrvDropdownOption } from '@drv-ds/drv-design-system-ng';

import { IEnvironment } from 'src/environments/environment.interface';
import { environment } from 'src/environments/environment';
import { APP_CONFIG } from './injection-tokens';
import { IState } from './state.service';
import { ISortprops, TTableSortIndex } from './interfaces';

export type TLocale = 'de' | 'en' | 'de-leicht' | 'xx'
export type TAPI = Record<'LOCAL' | 'DEV' | 'YSIT' | 'TEST' | 'PUBLIC' | 'PROD', { host: string, url: string }>

type TNps = {
  enabled: boolean
  routefragments: string[],
  timeout_secs: number,
  blocking_days_prod: number,
  blocking_days_test: number
}

/**
 * principal config interface of Public Reporting Frontend
 * */
export interface IConfig {
  readonly defaultLocale: TLocale
  readonly availableLocale: string[]
  readonly mapperLocale: Record<TLocale, TLocale>
  readonly storage: 'localStorage' | 'sessionStorage'
  readonly pages: string[]
  readonly apis: TAPI
  readonly endpoints: Record<string, string>
  readonly defaultPage: string
  readonly errorPage: string
  readonly no_quality: number
  readonly nps_timespan: number
  readonly nps_max_char: number
  readonly features: Record<string, boolean>
  readonly ids_fagrp_show_fan: [number]
  readonly id_fagrp_all: number
  readonly wartezeiten: [number, number]
  readonly max_ergebnis_length: number
  readonly markdown: {
    root: string
  }
  readonly testDomains: string[]
  readonly domain_url: string
  readonly defaultState: IState
  readonly pageSizes: DrvDropdownOption[]
  readonly cluster: {
    padding: number
  }
  readonly search_abbrevations: Array<[string, string]>
  readonly search_min_length: number
  readonly nps: TNps
}

export interface IMapConfig {
  readonly zoomDetail: number
  readonly zoomDefault: number
  readonly iconGreen: L.Icon
  readonly iconBlue: L.Icon
  readonly iconRed: L.Icon
  readonly iconCluster: L.Icon
  readonly centerDefault: L.LatLng
  readonly layerDefault: L.TileLayer
  readonly layerOEPNV: L.TileLayer
  readonly layerRailway: L.TileLayer
}

interface IFeatures extends Record<string, boolean> { }

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private readonly config: IConfig;
  private _features: Record<string, boolean>;

  private apiSelected: { host: string, url: string } = { host: 'unknown', url: 'unknown' };

  public features$ = new BehaviorSubject<IFeatures | null>(null);


  constructor (
    @Inject(APP_CONFIG) config: IConfig,
    private readonly router: Router,
  ) {

    this.config = config;

    // Log test domains
    if (config.testDomains.includes(location.hostname)) {
      console.log('%cConfig', 'font-weight: 800; color: darkorange', { config }, location.hostname, 'detected');

    } else {
      console.log('%cConfig', 'font-weight: 800; color: darkorange', { config });

    }

    // Log API Endpoint
    let apiFound = false;
    for (const [name, apicfg] of Object.entries(config.apis)) {
      if (apicfg.host === location.hostname) {
        this.apiSelected = apicfg;
        apiFound = true;
        console.log('%cAPI', 'font-weight: 800; color: darkorange', name, apicfg);
      }
    }
    !apiFound && console.error("NO API Found")

    // process optinal features, currently feedback page
    this._features = Object.assign({}, this.config.features);
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart) {

        // router data can disable features, but not enable!
        if ('features' in event.snapshot.data) {

          Object.entries(event.snapshot.data['features'] as IFeatures).forEach(([key, value]) => {
            if (!value) {
              this._features[key] = false;
            }
          });

          // restore features from config
        } else {
          this._features = Object.assign({}, this.config.features);

        }
        // propagate altered features
        this.features$.next(this._features);

      }
    });

  }

  // public doShowFascName(id: unknown): boolean {
  //   return (
  //     typeof id === 'number' ? this.config.ids_fagrp_show_fan.includes(id) :
  //     typeof id === 'string' ? this.config.ids_fagrp_show_fan.includes(Number(id)) :
  //         false
  //   );
  // }

  // Convenience ShortCuts
  get all(): IConfig { return this.config }
  get api(): { host: string, url: string } { return this.apiSelected; }
  get availableLocale(): string[] { return this.config.availableLocale; }
  get cluster() { return this.config.cluster; }
  get defaultLocale(): string { return this.config.defaultLocale; }
  get defaultPage(): string { return this.config.defaultPage; }
  get defaultState(): IState { return this.config.defaultState; }
  get endpoints(): Record<string, string> { return this.config.endpoints; }
  get environment(): IEnvironment { return environment }
  get errorPage(): string { return this.config.errorPage; }
  get features(): IFeatures { return this.config.features; }
  get id_fagrp_all(): number { return this.config.id_fagrp_all; }
  get isTestDomain(): boolean { return this.config.testDomains.includes(location.hostname) }
  get mapperLocale(): Record<TLocale, TLocale> { return this.config.mapperLocale; }
  get max_ergebnis_length(): number { return this.config.max_ergebnis_length; }
  get no_quality(): number { return this.config.no_quality; }
  get pages(): string[] { return this.config.pages; }
  get pageSizes() { return this.config.pageSizes; }
  get search_abbrevations() { return this.config.search_abbrevations; }
  get search_min_length() { return this.config.search_min_length; }
  get storage() { return this.config.storage; }
  get wartezeiten(): [number, number] { return this.config.wartezeiten; }
  get nps_timespan(): number { return this.config.nps_timespan; }
  get nps_max_char(): number { return this.config.nps_max_char; }
  get nps(): TNps { return this.config.nps; }

  get pictogramMapper(): Record<string, IconType> {
    // https://drv-design-system.eu-de.mybluemix.net/?path=/docs/tokens-icons--angular
    return {
      'Ausstattung': 'buildings',
      'Begleitung': 'family', // family
      'Zusätzliche Sprachen': 'contact-options',
      'Barrierefreiheit': 'user',
      'Sonstiges': 'sun',
      'Sonstige Filter': 'sun',
      'Sonstige': 'sun',
      'Sprache': 'contact-options',
    }
  }

  get mapConfig(): IMapConfig {

    const base = this.environment.basehref;

    return {
      zoomDefault: 3, //5,
      zoomDetail: 11,
      centerDefault: L.latLng(52.3, 13.5),
      layerDefault: L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18, detectRetina: false,
        attribution: `
          Map data: &copy; <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | Map style: &copy;
          <a target="_blank" href="https://www.OpenRailwayMap.org">OpenRailwayMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)
        `
      }),
      layerOEPNV: L.tileLayer('https://tileserver.memomaps.de/tilegen/{z}/{x}/{y}.png', {
        maxZoom: 18, detectRetina: false,
        attribution: `
          Map data: &copy; <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | Map style: &copy;
          <a target="_blank" href="https://www.OpenRailwayMap.org">OpenRailwayMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)
        `
      }),
      layerRailway: L.tileLayer('https://{s}.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png', {
        maxZoom: 18, detectRetina: false,
        attribution: `
          Map data: &copy; <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | Map style: &copy;
          <a target="_blank" href="https://www.OpenRailwayMap.org">OpenRailwayMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>
        `
      }),
      iconGreen: L.icon({
        ...L.Icon.Default.prototype.options,
        iconUrl: `${base}assets/images/markers/marker-icon-green.png`,
        iconRetinaUrl: `${base}assets/images/markers/marker-icon-2x-darkblue.png`,
        shadowUrl: `${base}assets/images/markers/marker-shadow.png`
      }),
      iconRed: L.icon({
        ...L.Icon.Default.prototype.options,
        ... { shadowSize: [0,0] },
        iconUrl: `${base}assets/images/markers/marker-icon-red.png`,
        iconRetinaUrl: `${base}assets/images/markers/marker-icon-2x-red.png`,
        shadowUrl: `${base}assets/images/markers/marker-shadow.png`
      }),
      iconBlue: L.icon({
        ...L.Icon.Default.prototype.options,
        iconUrl: `${base}assets/images/markers/marker-icon-darkblue.png`,
        iconRetinaUrl: `${base}assets/images/markers/marker-icon-2x-darkblue.png`,
        shadowUrl: `${base}assets/images/markers/marker-shadow.png`
      }),
      iconCluster: L.icon({
        ...L.Icon.Default.prototype.options,
        // iconSize: [25, 41],
        iconSize: [31, 41],
        iconUrl: `${base}assets/images/markers/marker-cluster-basic.png`,
        iconRetinaUrl: `${base}assets/images/markers/marker-cluster-basic.png`,
        shadowUrl: `${base}assets/images/markers/marker-shadow.png`
      })
    }

  }

  get sortOptions(): Record<TTableSortIndex, ISortprops> {

    const trans = {
      label_einrichtung:    $localize`:@@page.ergebnis.tablehead.einrichtung:`,
      label_plz:            $localize`:@@page.ergebnis.tablehead.plzort:`,
      label_durchfuehrung:  $localize`:@@page.ergebnis.tablehead.durchfuerung:`,
      label_reha:           $localize`:@@page.ergebnis.tablehead.rehaanschluss:`,
      label_quali:          $localize`:@@page.ergebnis.tablehead.qualitaet:`,
      label_wait:           $localize`:@@page.ergebnis.tablehead.wartezeit:`,
      label_distance:       $localize`:@@page.ergebnis.tablehead.distance:`,
    };

    // maps DS Table sort to API sort
    return {
      0: { type: 'string',    order: 'asc', label: trans.label_einrichtung,   prop: 'einrichtungname' },
      1: { type: 'string',    order: 'asc', label: trans.label_plz,           prop: 'plz' },
      2: { type: 'string',    order: 'asc', label: trans.label_durchfuehrung, prop: 'durchfuehrung' },
      3: { type: 'number',    order: 'asc', label: trans.label_quali,         prop: 'qualitaet' },
      4: { type: 'wartezeit', order: 'asc', label: trans.label_wait,          prop: 'wartezeit' },
    } as const;

  }

}
