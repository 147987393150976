<div class="page page-notfound drv-mt-sm--01">

  <pr-cell-header-bar>
    <drv-button
      class="back"
      style="margin-left: -1rem"
      [label]="trans.back_start_page"
      kind="tertiary"
      type="anchor"
      iconPosition="left"
      iconType="arrow-left"
      backgroundUI="light"
      [route]="['/', 'de']"
    ></drv-button>
  </pr-cell-header-bar>

  <!-- CLAIM -->
  <pr-atom-claim></pr-atom-claim>

  <!-- TITLE -->
  <pr-atom-page-title  i18n="@@page.notfound.title.long">
    page.notfound.title.long
  </pr-atom-page-title>

  <!-- MARKDOWN -->
  <div drv-row>
    <div drv-col [sm]="4" [md]="{ span: 9, offset: 0 }">
      @if (markdown) {
        <cell-markdown
          [rawmarkdown]="markdown"
        ></cell-markdown>
      }
    </div>
  </div>

</div>
