import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { I18n } from '@pr/core/i18n.module';

/** Page is shown, when API errors on tokens,
 *  so no localize.
 */

@Component({
  selector: 'pr-page-notavailable',
  templateUrl: './notavailable.page.html',
  styleUrls: ['./notavailable.page.scss']
})
export class NotavailablePage implements OnInit {

  constructor (
    readonly title: Title,
    public readonly i18n: I18n,
  ) { }

  ngOnInit(): void {
    this.title.setTitle('DRV - Reha, Fehler');
  }

}
