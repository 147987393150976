import { ChangeDetectorRef, Component, Inject, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { delay, Observable, of } from 'rxjs';
import { FrozenProcessor } from 'unified';

import { I18n } from '@pr/core/i18n.module';
import { INode } from '@pr/core/interfaces';
import { ApiService } from '@pr/core/api.service';
import { ConfigService } from '@pr/core/config.service';
import { MarkdownService } from '@pr/core/markdown.service';
import { TemplateIdDirective } from '@pr/core/directives/template-id.directive';

// https://www.c-sharpcorner.com/article/dynamically-loading-the-ng-template-from-its-name-in-angular-9/

const DEBUG = false;

@Component({
  selector: 'pr-cell-tooltips',
  template: `
    @for (child of procmast?.children; track child) {
      <ng-template [template-id]="child.identifier || ''" >
        <div class="markdown" [nodes]="child"></div>
      </ng-template>
    }`,
  styles: [],
})
export class TooltipsCell {

  public procmast: INode | undefined;
  private loaded: boolean = false;

  @ViewChildren(TemplateIdDirective) templateRefs: QueryList<TemplateIdDirective> | null = null;

  constructor (
    @Inject('unified') private unified: FrozenProcessor,
    public readonly i18n:     I18n,
    public readonly config:   ConfigService,
    private readonly api:     ApiService,
    private readonly service: MarkdownService,
    private readonly cd:      ChangeDetectorRef,
  ) {

    const root = this.config.environment.markdownroot;
    const url  = `${root}/${this.i18n.locale}/${'tooltips.md'}`;

    this.api.markdown(url)
      .subscribe( markdown => {
        const mast    = this.unified.parse(markdown) as INode;
        this.procmast = this.service.createProcMast(mast);
        this.loaded   = true;
        this.cd.detectChanges();
      })
    ;

  }

  get (templateName: string): Observable<TemplateRef<any> | undefined> {

    const tpl = this.loaded
      ? of(this.templateRefs?.toArray().find(x => x.name === templateName)?.template)
      : of(undefined).pipe(delay(500))
    ;

    DEBUG && console.log('TooltipsCell.get', templateName, (this.templateRefs?.toArray() ?? []).length);

    return tpl;

  }

}
