@if ( quality?.isGkv ) {
  
  <div>
    
    <div class="fxrow" style="margin-bottom: 1rem; align-items: center;">
      <div class="tl bold" [innerHTML]="trans.gkv_header"></div>
      <div class="fxgrow"></div>
      <pr-atom-quality-circle
        style="flex-basis: 120px; margin-right: -10px"
        [quality]="quality?.quality_mw"
        [size]="100"
        [stroke]="10"
        [title]="'Qualität in Prozent'"
        [color]="'#FFF'"
        [lightVersion]="true"
      ></pr-atom-quality-circle>
    </div>
    <div>
      <p style="margin-bottom: 0.5rem;">{{ trans.gkv_paragraph_1 }}</p>
      <p style="margin-bottom: 0.5rem;">{{ trans.gkv_paragraph_2 }}</p>
      <p style="margin-bottom: 0.5rem;">{{ trans.gkv_paragraph_3 }}</p>
    </div>
    
    <!-- INFOLINK 1 -->
    <div class="tl" style="margin-top: 1rem">
      <drv-iconlink
        icon="arrow-right"
        [route]="['/', 'de', 'hilfe']"
        [routeExtras]="{ fragment: trans.gkv_link1_fragment }"
        [title]="trans.gkv_link1_label"
      >{{ trans.gkv_link1_label }}</drv-iconlink>
    </div>
    
    <!-- INFOLINK 2 -->
    <div class="tl" style="margin-top: 0.5rem">
      <drv-iconlink
        icon="arrow-external"
        [href]="trans.gkv_link2_url"
        target="_blank"
        [title]="trans.gkv_link2_label"
      >{{ trans.gkv_link2_label }}</drv-iconlink>
    </div>
  </div>
  
} @else {
  
  <div>
    
    <div *pr-show="['ts', 'sm']" class="fxrow" style="margin-bottom: 1rem">
      <div class="tl bold" style="max-width: 49%" [innerHTML]="trans.mob_fachabteilung"></div>
      <div class="fxgrow"></div>
      <div class="tr bold" style="max-width: 49%" [innerHTML]="trans.mob_durchschnitt"></div>
    </div>
    <div *pr-hide="['ts', 'sm']" class="fxrow" style="margin-bottom: 1rem">
      <div class="tl bold" style="max-width: 49%" [innerHTML]="trans.dsk_fachabteilung"></div>
      <div class="fxgrow"></div>
      <div class="tr bold" style="max-width: 49%" [innerHTML]="trans.dsk_durchschnitt"></div>
    </div>
    
    <div class="fxrow" style="margin-bottom: 1rem">
      <pr-atom-quality-circle
        style="max-width: 40%; margin-left: -10px"
        [quality]="quality?.quality"
        [size]="100"
        [stroke]="10"
        [title]="'Qualität in Prozent'"
      ></pr-atom-quality-circle>
      <div class="fxgrow"></div>
      <pr-atom-quality-circle
        style="max-width: 40%; margin-right: -10px"
        [quality]="quality?.quality_mw"
        [size]="100"
        [stroke]="10"
        [title]="'Qualität in Prozent'"
        [color]="'#FFF'"
        [lightVersion]="true"
      ></pr-atom-quality-circle>
    </div>
    
    <div *pr-show="['ts', 'sm']"
      [innerHTML]="trans.mob_combined"
      style="max-width: 70%; min-width: 300px; margin-bottom: 1rem">
    </div>
    
    <!-- TABLE -->
    <div
      class="quality-table"
      role="table"
      aria-label="Semantic Elements"
      aria-describedby="semantic_elements_table_desc"
     [attr.aria-rowcount]="quality?.bewertung?.length"
    >
      <div *pr-show="['ts', 'sm']" role="rowgroup" class="mobile">
        <div role="row" class="fxrow" style="align-items:end">
          <div role="columnheader" aria-sort="none"></div>
          <div role="columnheader" aria-sort="none" [innerHTML]="trans.mob_header_left"></div>
          <div role="columnheader" aria-sort="none"></div>
          <div role="columnheader" aria-sort="none" [innerHTML]="trans.mob_header_right"></div>
        </div>
      </div>
      <div *pr-hide="['ts', 'sm']" role="rowgroup" class="desktop">
        <div role="row" class="fxrow" style="align-items:end">
          <div role="columnheader" aria-sort="none"></div>
          <div role="columnheader" aria-sort="none" [innerHTML]="trans.dsk_header_left"></div>
          <div role="columnheader" aria-sort="none" [innerHTML]="trans.dsk_combined"></div>
          <div role="columnheader" aria-sort="none" [innerHTML]="trans.dsk_header_right"></div>
        </div>
      </div>
      
      <!-- QBOX Mobile -->
      <div *pr-show="['ts', 'sm']" class="mobile">
        <div role="rowgroup">
          @for (item of quality?.bewertung?.sort( sorterBewertung ); track item; let i = $index) {
            <div role="row" attr.aria-rowindex="{{ i +1 }}">
              <div class="fxrow w-100" style="min-width: 100%;">
                <div role="cell" aria-colindex="1" [ngStyle]="{ 'color': item.neutralerWert ? 'black' : 'transparent' }">⌀</div>
                <div role="cell" aria-colindex="2">{{ item.qs | number:'1.0-0' }}</div>
                <div class="fxgrow"></div>
                <div role="cell" aria-colindex="4">{{ item.mwQs | number:'1.0-0' }}</div>
              </div>
              <div role="cell" aria-colindex="3" class="fxrow w-100">
                <drv-details title="{{ i18n.translate(item.label) }}">
                  <p>{{ i18n.translate(item.info) }}</p>
                </drv-details>
              </div>
            </div>
          }
        </div>
      </div>
      
      <!-- QBOX Desktop -->
      <div *pr-hide="['ts', 'sm']" class="desktop">
        @for (item of quality?.bewertung?.sort( sorterBewertung ); track item; let i = $index) {
          <div role="rowgroup">
            <div role="row" class="w-100" attr.aria-rowindex="{{ i +1 }}">
              <div role="cell" [ngStyle]="{ 'color': item.neutralerWert ? 'black' : 'transparent'}">⌀</div>
              <div role="cell">{{ item.qs | number:'1.0-0' }}</div>
              <div role="cell">
                <drv-button
                  label="{{ i18n.translate(item.label) }}"
                  kind="tertiary"
                  type="anchor"
                  iconPosition="left"
                  backgroundUI="light"
                  [attr.aria-expanded]=""
                  [iconType]="this.toggles[i] ? 'arrow-breadcrumb': 'chevron-down'"
                  (onClick)="onToggle($event, i)"
                ></drv-button>
              </div>
              <div role="cell">{{ item.mwQs | number:'1.0-0' }}</div>
            </div>
            @if (this.toggles[i]) {
              <div role="region"
                style="width: 80%; margin: auto; padding-top: 0.5rem"
              >
                {{ i18n.translate(item.info) }}
              </div>
            }
          </div>
        }
      </div>
    </div>
    
    <!-- INFONEUTRALEWERTE -->
    <div class="tl" style="margin-top: 1rem">
      <span class="bold" i18n="@@page.detail.qualitaet.neutralewerte.info">page.detail.qualitaet.neutralewerte.info</span>
    </div>
    
    <!-- INFOLINK -->
    <div class="tl" style="margin-top: 1rem">
      <drv-iconlink
        icon="arrow-right"
        [route]="['/', 'de', 'qualitaet']"
        [title]="trans.link_quali_title"
      >{{ trans.link_quali_label }}</drv-iconlink>
    </div>
    
  </div>
}
