import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';

import { ConfigService } from '@pr/core/config.service';
import { I18n } from '@pr/core/i18n.module';
import { IErgFachabteilung } from '@pr/core/interfaces.api';

const DEBUG = false;

@Component({
  selector: 'pr-atom-desktop-table-row',
  templateUrl: './desktop-table-row.atom.html',
  styleUrls: ['./desktop-table-row.atom.scss']
})
export class DesktopTableRowAtom implements OnInit {
  
  public trans = {
    title_quality_fa:      $localize`:@@page.ergebnis.circle.title.fachabteilung:`,
    title_quality_vg:      $localize`:@@page.ergebnis.circle.title.vergleichsgruppe:`,
    reha_anschluss_false:  $localize`:@@page.ergebnis.table.ahb.no:`,
    reha_anschluss_true:   $localize`:@@page.ergebnis.table.ahb.yes:`,
    qualitaet_nodata:      $localize`:@@page.ergebnis.table.qualitaet.nodata:`,
  };

  @Input() fa: IErgFachabteilung | undefined = undefined;
  @Output() rowclick: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('template', { static: true }) template: any;
  
  constructor(
    public readonly i18n:     I18n,
    public readonly config:   ConfigService,
    private viewContainerRef: ViewContainerRef
  ) { }
  
  public onRowClick (id: number | undefined): void {
    DEBUG && console.log('TableRow.click', id);
    id && this.rowclick.emit(id);
  }
  
  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
  }
  
}
