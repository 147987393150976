<div class="page page-notfound drv-mt-sm--01">

    <!-- BACK-LINK -->
    <pr-atom-page-backlink
      [route]="['/', 'de']"
      [routeExtras]="{}"
    >
      Zurück zur Startseite
    </pr-atom-page-backlink>

    <!-- TITLE -->
    <pr-atom-page-title>
      Service zur Zeit nicht verfügbar
    </pr-atom-page-title>

    <div drv-row class="drv-mt-sm--02">
      <div drv-col [sm]="4" [md]="{ span: 10, offset: 1 }">
        <p>
          Diese Service ist zur Zeit nicht verfügbar.
        </p>
        <p>
          Sollten Sie von einer unserer Seiten aus auf diesen Fehler gestoßen sein, teilen Sie uns dies bitte per E-Mail mit:
        </p>
        <p>
          public-reporting&#64;drv-bund.de
        </p>
        <p>
          Vielen Dank für ihr Verständnis
        </p>
      </div>
    </div>

  </div>
