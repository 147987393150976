import { Component, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, HostListener, Inject, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Map } from 'leaflet';

import { IErgebnisResults, IFilterConfig, IFachgruppe, IActiveFilter, IAPIFascs, IActiveSort, TTableSortIndex, TTableSortOrder, IDrvComboSortOption, TTableSort, TTableSortArray, IPagination, TMapEvent, TBreakpoint } from '@pr/core/interfaces';
import { I18n } from '@pr/core/i18n.module';
import { AppService } from 'src/app/app.service';
import { MetaService } from '@pr/core/meta.service';
import { MatomoService } from '@pr/core/matomo.service';
import { IState, StateService } from '@pr/core/state.service';
import { TScrollInfo } from '@pr/core/directives/intersection-observer.directive';
import { FachgruppenService } from '@pr/core/fachgruppen.service';
import { ConfigService } from '@pr/core/config.service';
import { DrvDropdownOption, NotificationService } from '@drv-ds/drv-design-system-ng';
import { ClusterMapCell } from '@pr/cells/cluster-map/cluster-map.cell';
import { TableCell } from '@pr/cells/table/table.cell';
import { ErgebnisService } from './ergebnis.service';

const DEBUG = false;

@Component({
  selector: 'pr-page-ergebnis',
  templateUrl: './ergebnis.page.html',
  styleUrls: ['./ergebnis.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErgebnisPage implements OnDestroy {

  public DEBUG = DEBUG;

  public trans = {
    'app_title':                  $localize`:@@app.title:`,
    'btn_print_label':            $localize`:@@header.label.print:`,
    'btn_antrag_label':           $localize`:@@header.label.antrag:`,
    'back_start_page':            $localize`:@@app.backlink.start.label:`,
    'sort_by':                    $localize`:@@app.sort.by:`,
    'toggle_karte':               $localize`:@@page.ergebnis.toggle.karte:`,
    'toggle_liste':               $localize`:@@page.ergebnis.toggle.liste:`,
    'link_hilfe':                 $localize`:@@page.ergebnis.filters.link:`,
    'suche_header':               $localize`:@@page.ergebnis.header.kliniksuche:`,
    'suche_prefix':               $localize`:@@page.ergebnis.header.kliniksuche.prefix:`,
    'btn_float_label':            $localize`:@@page.ergebnis.tabelle.button.float.label:`,
    'sort_dropdown_placeholder':  $localize`:@@page.ergebnis.tabelle.sort.dropdown.placeholder:`,
    'sort_dropdown_label':        $localize`:@@page.ergebnis.tabelle.sort.dropdown.label:`,
    'please_choose': 'XXXX',
    'label_sort':                 'Sortierung', // trans missing
    'aria_map_toggle': 'XXXX',
    // "tile2_header":               $localize`:@@page.start.tile2.header:`,
    // "tile2_items":               [
    //                               $localize`:@@page.start.tile2.absatz1:`,
    //                               $localize`:@@page.start.tile2.absatz2:`
    //                               ]
  }

  // needed for floating button
  private breakpoint: TBreakpoint | null = null;
  private allowFloating  = false;
  private enableFloating = false;
  public isFloating      = false;
  private scrollPosition = 0;
  private onScroll: (e: Event) => void;
  
  public suchStr    = '';
  public dataReady  = false;
  public results:       IErgebnisResults  | undefined;
  public fascs:         IAPIFascs = {};
  public filterConfig:  IFilterConfig[] = [];
  public fachgruppe:    IFachgruppe | undefined;

  // user choices
  public activeSort:   IActiveSort   = ['', ''];
  public activeFilter: IActiveFilter = {
    plz:      '',
    radius:   undefined,
    merkmale: [],
  };
  
  public selected = {
    sort:        [],
  } as Record<'sort', DrvDropdownOption[] | []>;
  
  public pagination = {} as IPagination;

  public countActiveFilter = 0;

  private map: Map | undefined = undefined;
  private dataSubscriber$:  Subscription | undefined;
  private routeSubscriber$: Subscription | undefined;

  public comboSortOptions: IDrvComboSortOption[] = [
    { label: $localize`:@@page.ergebnis.mobile.name.auf:`,      value: '0,asc'  },
    { label: $localize`:@@page.ergebnis.mobile.name.ab:`,       value: '0,desc' },
    { label: $localize`:@@page.ergebnis.mobile.plz.auf:`,       value: '1,asc'  },
    { label: $localize`:@@page.ergebnis.mobile.plz.ab:`,        value: '1,desc' },
    { label: $localize`:@@page.ergebnis.mobile.qual.auf:`,      value: '3,asc'  },
    { label: $localize`:@@page.ergebnis.mobile.qual.ab:`,       value: '3,desc' },
    { label: $localize`:@@page.ergebnis.mobile.warte.auf:`,     value: '4,asc'  },
    { label: $localize`:@@page.ergebnis.mobile.warte.ab:`,      value: '4,desc' },
  ];

  @ViewChild('pr_cell_cluster_map') private cellClusterMap: ClusterMapCell | undefined = undefined;
  @ViewChild('pr_cell_table')       private cellTable: TableCell | undefined = undefined;
  @ViewChild('pr_cell_table', {read: ElementRef, static:false}) 
    private cellTableRef: ElementRef | undefined = undefined;

  @HostListener('window:resize', ['$event.target.innerHeight']) onResize() {
    this.checkFloatingButton();
  }
  
  constructor (
    public readonly service:       ErgebnisService,
    public readonly i18n:          I18n,
    public readonly app:           AppService,
    public readonly state:         StateService,
    private readonly route:        ActivatedRoute,
    private readonly router:       Router,
    private readonly cd:           ChangeDetectorRef,
    private readonly meta:         MetaService,
    private readonly matomo:       MatomoService,
    private readonly fachgruppen:  FachgruppenService,
    private readonly config:       ConfigService,
    private readonly notification: NotificationService,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {

    this.notification.clear();

    // prepare data placeholders
    this.fachgruppe = this.fachgruppen.dummy;

    this.routeSubscriber$ = this.route.params.subscribe( params => {
      this.dataSubscriber$?.unsubscribe();
      this.dataSubscriber$ = this.service
        .results$(params)
        .subscribe(this.update.bind(this))
      ;
    });

    // scroll and media events for floating 'to top' button
    this.onScroll = this.onContentScrolled.bind(this);
    this.document.addEventListener('scroll', this.onScroll);
    this.app.media$.subscribe(breakpoint => {
      this.breakpoint = breakpoint;
      this.checkFloatingButton();
    });

  }
  
  public ngOnDestroy (): void {
    this.routeSubscriber$?.unsubscribe();
    this.dataSubscriber$?.unsubscribe();
    this.document.removeEventListener('scroll', this.onScroll);
  }

  private update (results: IErgebnisResults): void {

    const t0 = Date.now();
    DEBUG && console.log('Ergebnis.update.in', results);

    this.results = results;
  
    this.filterConfig   = results.filterConfig;
    this.fachgruppe     = results.fachgruppe;
    this.activeFilter   = results.activeFilter;
    this.activeSort     = results.activeSort;
    this.fascs          = results.fascs;
    this.suchStr        = results.suchStr;

    // MATOMO && META
    const hitCount = results.fachabteilungen.length;
    if (this.suchStr) {
      this.matomo.trackSearch(this.suchStr, 'einrichtungssuche', hitCount);
      this.meta.addTags('ergebnis', 'einrichtungssuche');
    } else {
      this.matomo.trackSearch(this.fachgruppe?.slug ?? 'no indication given', 'indikationssuche', hitCount);
      this.meta.addTags('ergebnis', results.fachgruppe);
    }

    // count filter for mobile view
    this.countFilter(this.activeFilter);
    
    // prep sort dropdown model
    const value = this.state.get<TTableSort>('sort');
    const label = this.comboSortOptions.find( option => option.value === value )?.label ?? 'unknown';
    this.selected.sort = [{ label, value }];

    // signal UI we're ready
    setTimeout( () => {
      const t1 = Date.now();
      this.dataReady = true;
      // This takes some time w/ onPush
      this.cd.detectChanges();
      DEBUG && console.log('Ergebnis.detectChanges.out', Date.now() - t1, 'ms', Date.now() - t0, 'ms')
    }, 100);

    DEBUG && console.log('Ergebnis.update.out', Date.now() - t0, 'ms');

  }

  public onNavigate (what: 'print' | 'antrag'): void {

    if (what === 'print') {

      // MATOMO
      this.matomo.trackEvent('ergebnisse', 'print');

      // PRINT
      try {
        // mac OS special case
        if(!document.execCommand('print', false, undefined)) {
          window.print();
        }
      } catch {
        window.print();
      }

    } else if (what === 'antrag') {
      void this.router.navigate(['/', 'de', 'zumantrag', 'def']);

    }

  }

  // routes to details page on tablerow click in map or table
  // special cases GKV/AHB/WZG routes directly to ZumAntrag
  public onNavigateDetails (idFach: number): void {

    const fa = this.results?.fachabteilungen.find( item => item.idFach === idFach);

    if (fa) {

      if (fa.qualitaet === this.config.no_quality) {
        void this.router.navigate(['/', 'de', 'zumantrag', 'def']);

      } else {
        void this.router.navigate(fa.detailRoute);

      }

    }

  }

  // state update und ggf request
  private updateState (params: Partial<IState>, refetch=false) {
    this.dataReady = false;
    this.state.update(params);
    window.setTimeout( () => {
      refetch && this.service.updateErgebnisse$.next(true);
    }, 100);
  }

  // toggle view between map and table
  public onToggleView (index: number): void {
    
    // ignore superflous pointer events
    if ( index === 0 || index === 1 ) {
    
      const view = index === 0 ? 'liste' : 'karte';
      
      this.state.update({ view });
        
      if (view === 'karte') {
        setTimeout(() => {
          this.cellClusterMap?.onResize();
        }, 100);          
      }
        
      if (view === 'liste') {
        this.cellTable?.onSelectActivePage(1);
      }
      
    }
    
  }

  private countFilter (filter: IActiveFilter) {
    
    const defaultRadius    = this.config.defaultState.radius;
    const ignoreFilter     = this.config.defaultState.merkmale;
    const filterToCount    = filter.merkmale.filter( f => f !== ignoreFilter);
    
    this.countActiveFilter = (
      (filter.plz && filter.radius !== defaultRadius ? 1 : 0) +
      (filterToCount.length)
    );
    
    DEBUG && console.log('Ergebnis.countFilter', filter.plz, filter.radius, filter.merkmale, filterToCount, '=>', this.countActiveFilter);
    
  }

  // triggers URL update on filter changes
  public onUpdateFilter (filter: IActiveFilter): void {
    this.countFilter(filter);
    this.updateState({
      plz:      filter.plz             ? String(filter.plz)         : undefined,
      radius :  filter.radius          ? Number(filter.radius )     : undefined,
      merkmale: filter.merkmale.length ? filter.merkmale.join(',')  : undefined
    }, true);
  }

  public onSelectSort (param: DrvDropdownOption[]) {

    if (!param[0].value) { return; } 
    
    const sort = param[0].value;
    const [index, order] = sort.split(',') as [TTableSortIndex, TTableSortOrder];
    const stateSort = this.state.get<TTableSort>('sort');

    if (sort !== stateSort) {
      this.onUpdateSort([index, order]);
      DEBUG && console.log('onSelectSort', param, "=>", stateSort);
    }

  }
  
  public onUpdatePagination (pagination: IPagination) {
    this.pagination = pagination;
  }

  // Updates URL, UI, data with new sort
  public onUpdateSort ( sort: TTableSortArray ): void {

    const [index, order] = sort;
    const sortOption = this.config.sortOptions[index];

    DEBUG && console.log('Ergebnis.onUpdateSort', sort);

    // MATOMO
    this.matomo.trackEvent('ergebnisse', 'sort', sortOption.label + '.' + order);

    this.updateState({ 
      sort:       `${index},${order}`,
      activepage: '1',
    }, true);

  }
  
  public onMapEvent (mapEvent: TMapEvent) {
    
    function updateMapInfo (m: Map) {
      const ll   = m.getCenter();
      const zoom = m.getZoom();
      const info = `Zoom: ${zoom} Lat: ${ll.lat} Lng: ${ll.lng}`;
      DEBUG && console.log('mapEventHandler', info);
    }
    
    if (mapEvent.type === 'mapready') {
      // do nothing
      
    } else if (mapEvent.type === 'clusterready') {
      mapEvent.map && updateMapInfo(mapEvent.map);
      return;
      
    } else if (mapEvent.type === 'sort') {
      mapEvent.map && updateMapInfo(mapEvent.map);
      if (mapEvent?.payload?.sort) {
        this.onSelectSort(mapEvent.payload.sort as DrvDropdownOption[]);
      }
      
    } else if (mapEvent.type === 'moveend') {
      // false && console.log('Labor.mapEvents', mapEvent.payload.hits, 'hits');
      mapEvent.map && updateMapInfo(mapEvent.map);
      this.cd.detectChanges();
      
    } else if (mapEvent.type === 'zoomend') {
      // false && console.log('Labor.mapEvents', mapEvent.payload.hits, 'hits');
      mapEvent.map && updateMapInfo(mapEvent.map);
      this.cd.detectChanges();
      
    } else if (mapEvent.type === 'markerclick') {
      mapEvent.map && updateMapInfo(mapEvent.map);
      return;
      
    } else if (mapEvent.type === 'rowclick') {
      console.log('Labor.mapEvents', mapEvent.payload.id, 'eid');
      mapEvent.map && updateMapInfo(mapEvent.map);
      return;
      
    }
    
    DEBUG && console.log('mapEventHandler', mapEvent.type, mapEvent.payload);
    
  }
  
  public onTopScroll () {
    this.isFloating    = false;
    this.allowFloating = false;
    this.document.querySelector('h1')?.scrollIntoView({ behavior: "smooth" });
  }
  
  private checkFloatingButton () {
    
    // called after resize oder media change
    
    const windowHeight    = this.document.defaultView?.innerHeight ?? 0;
    const cellTableHeight = this.cellTableRef?.nativeElement.offsetHeight ?? 0;
    
    // needs big table and breakpoint >= md to enable
    this.enableFloating = (
      (!this.breakpoint?.includes('s') ?? false) &&
      (windowHeight < cellTableHeight)
    )
    
    if (!this.enableFloating) { 
      this.allowFloating = false;
      this.isFloating    = false;
    }
    
  }
  
  public onIntersection (scrollInfo: TScrollInfo): void {
    
    const [isIntersecting, direction] = scrollInfo;
    const windowHeight    = this.document.defaultView?.innerHeight ?? 0;
    const cellTableHeight = this.cellTableRef?.nativeElement.offsetHeight ?? 0;
    
    this.enableFloating = (
      (!this.breakpoint?.includes('s') ?? false) &&
      (windowHeight < cellTableHeight)
    )
    
    // allow only if enabled and user scrolls down while top row no longer intersects with viewport
    this.allowFloating = this.enableFloating && !isIntersecting && direction === 'down';
    
    if (!this.allowFloating) {
      this.isFloating = false;
    }
    
    DEBUG && console.log('Ergebnis.onToprowIntersection', 
      'enableFloating', this.enableFloating, 
      'allowFloating',  this.allowFloating, 
      direction
    );
    
  }

  private onContentScrolled() {
    
    const scrollY = this.document.defaultView?.scrollY ?? 0;
    const scrollDirection = scrollY > this.scrollPosition
      ? 'down'
      : 'up'
    ;
    this.scrollPosition = scrollY;
    
    if (this.allowFloating && !this.isFloating && scrollDirection === 'up') {
      this.isFloating = true;
      this.cd.detectChanges();
    }
    
  }

}
