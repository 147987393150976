import { NgModule } from '@angular/core';

import { AccordionModule } from '@drv-ds/drv-design-system-ng';
import { ButtonbarModule } from '@drv-ds/drv-design-system-ng';
import { ButtonModule } from '@drv-ds/drv-design-system-ng';
import { CheckboxModule } from '@drv-ds/drv-design-system-ng';
import { DropdownModule } from '@drv-ds/drv-design-system-ng';
import { DrvdModule } from '@drv-ds/drv-design-system-ng';
import { FilterModule } from '@drv-ds/drv-design-system-ng';
import { FooterModule } from '@drv-ds/drv-design-system-ng';
import { GridModule } from '@drv-ds/drv-design-system-ng';
import { HeaderModule } from '@drv-ds/drv-design-system-ng';
import { IconlinkModule } from '@drv-ds/drv-design-system-ng';
import { IconModule } from '@drv-ds/drv-design-system-ng';
import { InputGroupModule } from '@drv-ds/drv-design-system-ng';
import { ListModule } from '@drv-ds/drv-design-system-ng';
import { ModalModule } from '@drv-ds/drv-design-system-ng';
import { NavigationModule } from '@drv-ds/drv-design-system-ng';
import { NotificationModule } from '@drv-ds/drv-design-system-ng';
import { SearchbarModule } from '@drv-ds/drv-design-system-ng';
import { SegmentedButtonModule } from '@drv-ds/drv-design-system-ng';
import { StatusScreenModule } from '@drv-ds/drv-design-system-ng';
import { TableModule } from '@drv-ds/drv-design-system-ng';
import { TextareaModule } from '@drv-ds/drv-design-system-ng';
import { TileModule } from '@drv-ds/drv-design-system-ng';
import { EventteaserModule } from '@drv-ds/drv-design-system-ng';
import { TooltipModule } from '@drv-ds/drv-design-system-ng';

@NgModule({
  exports: [
    AccordionModule,
    ButtonbarModule,
    ButtonModule,
    CheckboxModule,
    DropdownModule,
    DrvdModule,
    FilterModule,
    FooterModule,
    GridModule,
    HeaderModule,
    IconlinkModule,
    IconModule,
    InputGroupModule,
    ListModule,
    ModalModule,
    NavigationModule,
    NotificationModule,
    SearchbarModule,
    SegmentedButtonModule,
    StatusScreenModule,
    TableModule,
    TextareaModule,
    TileModule,
    EventteaserModule,
    TooltipModule
  ]
})
export class DSModule { }
