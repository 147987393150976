import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { combineLatest, debounceTime, Subject, Subscription } from 'rxjs';

import { I18n } from '@pr/core/i18n.module';
import { ApiService } from '@pr/core/api.service';
import { ConfigService } from '@pr/core/config.service';
import { StateService } from '@pr/core/state.service';
import { MetaService } from '@pr/core/meta.service';

const DEBUG = false;

@Component({
  selector: 'pr-page-hilfe',
  templateUrl: './hilfe.page.html',
  styleUrls: ['./hilfe.page.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class HilfePage implements OnDestroy {

  public trans = {
    'app_title':          $localize`:@@app.title:`,
    'back_start_page':    $localize`:@@app.backlink.start.label:`,
    'hits':               $localize`:@@app.search.hits:`,
    'title':              $localize`:@@page.hilfe.title.long:`,
    'search_button':      $localize`:@@page.hilfe.search.button.label:`,
    'search_label':       $localize`:@@page.hilfe.search.input.label:`,
    'search_placeholder': $localize`:@@page.hilfe.search.input.placeholder:`,
  };

  public markdown = '';
  public fragment = '';
  public pageLink: string[];
  public search   = '';
  public toggle   = true;
  public anchors  = false;
  public hits     = 0;

  private debouncer: Subject<string> = new Subject<string>();
  private subscriber$: Subscription | undefined;

  constructor (
    @Inject(DOCUMENT) private readonly document: Document,
    public readonly i18n:   I18n,
    public readonly state:  StateService,
    public readonly config: ConfigService,
    private readonly meta:  MetaService,
    private readonly api:   ApiService,
    private readonly route: ActivatedRoute,
    private readonly cd:    ChangeDetectorRef
  ) {

    this.meta.addTags('hilfe');

    this.pageLink = ['/', 'hilfe'];

    // debounce fast typing users
    this.debouncer
      .pipe(debounceTime(300))
      .subscribe((value) => this.search = value)
    ;

    this.subscriber$ = combineLatest([
      this.route.data,
      this.route.fragment
    ]).subscribe( ([data, fragment]) => {

      const root   = this.config.environment.markdownroot;
      const locale = this.i18n.locale;
      const url    = `${root}/${locale}/${data['markdown'] as string}`;

      this.fragment = fragment ?? '';

      this.api.markdown(url).subscribe( markdown => {

        this.markdown = markdown;
        this.cd.detectChanges();

        setTimeout(() => {
          if (fragment === 'faq') {
            this.document.getElementById(fragment)?.scrollIntoView({ behavior: "smooth" });
          }
        }, 100)

      });

    });

  }

  public ngOnDestroy (): void {
    this.subscriber$?.unsubscribe();
  }

  public date (y: number, m: number, d: number): Date { return new Date(y, m, d) }
  
  // user input
  onSearch (e: any | string): void {

    const search = (e.target && typeof e.target.value === 'string') ? String(e.target.value) : String(e);

    if (search.trim().length) {
      this.debouncer.next(search.trim());
      DEBUG && console.log('PageHilfe.onSearch', search)
    }

  }

  // markdown feedback #hits
  onHits (hits: number): void {
    DEBUG && console.log('PageHilfe.onHits', hits);
    this.hits = hits;
    this.cd.detectChanges();
  }

  // probably disabled on this page
  onAntrag (): void {
    DEBUG && console.log('PageHilfe.onAntrag');
  }

  // from markdown helpers
  onFile (markdown: string) {
    DEBUG && console.log('PageHilfe.onFile', markdown.length);
    this.markdown = markdown;

    this.cd.detectChanges();
  }

  // from markdown helpers
  onToggle (foldin: boolean) {
    this.toggle = foldin;
  }

  // from markdown helpers
  onAnchors (show: boolean) {
    console.log('PageHilfe.onAnchors', show);
    this.anchors = show;
  }

}
