import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { control, Layer, marker } from 'leaflet';
import * as L from 'leaflet';

import { I18n } from '@pr/core/i18n.module';
import { StateService } from '@pr/core/state.service';
import { MetaService } from '@pr/core/meta.service';
import { ConfigService } from '@pr/core/config.service';
import { MatomoService } from '@pr/core/matomo.service';
import { HelperService } from '@pr/core/helper.service';
import { QualityDetailsCell } from '@pr/cells/quality-details/quality-details.cell';
import { IAPIDetails, INeighbours, IAPIFascs, IFachgruppe, IQuality, INeighbour, IFasc } from '@pr/core/interfaces';

import { DetailService,  } from './detail.service';
import { AppService } from 'src/app/app.service';

const DEBUG = false;



// "de";"page.detail.nextsteps.header";"Nächste Schritte"
// "de";"page.detail.nextsteps.tile1.header";"Ihre Reha wurde bereits bewilligt"
// "de";"page.detail.nextsteps.tile1.absatz1";"Sie haben bereits einen Reha-Antrag gestellt und sich für eine in Ihrem Bescheid vorgeschlagene Rehaeinrichtung entschieden?"
// "de";"page.detail.nextsteps.tile1.absatz2";"Teilen Sie uns Ihre Wunscheinrichtung postalisch über das Antwortschreiben mit oder nutzen Sie das Onlineformular."
// "de";"page.detail.nextsteps.tile1.button.url";"https://www.eservice-drv.de/eantrag/hinweis-ohne-karte-direkt.seam?formular=g1560"
// "de";"page.detail.nextsteps.tile2.header";"Sie haben noch keinen Antrag gestellt?"
// "de";"page.detail.nextsteps.tile2.absatz1";"Nutzen Sie den Reha-Onlineantrag dafür."
// "de";"page.detail.nextsteps.tile2.absatz2";"Gerne können Sie uns hier Ihre Wünsche mitteilen. Wir prüfen die Möglichkeit der Berücksichtigung. "
// "de";"page.detail.nextsteps.tile2.button.url";"https://www.deutsche-rentenversicherung.de/DRV/DE/Reha/Reha-Antragstellung/reha-antragstellung_node.html"




@Component({
  selector: 'pr-page-detail',
  templateUrl: './detail.page.html',
  styleUrls: ['./detail.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailPage implements OnDestroy, AfterViewChecked {

  public DEBUG = DEBUG;

  public trans = {
    'app_title':          $localize`:@@app.title:`,
    'page_title':         $localize`:@@app.page.detail.title:`,
    'back_link':          $localize`:@@app.backlink.ergebnis.label:`,
    'btn_print_label':    $localize`:@@header.label.print:`,
    'btn_antrag_label':   $localize`:@@header.label.antrag:`,
    'link_quali_label':   $localize`:@@page.detail.qualitaet.link.label:`,
    'link_quali_title':   $localize`:@@page.detail.qualitaet.link.title:`,
    'zoom_in_title':      $localize`:@@app.map.zoom.in:`,
    'zoom_out_title':     $localize`:@@app.map.zoom.out:`,
    'wz_noinfo':          $localize`:@@app.wartezeit.noinfo:`,
    'aria_label_map':     $localize`:@@app.page.detail.aria_label_map:`,
    'tile1_url':          $localize`:@@page.detail.nextsteps.tile1.button.url:`,
    'tile2_url':          $localize`:@@page.detail.nextsteps.tile2.button.url:`,
    'tile1_btn_label':    $localize`:@@page.detail.nextsteps.tile1.button.label:`,
    'tile2_btn_label':    $localize`:@@page.detail.nextsteps.tile2.button.label:`,
    'tile1_btn_linktext': $localize`:@@page.detail.nextsteps.tile1.button.externalLinkText:`,
    'tile2_btn_linktext': $localize`:@@page.detail.nextsteps.tile2.button.externalLinkText:`,
    'tile1_btn_subtitle': $localize`:@@page.detail.nextsteps.tile1.button.subtitle:`,
    'tile2_btn_subtitle': $localize`:@@page.detail.nextsteps.tile2.button.subtitle:`,
  };

  public dataReady = false;
  public quality: IQuality | undefined;
  public data: IAPIDetails | undefined;
  public fascs: IAPIFascs | undefined;
  public fasc: IFasc | undefined;
  private fachgruppe: IFachgruppe | undefined;
  public neighbours: INeighbours | undefined;
  public backRoute: string[] = [];

  public leafletOptions: any;
  public leafletLayersControl: any;
  public leafletLayers: Layer[] = [];

  private map: L.Map | undefined;
  private dataSubscriber$: Subscription | undefined;

  // Query for a VIEW child of type `QualityDetailsCell`
  @ViewChild(QualityDetailsCell) viewChild!: QualityDetailsCell;

  constructor (
    public readonly  config:   ConfigService,
    public readonly  i18n:     I18n,
    public readonly  router:   Router,
    public readonly  route:    ActivatedRoute,
    public readonly  state:    StateService,
    public readonly  helper:   HelperService,
    private readonly service:  DetailService,
    private readonly meta:     MetaService,
    private readonly matomo:   MatomoService,
    private readonly renderer: Renderer2,
    private readonly cd:       ChangeDetectorRef,
    private readonly app:      AppService,
  ) {
    
    this.leafletOptions = {
      layers: [this.config.mapConfig.layerDefault],
      zoom:   this.config.mapConfig.zoomDetail,
      center: this.config.mapConfig.centerDefault,
      zoomControl: false,
    };

    this.leafletLayersControl = {
      baseLayers: {
        'Open Street Map':  this.config.mapConfig.layerDefault,
        ÖPNV:               this.config.mapConfig.layerOEPNV
      },
      overlays: { }
    };

    this.route.params.subscribe( params => {
      this.dataSubscriber$?.unsubscribe();
      // get results with id from params
      this.dataSubscriber$ = this.service.results$(params).subscribe( (results) => {
        const [ data ] = results;
        if (data.einrichtung) {
          this.update(results);
        }
      });
    });

  }

  public ngOnDestroy (): void {
    this.dataSubscriber$?.unsubscribe();
  }

  private update (results: [IAPIDetails, IFachgruppe, IAPIFascs, IFasc, string[]]): void {

    DEBUG && console.log('Details.results', results);

    const [ data, fachgruppe, fascs, fasc, backRoute ] = results;

    this.data       = data;
    this.fasc       = fasc;
    this.fascs      = fascs;
    this.fachgruppe = fachgruppe;
    this.backRoute  = backRoute;

    this.meta.addTags('detail', data);
    
    // add wartezeiten thresholds
    this.data.fachabteilung.wz_thresholds = fasc.wartezeiten;

    // extract quality data
    this.quality = this.service.prepareQuality(this.data);

    // unique weitere FAs
    const weiteresFAs = this.data.weitereFachabteilungen.map( fa => this.fascs ? `${this.fascs[fa].name_detail} - ${this.fascs[fa].spec_detail}` : '')
    this.data.uniqueFachabteilungen = [ ... new Set(weiteresFAs) ];

    // extract prev/next IDs
    this.neighbours = this.service.neighbours(this.data.fachabteilung.idFach);
    const pos = this.state.posFachabteilung(this.data.fachabteilung.idFach);
    DEBUG && console.log('Details.neighbours', this.neighbours?.curr.id, pos);

    // adjust map center
    this.leafletOptions = Object.assign({}, this.leafletOptions, {
      center: L.latLng(this.data.fachabteilung.lat, this.data.fachabteilung.lon)
    });

    // update map marker
    this.leafletLayers = [ marker(
      [this.data.fachabteilung.lat, this.data.fachabteilung.lon], {
        icon: this.config.mapConfig.iconBlue,
      }
    )];

    // signal UI we're ready
    setTimeout( () => {
      this.dataReady = true;
      this.cd.detectChanges();
      this.ngAfterViewChecked()
    }, 100);

  }

  public ngAfterViewChecked(): void {
    
    const $qbox = document.querySelector('pr-cell-quality-details') as HTMLElement;
    const $map  = document.querySelector('div.map') as HTMLElement;
    
    if ($qbox && $map) {

      const qualityDetailsHeight = $qbox.offsetHeight;

      if ($map && this.map) {
        const newHeight = ['ts', 'sm'].includes(this.app.media$.getValue() || '')
          ? '80vh'
          : `${qualityDetailsHeight}px`
        
        this.renderer.setStyle($map, "height", newHeight);
        this.map.invalidateSize()
      }
      
    }

  }

  public navigateNeighbour (neighbour: INeighbour): void {

    DEBUG && console.log('Detail.navigateNeighbour', neighbour);

    // const queryParams = this.state.queryparams();
    const route = ['/', 'de', neighbour.slug, neighbour.id, this.fachgruppe?.id_fagrp ?? 0, 'details'];

    // next/prev fachabteilung
    this.dataReady = false;
    void this.router.navigate(route);

  }

  public onMapReady (map: L.Map): void {
    this.map = map;
    map.addControl(control.zoom({
      position:     'topleft',
      zoomInTitle:  this.trans.zoom_in_title,
      zoomOutTitle: this.trans.zoom_out_title,
    }));
  }

  public onNavigate (what: 'print' | 'antrag' | string): void {

    // const queryParams = Object.assign({}, this.state.queryparams(), { back: 'detail' })

    if (what === 'print') {

      // MATOMO
      this.matomo.trackEvent('details', 'print', this.data?.einrichtung.name);

      // PRINT
      try {
        if(!document.execCommand('print', false, undefined)) {
          window.print();
        }
      } catch {
        window.print();
      }

    } else if (what === 'antrag') {

      if (this.data?.fachabteilung) {

        // Special Markdown for AHB
        if (this.data.fachabteilung.isAhb) {
          void this.router.navigate(['/', 'de', 'zumantrag', 'ahb']);
          return;

      // Special Markdown for Wartezeit, blocked
      } else if (
          this.data.fachabteilung.wartezeit.gruppe === 5
          // || this.data.fachabteilung.wartezeit >= this.data.fachabteilung.wz_thresholds[1]
      ) {
          void this.router.navigate(['/', 'de', 'zumantrag', 'wzg']);
          return;

        }

      }

      void this.router.navigate(['/', 'de', 'zumantrag', 'def']);

    } else {
      console.log(what)
      
    }

  }

}
