import { Injectable } from '@angular/core';
import { IAPIFachgruppen, IFachgruppe } from './interfaces';
import fascsJson from '../../assets/api/json/pr.fachgruppen.de.json';

@Injectable({
  providedIn: 'root'
})
export class FachgruppenService {
  
  private fachgruppen: IAPIFachgruppen = fascsJson.data;
  
  get dummy ():IFachgruppe {

    return {
      id_fagrp:             0,
      name:                 'dummy',
      sort:                 0,
      fasc:                 ['0000'],
      erg_h1:               'dummy',
      erg_h2:               'dummy',
      // name_short:           'dummy',
      // detail_erkrankungen:  'dummy',
      slug:                 'suche',
      tab_title:            'Kliniksuche',
      meta_title:           'Kliniksuche',
      meta_description:     'Hier finden Sie Reha-Fachabteilungen per Stichwortsuche',
    } as IFachgruppe;

  }

  public find (idFagr: string, data: IFachgruppe[]): IFachgruppe {

    let found: any;

    data.forEach( fagr => {
      if (!found) {
        if (fagr.id_fagrp === Number(idFagr)) {
          found = fagr;
        } else if (fagr.childs) {
          fagr.childs.forEach( child => {
            if (child.id_fagrp === Number(idFagr)) {
              found = child;
            }
          })

        }
      }

    });

    return found ?? this.dummy;

  }

  public filter (predicate: (fagr: IFachgruppe) => Boolean): IFachgruppe[] {

    const results: IFachgruppe[] = [];

    this.fachgruppen.forEach( fagr => {
      
      if (predicate(fagr)) { results.push(fagr); }
      
      // only 2 levels possible
      fagr.childs.forEach( fagr => {
        if (predicate(fagr)) { results.push(fagr); }
      })

    });

    return results;

  }

}
