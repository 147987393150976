import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Self, ViewChild } from '@angular/core';
import { CollapsibleComponent } from '@drv-ds/drv-design-system-ng';

const DEBUG = false;

@Component({
  selector: 'pr-cell-filter',
  templateUrl: './filter.cell.html',
  styleUrls: ['./filter.cell.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterCell {

  public isOpen = false;
  public trans = {
    'filter_possibilities': 'Filtermöglichkeiten' // trans-missing
  }

  @ViewChild(CollapsibleComponent) collapsible!: CollapsibleComponent;

  @HostListener('click', ['$event.target']) Click() {
    this.isOpen = this.collapsible.contentClasses.includes("drv-collapsible__content--open");
    DEBUG && console.log('Click', this.height, this.isOpen ? 'open' : 'closed');
  }

  constructor(@Self() private element: ElementRef) { }

  get height(): number {
    return this.element.nativeElement.offsetHeight;
  }

}
