// <reference types='@runette/leaflet-fullscreen' />

import { Component, Input, AfterViewInit, OnChanges, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Map, circle, marker, Marker, Layer, LayerGroup, control, MapOptions, LeafletEvent } from 'leaflet';

import { IAPIFascs, IErgebnisResults } from '@pr/core/interfaces';
import { MapPopupService } from '@pr/atoms/map-popup/map-popup.service';
import { ConfigService } from '@pr/core/config.service';

// Fit to marker
// var  myFGMarker = new L.FeatureGroup();
// marker = L.marker(lat_lng);
// myFGMarker.addLayer(marker);
// myFGMarker.addTo(map);
// map.fitBounds(myFGMarker.getBounds());

  // https://stackoverflow.com/questions/56451868/how-to-prevent-leaflet-map-elements-to-get-focus/56507287#56507287
  // https://github.com/Leaflet/Leaflet/issues/3472
  // markerEinrichtung._icon.removeAttribute('tabindex');

interface IPRMarker extends Marker {
  _icon?: any
}

@Component({
  selector: 'pr-cell-map',
  templateUrl: './map.cell.html',
  styleUrls: ['./map.cell.scss']
})
export class MapCell implements OnChanges, AfterViewInit {

  public readonly trans = {
    standort:           $localize`:@@app.map.standort:`,
    einrichtungen:      $localize`:@@app.map.einrichtungen:`,
    accessibility_hint: $localize`:@@app.map.accessibility.hint:`,
    oepnv:              $localize`:@@app.map.oepnv:`,
    railway:            $localize`:@@app.map.railway:`,
    umkreis:            $localize`:@@app.map.umkreis:`,
    km:                 $localize`:@@app.map.km:`,
    osm:                $localize`:@@app.map.osm:`,
    title_quality_fa:   $localize`:@@page.ergebnis.circle.title.fachabteilung:`,
    title_quality_vg:   $localize`:@@page.ergebnis.circle.title.vergleichsgruppe:`,
    zoom_in_title:      $localize`:@@app.map.zoom.in:`,
    zoom_out_title:     $localize`:@@app.map.zoom.out:`,
    fullscreen:         $localize`:@@app.map.fullscreen:`,
    fullscreen_out:     $localize`:@@app.map.fullscreen.out:`,
    qualitaet_nodata:   $localize`:@@page.ergebnis.table.qualitaet.nodata:`,
  };

  public map?: Map;

  public selected = {
    idfach: NaN
  }

  public fascs:IAPIFascs = {};

  public leafletFeatures: Layer[] = [];
  public leafletOptions: MapOptions;
  public leafletLayersControl: any;

  private interval: number = 0;

  @Input() public results: IErgebnisResults | undefined;
  @Output() rowclick: EventEmitter<number> = new EventEmitter<number>();
  @Output() mapready: EventEmitter<Map> = new EventEmitter<Map>();

  constructor (
    public readonly config: ConfigService,
    private readonly popupService: MapPopupService,
    private readonly cd: ChangeDetectorRef,
  ) {

    this.leafletOptions = {
      layers: [this.config.mapConfig.layerDefault],
      zoom:   this.config.mapConfig.zoomDefault - 1,
      center: this.config.mapConfig.centerDefault,
      zoomControl: false,
    };

    this.leafletLayersControl = {
      baseLayers: {
        [this.trans.osm]:     this.config.mapConfig.layerDefault,
        [this.trans.oepnv]:   this.config.mapConfig.layerOEPNV,
        [this.trans.railway]: this.config.mapConfig.layerRailway
      },
      overlays: { } as Record<string, Layer>
    };

  }

  ngAfterViewInit (): void {
    setTimeout(() => this.map?.invalidateSize(true), 100);
  }

  ngOnChanges (): void {
    
    const self = this;
    
    // Quick exit
    this.interval && window.clearInterval(this.interval);
    if (!this.results) { return; }
    
    // reset container for all marker
    const overlay: Layer[] = [];

    const plz         = this.results.activeFilter.plz;
    const lat         = this.results?.activeFilter.lat;
    const lon         = this.results?.activeFilter.lon;
    const radius      = this.results?.activeFilter.radius;
    this.fascs        = this.results.fascs;

    // Marker for all Eirichtungen
    this.results.fachabteilungen.forEach( item => {

      item.entfernung = parseInt(String(item.entfernung), 10);

      const scrollParams: ScrollIntoViewOptions = { behavior: 'smooth', block: 'nearest', inline: 'start' };

      const markerEinrichtung: IPRMarker = marker(
          [item.lat, item.lon], {
            icon: this.config.mapConfig.iconBlue,
            riseOnHover: true,
            alt: '',
            keyboard: false,
          }
        )
        .on('click', markerClick)
      ;
      
      // scroll table row into view
      function markerClick (this: Marker, a: LeafletEvent): void {
        
        this.unbindPopup();
        this.bindPopup(self.popupService.htmlPopup(item));
        this.openPopup();
        
        const selector = `[data-idfach='${item.idFach}']`;
        const element  = document.querySelector(selector);
        self.selected.idfach = item.idFach;
        element?.scrollIntoView(scrollParams);
      };

      overlay.push(markerEinrichtung);

    });

    // UI map toggle for Fachabteilungen
    const label = this.trans.einrichtungen;
    const layerGroup: LayerGroup = new LayerGroup(overlay);
    this.leafletLayersControl.overlays[label] = layerGroup;

    // reset array for marker, home, umkreis
    this.leafletFeatures = [layerGroup];

    // whether decorate with Home Marker and Umkreis Circle
    if (lat && lon) {

      // create red marker for home
      const label      = `${this.trans.standort} (${plz})`;
      const htmlPopup  = `<p>${this.trans.standort} (${plz})</p>`;
      const homeMarker = marker(
          [lat, lon],
          { icon: this.config.mapConfig.iconRed, zIndexOffset: 1000, alt: this.trans.standort }
        )
        .bindPopup(htmlPopup)
        .openPopup()
      ;

      this.leafletLayersControl.overlays[label] = homeMarker;
      this.leafletFeatures.push(homeMarker);

      if (radius) {

        // create cirlce with radius in km
        const label   = `${this.trans.umkreis} (${radius}${this.trans.km})`;
        const umkreis = circle(
          [lat, lon],
          { radius: radius * 1_000 }
        );

        this.leafletLayersControl.overlays[label] = umkreis;
        this.leafletFeatures.push(umkreis);

      }

    }

    // Mmmh
    setTimeout(() =>{
      this.map?.invalidateSize(true);
    }, 100);
    // TODO reset latlng bounds

  }

  onRowClick (id: number): void {
    this.rowclick.emit(id);
  }

  onMapReady (map: Map): void {
    
    this.map = map;
    this.mapready.emit(map);
    
    map.addControl(control.zoom({
      position: 'topleft',
      zoomInTitle:  this.trans.zoom_in_title,
      zoomOutTitle: this.trans.zoom_out_title,
    }));
        
  }

}
