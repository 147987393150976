
@if (config.isTestDomain) {
  <pre class="app-debug"> {{ service.media$ | async }} </pre>
}

<!-- LOAD SVGs inline -->
<pr-atom-symbols></pr-atom-symbols>


<header>
  <pr-cell-header></pr-cell-header>
  <drv-notification
    type="inline"
    notificationId="prAppNotification"
  ></drv-notification>
</header>

<div drv-grid class="pr-container">

  <main style="position: relative" aria-live="polite">
    <router-outlet></router-outlet>
  </main>

</div>

<footer class="drv-mt-sm--05">
  <pr-cell-footer></pr-cell-footer>
</footer>
