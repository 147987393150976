import { Component, HostBinding, Input } from '@angular/core';

@Component({
  template: '',
  selector: 'pr-atom-loading',
  styleUrls: ['./loading.atom.scss'],
})
export class LoadingAtom { 
  @Input() size = 100;
  @HostBinding('class') class = 'loading-ring';
  @HostBinding('style.width.px') get width(): number { return this.size; }
  @HostBinding('style.height.px') get height(): number { return this.size; }
  @HostBinding('style.border-width.px') get border(): number { return this.size / 5; }
}
