
<div *pr-show="['ts', 'sm', 'md']" class="fxcol drv-bg--ui-05 drv-p-sm--01 drv-mt-sm--02">
    <div class="col-text fxcol drv-pr-sm--01">
        <ng-container *ngTemplateOutlet="contentH3"></ng-container>
        <ng-container *ngTemplateOutlet="contentP1"></ng-container>
        <ng-container *ngTemplateOutlet="contentP2"></ng-container>
    </div>
    <div class="col-button-small fxcol-center drv-p-sm--01">
        <ng-container *ngTemplateOutlet="contentButton"></ng-container>
    </div>
</div>

<div *pr-hide="['ts', 'sm', 'md']" class="fxrow drv-bg--ui-05 drv-p-sm--01 drv-mt-sm--02">
    <div class="col-text fxcol drv-pr-sm--01">
        <ng-container *ngTemplateOutlet="contentH3"></ng-container>
        <ng-container *ngTemplateOutlet="contentP1"></ng-container>
        <ng-container *ngTemplateOutlet="contentP2"></ng-container>
    </div>
    <div class="fxgrow"></div>
    <div class="col-button-huge fxcol-center drv-p-sm--01">
        <ng-container *ngTemplateOutlet="contentButton"></ng-container>
    </div>
</div>

<ng-template #contentH3><ng-content select="h3"></ng-content></ng-template>
<ng-template #contentP1><ng-content select="p.first"></ng-content></ng-template>
<ng-template #contentP2><ng-content select="p.second"></ng-content></ng-template>
<ng-template #contentButton><ng-content select="drv-button"></ng-content></ng-template>
