import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { BehaviorSubject, combineLatest, map, Observable, of, switchMap, tap, } from 'rxjs';
import { TableHeaderItem } from '@drv-ds/drv-design-system-ng';

import { I18n } from '@pr/core/i18n.module';
import { ApiService } from '@pr/core/api.service';
import { ConfigService } from '@pr/core/config.service';
import { HelperService } from '@pr/core/helper.service';
import { StateService } from '@pr/core/state.service';
import { FachgruppenService } from '@pr/core/fachgruppen.service';

import { IFilterConfig, IFilter, IErgFachabteilung, IFachgruppe, IAPIFilterGroup, IActiveFilter, IAPIErgebnis, IAPIFascs, IAPIPlzs, IAPIFachgruppen, IErgebnisResults, TTableSort, TTableSortArray, IAPIResponse, IMapHomeFeature, TTableSortIndex, TTableSortOrder } from '@pr/core/interfaces';
import { MatomoService } from '@pr/core/matomo.service';

const DEBUG = false;

type ICache = {
  plzs?:        IAPIPlzs
  fascs?:       IAPIFascs
  filter?:      IAPIFilterGroup[]
  fachgruppen?: IAPIFachgruppen
  ergebnisse?:  IAPIErgebnis
}

@Injectable({
  providedIn: 'root'
})
export class ErgebnisService {

  public readonly updateErgebnisse$ = new BehaviorSubject<boolean>(true);
  public merkmaleProps: IFilterConfig[] = [];

  private cacheResponse: Record<string, IAPIErgebnis> = {};
  private cache: ICache = {};

  private trans = {
    'asc':                    $localize`:@@app.sort.order.asc:`,
    'desc':                   $localize`:@@app.sort.order.desc:`,
    'sort_by_einrichtung':    $localize`:@@app.sort.by.einrichtung:`,
    'sort_by_wartezeit':      $localize`:@@app.sort.by.wartezeit:`,
    'sort_by_durchfuehrung':  $localize`:@@app.sort.by.durchfuehrung:`,
    'sort_by_reha_anschluss': $localize`:@@app.sort.by.reha_anschluss:`,
    'sort_by_quality':        $localize`:@@app.sort.by.quality:`,
    'sort_by_plz':            $localize`:@@app.sort.by.plz:`,
    'sort_by_entfernung':     $localize`:@@app.sort.by.entfernung:`,
  }

  constructor (
    public readonly i18n:          I18n,
    private readonly config:       ConfigService,
    private readonly state:        StateService,
    private readonly api:          ApiService,
    private readonly helper:       HelperService,
    private readonly fachgruppen:  FachgruppenService,
    private readonly matomo:       MatomoService,
  ) { }

  private cachedEndpoint$<K extends keyof ICache>(endpoint: K) {

    return typeof this.cache[endpoint] !== 'undefined'
      ? of(this.cache[endpoint] as Required<ICache>[K])
      : this.api.get<Required<ICache>[K]>('/' + endpoint)
      .pipe(
        map( reponse      => reponse.data ),
        tap( responseData => this.cache[endpoint] = responseData ),
      )
    ;

  }

  private ergebnisse$ (params: Params): Observable<IAPIErgebnis> {
    
    // determine fasc list if needed
    const slug  = params['indikation'];
    const fagrs = this.fachgruppen.filter(fagr => fagr.slug === slug);
    const fascsParam = fagrs.length ? fagrs[0].fasc.join(',') : '';
    
    return this.updateErgebnisse$.pipe (
      switchMap( () => {

        let apiget, abbr = '', suche = '';

        // check for abbreviation
        if (params['suche'] && params['suche'].includes('(') && params['suche'].includes(')')) {
          [ abbr, suche ] = this.helper.splitAbbreviation(params['suche']);

        } else {
          suche = params['suche'];

        }

        // pick right cache
        const cachehash = params['suche']
          ? '/einrichtungssuche' + 'de' + params['suche']
          : '/ergebnisse' + '/' + fascsParam
        ;

        if (!this.cacheResponse[cachehash]) {
          apiget = params['suche']
            ? this.api.get<IAPIErgebnis>('/einrichtungssuche', { suche })
            : fascsParam === 'ALLE'
              ? this.api.get<IAPIErgebnis>('/ergebnisse',        { })
              : this.api.get<IAPIErgebnis>('/ergebnisse',        { fascs: fascsParam })
          ;

        } else {
          DEBUG && console.log("Hit cache with", cachehash);
          return of(this.cacheResponse[cachehash]);

        }

        return apiget.pipe (
          map( response => response.data ),
          tap( responseData => this.cacheResponse = { [cachehash]: responseData } ),
        );

      })
    );
  }

  public results$ (routeParams: Params): Observable<IErgebnisResults> {

    return combineLatest([
        this.ergebnisse$(routeParams),
        this.cachedEndpoint$<'filter'>('filter'),
        this.cachedEndpoint$<'fachgruppen'>('fachgruppen'),
        this.cachedEndpoint$<'plzs'>('plzs'),
        this.cachedEndpoint$<'fascs'>('fascs'),
      ])
      .pipe(
        map( (results) => {

          const [ apiergebnisse, merkmaleGroups, fachgruppen, plzs, fascs ] = results;

          const t0 = Date.now();
          const n0 = apiergebnisse?.fachabteilungen?.length ?? 0;
          const total = apiergebnisse?.fachabteilungen?.length ?? 0;
          const state = this.state.all();

          DEBUG && console.log('EService.results.in', JSON.stringify(routeParams));

          // prep sort for gui and results
          const sort  = this.state.get<TTableSort>('sort');
          const [index, order] = sort.split(',') as TTableSortArray;
          const sortOption = this.config.sortOptions[index];
          const activeSort = [
            sortOption.label,
            order === 'asc' ? this.trans.asc : this.trans.desc
          ];

          // prep filters
          const activeFilter: IActiveFilter = {
            plz      : (state.plz)      ?? '',
            radius   : (state.radius)   ? Number(state.radius) : undefined,
            merkmale : (state.merkmale) ? String(state.merkmale).split(',') : [],
          };
          
          // prep marker on map for plz
          const homeCoords = plzs[activeFilter.plz];
          const homeFeature: IMapHomeFeature = {
            plz:    activeFilter.plz,
            radius: activeFilter.radius ?? 0,
            lat:    homeCoords?.lat ?? 0,
            lon:    homeCoords?.lon ?? 0,
          }

          // prepare unfiltered result
          const ergebnisResult: IErgebnisResults = {
            fascs,
            activeFilter,
            activeSort,
            homeFeature,
            suchStr:          routeParams['suche'] ?? '',
            header:           '',
            beispiele:        '',
            showFascName:     true,
            showFascSpec:     true,
            filterConfig:     this.createFilterConfig(merkmaleGroups, activeFilter),
            fachgruppe:       this.findFachgruppe(routeParams, fachgruppen),
            headerTable:      this.createTableHeader(sort),
            fachabteilungen:  apiergebnisse?.fachabteilungen ?? [],
          }

          // find headers for page
          if (!ergebnisResult.suchStr) {
            ergebnisResult.header    = ergebnisResult.fachgruppe?.erg_h1 ?? 'H1 missing';
            ergebnisResult.beispiele = ergebnisResult.fachgruppe?.erg_h2 ?? '';

          } else {
            ergebnisResult.header    = $localize`:@@page.ergebnis.header.kliniksuche:`;
            ergebnisResult.beispiele = $localize`:@@page.ergebnis.header.kliniksuche.prefix:` + `: '${ergebnisResult.suchStr}'`;

          }

          // enhance, filter and sort data/fachabteilunen
          ergebnisResult.fachabteilungen = this.addFASCInfo(ergebnisResult, fascs);
          ergebnisResult.fachabteilungen = this.generateDetailRoutes(ergebnisResult);
          ergebnisResult.fachabteilungen = this.filterErgebnisse(ergebnisResult, plzs, activeFilter);
          ergebnisResult.fachabteilungen.sort(this.sorter(index, order));

          // remember for details page, without no Quallity eg. Augenkliniken
          const clone = Object.assign( {}, ergebnisResult );
          clone.fachabteilungen = clone.fachabteilungen.filter(fa => fa.qualitaet !== this.config.no_quality);
          this.state.saveErgebnisResult(clone);

          DEBUG && console.log('EService.results.out', Date.now() - t0, 'ms', ergebnisResult.fachabteilungen.length, '/', n0);
              
          // ZVWBG2258-453
          const subtotal = ergebnisResult.fachabteilungen.length;
          this.trackFilter(total, subtotal, activeFilter, JSON.stringify(routeParams));
    
          return ergebnisResult;

        })
      )
    ;

  }

  private addFASCInfo (ergebnis: IErgebnisResults, fascs: IAPIFascs): IErgFachabteilung[] {

    ergebnis.fachabteilungen.forEach( (fa: IErgFachabteilung) => {
      const fasc = fascs[fa.fasc];
      fa.name_ergebnis = fasc.name_ergebnis;
      fa.spec_ergebnis = fasc.spec_ergebnis;
      fa.wz_thresholds = fasc.wartezeiten;
    });

    return ergebnis.fachabteilungen;

  }

  private generateDetailRoutes (ergebnis: IErgebnisResults): IErgFachabteilung[] {

    // was 12ms with 4500

    const id_fagrp  = String(ergebnis.fachgruppe?.id_fagrp);

    ergebnis.fachabteilungen.forEach( (fa: IErgFachabteilung) => {

      if (fa.qualitaet === this.config.no_quality) {
        // e.g. Augenkliniken
        fa.detailRoute  = ['/', 'de', 'zumantrag', 'def'];

      } else {
        fa.detailRoute  = ['/', 'de', fa.slug, String(fa.idFach), id_fagrp, 'details'];

      }

    });

    return ergebnis.fachabteilungen;

  }
  
  private trackFilter (total: number, filtered: number, activeFilter: IActiveFilter, params: string) {
    
    const geoinfo = (
      activeFilter.plz && activeFilter.radius ?
        `GEO: ${activeFilter.plz}/${activeFilter.radius}` :
        undefined
    );
    
    const payload = [
      this.config.isTestDomain ? 'TEST' : 'LIVE', 
      (new Date).toJSON().slice(0, 10),
      geoinfo,
      'Filter: ' + String(activeFilter.merkmale),
      `Anzahl: ${filtered}/${total}`,
      `${params}`
    ].filter(Boolean).join(' $$ ');
    
    this.matomo.trackEvent(
      'FILTER',
      payload,
      'trackname',
      0,
    )
    
  }

  // filters Fachabteilungen by PLZ/Radius/Entfernung und Merkmale
  private filterErgebnisse(ergebnis: IErgebnisResults, plzs: IAPIPlzs, activeFilter: IActiveFilter): IErgFachabteilung[] {

    const t0 = Date.now();

    // filter on geo
    if (activeFilter.plz && activeFilter.radius) {
      
      // set user home PLZ as coords
      const homeCoords = plzs[activeFilter.plz];
      
      if ( homeCoords ) {
        ergebnis.activeFilter.lat = homeCoords.lat;
        ergebnis.activeFilter.lon = homeCoords.lon;

        // add distance
        ergebnis.fachabteilungen.forEach( fa => {
          fa.entfernung = this.helper.getDistance (
            homeCoords.lat,
            homeCoords.lon,
            fa.lat,
            fa.lon
          );
        });

        // filter on distance
        const radius = activeFilter.radius || 0;
        if (radius > 0) {
          ergebnis.fachabteilungen = ergebnis.fachabteilungen
            .filter( fa => (fa.entfernung ?? 0) <= radius)
          ;
        }
      } else {
        console.warn('EService.filter', 'invalid PLZ', 'ignoring...');
        activeFilter.plz = '';
        activeFilter.radius = this.config.defaultState.radius;
        
      }

    }

    // filter on merkmale
    if (activeFilter.merkmale.length) {
      const activeMerkmale = activeFilter.merkmale.map(Number);
      ergebnis.fachabteilungen = ergebnis.fachabteilungen.filter( fa => {
        return activeMerkmale.every( merkmal => fa.filterMerkmale.indexOf(merkmal) !== -1 );
      });
    }
    
    DEBUG && console.log('EService.filter.out', Date.now() - t0, 'ms');

    return ergebnis.fachabteilungen;

  }

  private createFilterConfig (filterGroups: IAPIFilterGroup[], filter: IActiveFilter): IFilterConfig[] {

    const result = filterGroups.map( group => {

      // find matching merkmale in this group
      const groupMerkmale = group.childs.map( item => String(item.id_filt));
      const intersection  = groupMerkmale.filter( item => filter.merkmale.includes(item) );

      // map options from merkmal
      const options = group.childs
        // AHB Filter ZVWBG2258-168
        .filter( (merkmal: IFilter) => merkmal.id_filt !== 902 )
        .map( (merkmal: IFilter) => ({ label: merkmal.label, value: String(merkmal.id_filt) }) )
      ;

      return {
        id:       String(group.id_figp),
        disabled: !!group.disabled,
        visible:  group.visible,
        label:    group.label,
        multiple: group.type === 'checkbox' || group.type === 'toggle',
        model:    intersection,
        options,
        type:     group.type
      } as const;

    });

    return result;

  }

  private createTableHeader (sort: TTableSort): TableHeaderItem[] {

    // Header Infos for DS TableComponent
    const header: TableHeaderItem[] = Object
      .entries(this.config.sortOptions)
      .sort((a, b) => Number(a[0]) - Number(b[0]))
      .map(( [_, value] ) => ( { label: value.label } ))
    ;

    const [index, order] = sort.split(',') as TTableSortArray;
    header[Number(index)].order  = order;
    header[Number(index)].sorted = true;

    // log sorted column only
    const sortColumn = header.find( h => !!h.order);
    DEBUG && console.log('EService.createTableHeader', sort, JSON.stringify(sortColumn, null, 2));

    return header;

  }
  
  private sorterWartezeit (a: IErgFachabteilung, b: IErgFachabteilung, direc: number): number {
    
    // effectively groups by wartezeit and sorts by quality, desc within group
    return (a.wartezeit.gruppe === b.wartezeit.gruppe 
      ? (b.qualitaet - a.qualitaet) * +1 
      : (a.wartezeit.gruppe - b.wartezeit.gruppe) * direc
    );
    
  }

  private sorter (index: TTableSortIndex, order: TTableSortOrder): (a: IErgFachabteilung, b: IErgFachabteilung) => number {

    const prop  = this.config.sortOptions[index].prop;
    const type  = this.config.sortOptions[index].type
    const direc = order === 'desc' ? -1 : +1;

    return (a: IErgFachabteilung, b: IErgFachabteilung) => {
      return ( 
        type === 'string'    ? (String(a[prop])).localeCompare(String(b[prop]))  * direc : 
        type === 'number'    ? (Number(a[prop]) - Number(b[prop]))               * direc :
        type === 'wartezeit' ? this.sorterWartezeit(a, b, direc) :
        0
      );
    }

  }

  private findFachgruppe (routeParams: Params, data: IFachgruppe[]): IFachgruppe | undefined {

    let fachgruppe: IFachgruppe | undefined;

    if (routeParams['suche']) {
      return {
        id_fagrp:         0,
        childs:           [],
        name:             'EINRICHTUNGSSUCHE',
        sort:             0,
        fasc:             [],
        erg_h1:           $localize`:@@page.ergebnis.header.kliniksuche:`,
        erg_h2:           'erg_h2',
        slug:             'einrichtungs-suche',
        tab_title:        '',
        meta_title:       'Meine Rehabilitation | Einrichtungssuche',
        meta_description: 'Hier finden Sie passende Reha-Fachabteilungen per Einrichtungssuche.',
      };

    } else if (routeParams['indikation']) {

      const slug = routeParams['indikation'];

      data.forEach( fagr => {
        if (!fachgruppe) {
          if (fagr.slug === slug) {
            fachgruppe = fagr;
          } else if (fagr.childs) {
            fagr.childs.forEach( child => {
              if (child.slug === slug) {
                fachgruppe = child;
              }
            })

          }
        }

      })

      return fachgruppe;

    } else {

      throw "findFachgruppe failed. Strange routeParams";

    }

  }

}
