@for (child of children; track child) {
  @switch (child.type) {
    <!-- PLAIN TEXT -->
    @case ('text') {
      <span
        [prHighlight]="child.highlight ?? ''"
        [innerHtml]="child.value"
      ></span>
    }
    <!-- HEADINGS -->
    @case ('heading') {
      <!-- H1 can't be here -->
      @if (child.depth === 1) {
        <h1>H1 not allowed in Markdown</h1>
      }
      <!-- HEADERS -->
      @if (child.depth === 2) {
        <h2
          [inline]="child"
        ></h2>
      }
      @if (child.depth === 3) {
        <h3
          [inline]="child"
        ></h3>
      }
      @if (child.depth === 4) {
        <h4
          [inline]="child"
        ></h4>
      }
    }
    <!-- BREAK -->
    @case ('break') {
      <br />
    }
    <!-- LINKS -->
    @case ('link') {
      @if (linktype(child?.url) === 'intern') {
        <a class="drv-icon-link"
          [routerLink]="makeInternal(child?.url)"
          [fragment]="getFragment(child?.url)"
          [title]="child.title"
          >
          {{ child.children[0].value }}
        </a>
      }
      <!-- e.g. ./leicht -->
      @if (linktype(child?.url) === 'subpage') {
        <a class="drv-icon-link"
          [routerLink]="child?.url"
          [title]="child.title"
          >
          {{ child.children[0].value }}
        </a>
      }
      @if (linktype(child.url ?? '') === 'extern') {
        <drv-iconlink
          icon="arrow-external-small"
          [href]="child.url ?? ''"
          subtitle=""
          target="_blank"
          [title]="child.title"
          >
          {{ child.children[0].value }}
        </drv-iconlink>
      }
      @if (linktype(child.url ?? '') === 'email') {
        <drv-iconlink
          icon="mail"
          [href]="child.url ?? ''"
          [title]="child.title ?? ''"
          >
          {{ child.children[0].value }}
        </drv-iconlink>
      }
    }
    <!-- STRONG -->
    @case ('strong') {
      <strong [inline]="child"></strong>
    }
    <!-- EMPHASIS -->
    @case ('emphasis') {
      <em   [inline]="child"></em>
    }
    <!-- PARAGRAPH -->
    @case ('paragraph') {
      <p   [inline]="child"></p>
    }
    <!-- LIST -->
    @case ('list') {
      <ul       [inline]="child"></ul>
    }
    <!-- LISTITEM -->
    @case ('listItem') {
      <li   [inline]="child"></li>
    }
    <!-- HTML -->
    @case ('html') {
      <span class="pr-highlight" [innerHtml]="child.value"></span>
    }
    <!-- ACCORDIONS -->
    @case ('accordion-wrapper-start') {
      <drv-accordion>
        @for (item of child.children; track item) {
          <drv-accordionitem
            [title]="item.title ?? ''"
            [isOpen]="item.isOpen"
            >
            <span [inline]="item"></span>
          </drv-accordionitem>
        }
      </drv-accordion>
    }
    @case ('accordion-item') {
      <drv-accordionitem
        [title]="child.title ?? ''"
        [isOpen]="false"
        >
        {{ child }}
      </drv-accordionitem>
    }
    <!-- DETAILS -->
    @case ('details-item') {
      <drv-details
        [title]="child.title ?? ''"
        [isOpen]="child.isOpen"
        >
        <span [inline]="child"></span>
      </drv-details>
    }
    <!-- DEFAULT -->
    @default {
      <div>
        DEFAULT-INLINE {{ child.type | json }}
      </div>
    }
  }
}
