import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import packageJson from '../../../../package.json';

import { I18n } from '@pr/core/i18n.module';
import { ConfigService } from '@pr/core/config.service';
import { FooterLinkItem, ModalService } from '@drv-ds/drv-design-system-ng';
import { MatomoService } from '@pr/core/matomo.service';
import { ApiService } from '@pr/core/api.service';

@Component({
  selector: 'pr-cell-footer',
  templateUrl: './footer.cell.html',
  styleUrls: ['./footer.cell.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class FooterCell implements OnDestroy, OnInit, AfterViewInit {

  public trans = {
    email_barriere:       'mailto:' + $localize`:@@footer.email.barriere.melden:`,
    label_mail_barriere:  $localize`:@@footer.email.barriere.label:`,
    label_barriere:       $localize`:@@footer.link.barriere.label:`,
    copyright:            $localize`:@@footer.copyright.label:`,
    cookies:              $localize`:@@footer.cookies.label:`,
    feedback:             $localize`:@@footer.feedback.floater.label:`,
    label_hilfe:          $localize`:@@footer.link.hilfe.label:`,
    label_impressum:      $localize`:@@footer.link.impressum.label:`,
    label_qualitaet:      $localize`:@@footer.link.qualitaet.label:`,
    label_datenschutz:    $localize`:@@footer.link.datenschutz.label:`,
    mastodon_href:        $localize`:@@footer.link.mastodon.href:`,
    mastodon_title:       $localize`:@@footer.link.mastodon.title:`,
    mastodon_label:       $localize`:@@footer.link.mastodon.label:`,
    facebook_href:        $localize`:@@footer.link.facebook.href:`,
    facebook_title:       $localize`:@@footer.link.facebook.title:`,
    facebook_label:       $localize`:@@footer.link.facebook.label:`,
    cookies_close:        $localize`:@@app.cookies.close:`,
    cookies_consent:      $localize`:@@app.cookies.radio.consent:`,
    cookies_no_consent:   $localize`:@@app.cookies.radio.no.consent:`,
    cookies_hint:         $localize`:@@app.cookies.radio.hint:`,
    cookies_setting:      $localize`:@@app.cookies.settings.link.label:`,
    telefon_href:         $localize`:@@footer.service.telefon.href:`,
  }

  public features: Record<string, boolean>;
  public version = packageJson.version;
  public items: FooterLinkItem[] = [];
  public checkValue: string[] | undefined = undefined;
  public markdown = '';

  public cookieConsentHasHint: boolean = false;
  public cookieConsentHintText: string = '';

  private readonly featureSubscription: Subscription;

  constructor(
    private readonly api: ApiService,
    private readonly cd: ChangeDetectorRef,
    private readonly i18n: I18n,
    private readonly router: Router,
    private readonly config: ConfigService,
    private readonly modalService: ModalService,
    private readonly matomoService: MatomoService,
  ) {

    this.features = Object.assign({}, this.config.features);

    this.featureSubscription = this.config.features$.subscribe(features => {
      if (features) {
        this.features = Object.assign({}, this.features, features);
      }
    });

    this.items = [
      { href: this.trans.email_barriere, text: this.trans.label_mail_barriere, icon: 'mail' },
      { href: '', route: ['/', 'de', 'impressum'], text: this.trans.label_impressum, icon: 'arrow-right' },
      { href: '', route: ['/', 'de', 'datenschutz'], text: this.trans.label_datenschutz, icon: 'arrow-right' },
      { href: '', route: ['/', 'de', 'barrierefreiheit'], text: this.trans.label_barriere, icon: 'arrow-right' },
      { href: '', route: ['/', 'de', 'qualitaet'], text: this.trans.label_qualitaet, icon: 'arrow-right' },
      { href: '', route: ['/', 'de', 'hilfe'], text: this.trans.label_hilfe, icon: 'question-mark' },
      { href: '#', text: this.trans.cookies_setting, icon: 'maintenance' }
    ];

    // add /labor if dev
    if (this.config.environment.name === 'DEV') {
      this.items.push(
        { href: '', route: ['/', 'de', 'labor'], text: 'Labor', icon: 'question-mark' }
      );
    }

    const root   = this.config.environment.markdownroot;
    const locale = this.i18n.locale;
    const url    = `${root}/${locale}/cookie_consent.md`;

    this.api.markdown(url).subscribe(markdown => {
      this.markdown = markdown;
      this.cd.detectChanges();
    });

  }

  ngOnInit() {

    let showDialog = false;

    if (this.matomoService.cookieConsent) {
      this.checkValue = ['consent_true'];

    } else if (this.matomoService.cookieConsent === false) {
      this.checkValue = ['consent_false'];

    } else {
      this.checkValue = undefined;
      showDialog = true;

    }

    // DS 4.6, add timeout to help initaialize
    setTimeout(() => {
      if (showDialog) {
        this.modalService.updateModal({
          id: 'cookie_modal',
          open: showDialog
        });
      }
    }, 100);

  }

  // ZVWBG2258-202 - wrong tab index for cookie consent
  public ngAfterViewInit(): void {
    const cookieButton: HTMLElement | null = document.querySelector('a.drv-footer__link[href="#"]');
    if (cookieButton !== null) {
      cookieButton.onclick = (event: Event) => {
        this.modalService.updateModal({
          id: 'cookie_modal',
          open: true
        });
        event.preventDefault();
      }
    }
  }

  public ngOnDestroy(): void {
    this.featureSubscription?.unsubscribe();
  }

  public navigate(what: 'feedback'): void {
    void this.router.navigate(['/', 'de', 'feedback']);
  }

  public setConsent(): void {
    if (this.checkValue?.[0] === 'consent_true') {
      this.matomoService.rememberConsent();
    } else if (this.checkValue?.[0] === 'consent_false') {
      this.matomoService.forgetConsent();
    }
  }

  public closeCookieDialog() {
    if (!!this.checkValue) {
      this.cookieConsentHasHint = false;
      this.modalService.updateModal({
        id: 'cookie_modal',
        open: false
      });
    }
    else {
      this.cookieConsentHasHint = true;
      this.cookieConsentHintText = this.trans.cookies_hint
    }
  }
  public closeNpsDialog() {
    this.modalService.updateModal({
      id: 'nps_modal',
      open: false
    });

  }

}

