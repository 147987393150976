import { Component, Input, ViewEncapsulation } from '@angular/core';
import { INode } from '@pr/core/interfaces';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: '[block]',
  templateUrl: './block.cell.html',
  styles: ['.slug {font-family: monospace; background-color: powderblue;}'],
  encapsulation: ViewEncapsulation.None,
})

export class BlockCell {

  @Input('block') public node: INode  | undefined;
  @Input() public pageLink: string[] | undefined;
  @Input() public show: { anchors: boolean } | undefined;

  constructor(protected html_sanitizer: DomSanitizer) {}

  get children() {
    // return 'children' in this.node ? this.node.children : [];
    return this.node?.children;
  }

  sameAsHtml(html_content: string) {
    return this.html_sanitizer.bypassSecurityTrustHtml(html_content);
  }

}

