import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { I18n } from '@pr/core/i18n.module';
import { IBewertung, IQuality } from '@pr/core/interfaces';

@Component({
  selector: 'pr-cell-quality-details',
  templateUrl: './quality-details.cell.html',
  styleUrls: ['./quality-details.cell.scss']
})
export class QualityDetailsCell implements OnChanges {

  public trans = {
    'link_quali_label':      $localize`:@@page.detail.qualitaet.link.label:`,
    'link_quali_title':      $localize`:@@page.detail.qualitaet.link.title:`,
    'mob_fachabteilung':     $localize`:@@page.detail.qualitaet.mob.fachabteilung:`,
    'mob_durchschnitt':      $localize`:@@page.detail.qualitaet.mob.durchschnitt:`,
    'dsk_fachabteilung':     $localize`:@@page.detail.qualitaet.dsk.fachabteilung:`,
    'dsk_durchschnitt':      $localize`:@@page.detail.qualitaet.dsk.durchschnitt:`,
    'mob_combined':          $localize`:@@page.detail.qualitaet.mob.combined:`,
    'dsk_combined':          $localize`:@@page.detail.qualitaet.dsk.combined:`,
    'mob_header_right':      $localize`:@@page.detail.qualitaet.mob.header.right:`,
    'mob_header_left':       $localize`:@@page.detail.qualitaet.mob.header.left:`,
    'dsk_header_right':      $localize`:@@page.detail.qualitaet.dsk.header.right:`,
    'dsk_header_left':       $localize`:@@page.detail.qualitaet.dsk.header.left:`,
    'quality_percent':       'Qualität in Prozent',
    'gkv_header':            $localize`:@@page.detail.gkv.header:`,
    'gkv_paragraph_1':       $localize`:@@page.detail.gkv.paragraph.1:`,
    'gkv_paragraph_2':       $localize`:@@page.detail.gkv.paragraph.2:`,
    'gkv_paragraph_3':       $localize`:@@page.detail.gkv.paragraph.3:`,
    'gkv_link1_label':       $localize`:@@page.detail.gkv.link1.label:`,
    'gkv_link1_fragment':    $localize`:@@page.detail.gkv.link1.fragment:`,
    'gkv_link2_label':       $localize`:@@page.detail.gkv.link2.label:`,
    'gkv_link2_url':         $localize`:@@page.detail.gkv.link2.url:`,
  };

  public toggles: Record<string, boolean> = {};

  constructor ( public i18n: I18n ) {}

  @Input()  quality: IQuality | undefined;

  public onToggle (e: Event, i: number) {
    this.toggles[i] = !this.toggles[i];
    e.preventDefault();
  }

  public ngOnChanges (changes: SimpleChanges) {
    changes['quality'].currentValue?.bewertung.forEach( (b: any, i: number) => this.toggles[i] = false)
  }

  public sorterBewertung (a: IBewertung, b: IBewertung): number {
    return a.sort - b.sort;
  }

}
