import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { loadTranslations } from '@angular/localize';

import { IAPIMessage, IAPITokens } from '@pr/core/interfaces';
import { ApiService } from '@pr/core/api.service';
import { ConfigService, TLocale } from '@pr/core/config.service';
import { StorageKey, StorageService } from './storage.service';

const DEBUG = false;

export interface II18n {
  locale: string
  setLocale: () => Promise<void>
  translate: (token: string) => string 
}

interface IDefaultStr { default: string }

@Injectable({ providedIn: 'root' })
export class I18n {

  public locale: TLocale = 'de';
  public tokens: IAPITokens = {};

  constructor (
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly api: ApiService,
    private readonly config: ConfigService,
    private readonly storage: StorageService,
  ) {
    this.locale = this.config.defaultLocale as TLocale;
  }
  
  public translate (token: string): string {
    
    if (!token) { return ''; }
    
    const text = this.tokens[token];
    if (!text) {
      console.warn('I18n: no token for', token);
    } 
    return text ?? token;
    
  }

  public interpolate (msg: IAPIMessage): IAPIMessage {
    
    // Test width a99, z99, a002, huhu, eid996, etc1

    let { text, severity, code } = msg;

    const template = this.translate(msg.code);
    
    if (template){
      text = template.replace('%s', text);
    }
    
    DEBUG && console.log('I18N.interpolate', msg.code);
    
    return { text, severity, code };
    
  }


  private sanitizeUrl (): TLocale {

    const base  = this.config.environment.basehref;
    const pages = this.config.pages;

    let path1, pathLanguage, pageDetected;
    let userLanguage: string  = navigator.language;
    let localLanguage = this.storage.get(StorageKey.Locale) ?? '';

    if (base === '/') {
      path1         = location.pathname.split('/').filter(Boolean).slice(0, 1)[0];
      pathLanguage  = location.pathname.split('/').filter(Boolean).slice(0, 1)[0];
      pageDetected  = location.pathname.split('/').filter(Boolean).slice(1, 2)[0];

    } else {
      path1         = location.pathname.split('/').filter(Boolean).slice(1, 2)[0];
      pathLanguage  = location.pathname.split('/').filter(Boolean).slice(1, 3)[0];
      pageDetected  = location.pathname.split('/').filter(Boolean).slice(2, 3)[0];

    }

    // sanitize
    if (!this.config.availableLocale.includes(userLanguage))  { userLanguage = ''; }
    if (!this.config.availableLocale.includes(pathLanguage))  { pathLanguage = ''; }
    if (!this.config.availableLocale.includes(localLanguage)) { localLanguage = ''; }
    if (!pages.includes(pageDetected)) {
      if (!pages.includes(path1)) {
        pageDetected = '';
      } else {
        pageDetected = path1;
      }
    }

    if (pageDetected === this.config.errorPage) {
      // don't work on this page
      return this.locale;
    }

    if (pathLanguage && pageDetected) {
      // this looks good
      return pathLanguage as TLocale;
    }

    if (pathLanguage && !pageDetected) {
      // wrong page, good lang
      DEBUG && console.log('i18n.navigate.error', pathLanguage, this.config.errorPage);
      this.router.navigate([pathLanguage, this.config.errorPage], { relativeTo: this.route });
      return pathLanguage as TLocale;
    }

    if (!pathLanguage && pageDetected) {
      // wrong lang, good page
      const newLanguge = userLanguage || localLanguage || this.config.defaultLocale;
      DEBUG && console.log('i18n.navigate.detected', newLanguge, pageDetected);
      this.router.navigate([newLanguge, pageDetected], { relativeTo: this.route });
      return newLanguge as TLocale
    }

    if (!pathLanguage && !pageDetected) {
      // wrong lang, wrong page : start over
      const newLanguge = userLanguage || localLanguage || this.config.defaultLocale;
      DEBUG && console.log('i18n.navigate.default', newLanguge, this.config.defaultPage);
      this.router.navigate([newLanguge, this.config.defaultPage], { relativeTo: this.route });
      return newLanguge as TLocale;
    }

    console.error('i18n.sanitizeURL', 'Could not process language and path', pathLanguage);

    return this.locale;

  }

  private async setLocale (): Promise<void> {

    this.locale = 'de';

    // TODO: Inject DOCUMENT
    document.querySelector('html')?.setAttribute('lang', 'de');

    // remember this
    this.storage.set(StorageKey.Locale, this.locale);

    // Use web pack magic string to only include required locale data
    const mappedLocale = this.config.mapperLocale[this.locale] as string;
    const localeModule: Promise<IDefaultStr> = await import(
      /* webpackInclude: /(de)\.mjs$/ */
      `/node_modules/@angular/common/locales/${mappedLocale}.mjs`
    ) as Promise<IDefaultStr>;

    // Set locale for built in pipes, etc.
    registerLocaleData((await localeModule).default);

    try {

      const response = await this.api.get<IAPITokens>('/tokens', { lang: this.locale } ).toPromise();
      this.tokens = response?.data ?? {};

      // search for empty values
      Object.entries(this.tokens).forEach( ([token, value]) => {
        if (String(value).length === 0 || value === null) {
          console.warn('I18N', token, 'not valid', value);
        }
      });

      // console.log('%cI18n %s', 'font-weight: 800', this.locale, Object.keys(this.tokens).length, 'tokens');
      loadTranslations( this.tokens  );

    } catch (error) {
      // goto /notavailable
      console.error('i18n', error)
      loadTranslations( {} );
      this.router.navigate(['/', 'notavailable']);
    }

  }

}
