<!-- EINRICHTUNG + LINK -->
<drv-iconlink icon="arrow-right" [route]="fa?.detailRoute || []">
  <span class="fplus">{{ fa?.einrichtungname }}</span>
</drv-iconlink>

<!-- COLLAPSIBLE OR NOT -->
@if (!expand) {
  <drv-collapsible
    class="drv-mt-sm--01"
    buttonText=""
    [fullWidthStretch]="false"
    [activeStateTemplate]="fascinfo"
    ><ng-container *ngTemplateOutlet="withinCollapsible"></ng-container>
  </drv-collapsible>
}

@if (expand) {
  <ng-container *ngTemplateOutlet="fascinfo"></ng-container>
  <div style="background-color: red;" class="drv-mb-sm--01"></div>
  <ng-container *ngTemplateOutlet="withinCollapsible"></ng-container>
}

<!-- BELOW COLLAPSABLE ALWAYS VISIBLE -->
<!-- QUALITÄT / WARTEZEIT / DURCHFÜHRUNG / ADRESSE -->
<div class="w-100 drv-mt-sm--00 fxrow"  style="row-gap: 8px; column-gap: 8px; flex-flow: wrap">

  <!-- LEFT -->
  <div class="fx50">
    <ng-container [ngTemplateOutlet]="cells[2]"></ng-container>
  </div>

  <!-- RIGHT -->
  <div class="fx50">
    <ng-container [ngTemplateOutlet]="cells[3]"></ng-container>
  </div>

</div>

<!-- FASC INFO -->
<ng-template #fascinfo>
  <div class="nobold lh-110" style="margin-top: 4px">
    {{ fa?.name_ergebnis }}, {{ fa?.spec_ergebnis }}
  </div>
</ng-template>

<!-- ADRESSE -->
<ng-template template-id="adresse">
  <div class="fxcol">
    <div class="nowrap fs-80">Adresse</div>
    <div class="nowrap bold lh-130">{{ fa?.plz }} {{ fa?.ort }}</div>
    <div class="nowrap bold lh-130">{{ fa?.strasse }} {{ fa?.hausnummer }}</div>
  </div>
</ng-template>

<!-- DURCHFÜHRUNG -->
<ng-template template-id="durchfuehrung">
  <div class="fxcol">
    <div class="nowrap fs-80">Durchführung</div>
    <div class="bold lh-130">{{ i18n.translate(fa?.durchfuehrung ?? '') }}</div>
  </div>
</ng-template>

<!-- WARTEZEIT -->
<ng-template template-id="wartezeit">
  <div class="fxcol">
    <div class="nowrap fs-80">{{ 'Wartezeit' }}</div>
    <pr-atom-wartezeit class="bold" [wzinfo]="[fa?.wartezeit, 'short']"></pr-atom-wartezeit>
  </div>
</ng-template>

<!-- QUALITÄT -->
<ng-template template-id="qualitaet">
  <div class="fxcol">
    <div class="fs-80">{{ 'Qualität' }}</div>
    <div class="fxrow fxaistart">
      @if (fa?.qualitaet !== config.no_quality) {
        <pr-atom-quality-circle style="margin-right: 0.25rem; margin-left: -4px; "
          [quality]="fa?.qualitaet" [title]="fa?.isGkv ? trans.title_quality_vg : trans.title_quality_fa"
          [lightVersion]="!!fa?.isGkv" [color]="fa?.isGkv ? '#FFF' : '#446688'" [size]="40">
        </pr-atom-quality-circle>
      }
      @if (fa?.qualitaet !== config.no_quality) {
        <div class="bold lh-130">
          {{ fa?.qualitaet }} <br /> {{ 'Punkte' }}
        </div>
      }
      @if (fa?.qualitaet === config.no_quality) {
        <div class="bold lh-130">
          {{ trans.qualitaet_nodata }}
        </div>
      }
    </div>
  </div>
</ng-template>

<ng-template #withinCollapsible>
  <div class="w-100 drv-mb-sm--01 fxrow" style="row-gap: 8px; column-gap: 8px; flex-flow: wrap">

    <!-- LEFT -->
    <div class="fx50">
      <ng-container [ngTemplateOutlet]="cells[0]"></ng-container>
    </div>

    <!-- RIGHT -->
    <div class="fx50">
      <ng-container [ngTemplateOutlet]="cells[1]"></ng-container>
    </div>

  </div>
</ng-template>
