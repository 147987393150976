import { Component } from '@angular/core';

@Component({
  selector: 'pr-atom-page-title',
  template: `
    <div drv-row>
      <div drv-col [sm]="4">
        <h1 class="drv-title-large drv-mt-sm--01 drv-mb-sm--02">
          <ng-content></ng-content>
        </h1>
      </div>
    </div>
  `,
  styles: []
})
export class PageTitleAtom { }
