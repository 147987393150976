import { Component, OnChanges, Input, Output, EventEmitter, ChangeDetectionStrategy, ViewChild, AfterViewInit } from '@angular/core';

import { I18n } from '@pr/core/i18n.module';
import { IActiveFilter, IFilterConfig } from '@pr/core/interfaces';

import { ConfigService } from '@pr/core/config.service';
import { FilterUmkreisCell } from '../filterumkreis/filterumkreis.cell';

const DEBUG = false;

@Component({
  selector: 'pr-cell-filtercollection',
  templateUrl: './filtercollection.cell.html',
  styleUrls: ['./filtercollection.cell.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterCollectionCell implements OnChanges, AfterViewInit {

  public trans = {
    'link_hilfe':           $localize`:@@page.ergebnis.filters.link:`,
    'filter_delete':        $localize`:@@page.ergebnis.filters.delete:`,
    'plz_label':            $localize`:@@page.ergebnis.filters.plz.label:`,
    'plz_error':            $localize`:@@page.ergebnis.filters.plz.error:`,
    'radius_error':         $localize`:@@page.ergebnis.filters.radius.error:`,
    'plzradius_error':      $localize`:@@page.ergebnis.filters.plzradius.error:`,
    'slider_label':          'Umkreis (in km)',
    'filter_umkreis_label':  'Umkreisusche'  // trans missing
  }

  public hasFilters = false;
  public filterGroups: IFilterConfig[] = [];
  public filterList: string[] = [];

  @Input()  showLoader   = true;
  @Input()  config!: IFilterConfig[];
  @Input()  activeFilter: IActiveFilter | undefined;
  @Output() updateFilter = new EventEmitter<IActiveFilter>();
  @ViewChild(FilterUmkreisCell) compFilterUmkreis!: FilterUmkreisCell;
  
  constructor ( 
    public readonly i18n:  I18n,
    private readonly cfg:  ConfigService,
  ) {}
  
  public ngAfterViewInit () {
    DEBUG && console.log('FilterCollection.viewinit', this.compFilterUmkreis);
  }

  public ngOnChanges (): void {

    this.filterGroups = this.config;

    // update background for Umkreis filter
    const countUmkreisFilter  = (
      this.activeFilter && this.activeFilter.radius && this.activeFilter.plz
        ? 1
        : 0
      )
    ;

    //disable/enable delete all filter button
    const countMerkmalFilter = (
      this.activeFilter === undefined ? 0 :
      this.activeFilter.merkmale.filter( m => !(m === '902')).length
    );
    
    this.hasFilters = ( countUmkreisFilter + countMerkmalFilter ) > 0;

  }

  public onUpdateFilter (event: [string, string]): void {
    
    DEBUG && console.log('FilterCollection.updateFilter.in', event);
    
    const [what, info] = event;

    if (!this.activeFilter) { return; }

    let needsUpdate = true;
    let merkmale: string[] = [];
    let update = Object.assign({}, this.activeFilter ?? {} as IActiveFilter);

    // copy aktiv merkmale
    this.filterGroups.forEach( group => {
      if (group.model.length) {
        group.model.forEach( merkmal => merkmale.push(merkmal) )
      }
    });

    if (what === 'deleteall') {
      merkmale         = [];
      update.merkmale  = [];
      update.plz       = '';
      update.radius    = this.cfg.defaultState.radius;

    } else if (what === 'merkmal') {

      // check for delta
      const fresh = merkmale.sort().join('');
      const last  = this.activeFilter.merkmale.sort().join('');
      needsUpdate = fresh !== last;

    }

    // toggle DeleteAll Button
    this.hasFilters = (this.compFilterUmkreis.selectCount + this.activeFilter.merkmale.length) > 0;

    if (needsUpdate) {
      update = Object.assign(update, { merkmale })

      // AHB Filter ZVWBG2258-168
      // ensure either 901 or 902 are active
      // 900;901;;false;Anschlussrehabilitationen anzeigen
      // 901;902;;true;Nein
      if (update.merkmale.includes('901')){
        // do nothing
      } else {
        // ensure 902
        update.merkmale.push('902');
        // remove duplicates
        update.merkmale = [ ... new Set(update.merkmale) ];
      }

      DEBUG && console.log('FilterCollection.updateFilter', what, JSON.stringify(update, null, 2));
      this.updateFilter.emit(update);

    } else {
      DEBUG && console.log('FilterCollection.updateFilter', 'ignored', what);

    }

  }

}
