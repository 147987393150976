import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, switchMap } from 'rxjs';
import { Params } from '@angular/router';

import { I18n } from '@pr/core/i18n.module';
import { ApiService } from '@pr/core/api.service';
import { StateService } from '@pr/core/state.service';
import { IAPIDetails, IAPIFascs, IFachgruppe, IFasc, INeighbours, IQuality } from '@pr/core/interfaces';

const DEBUG = false;

@Injectable({
  providedIn: 'root'
})
export class DetailService {

  public readonly updateDetails$ = new BehaviorSubject<boolean>(true);

  constructor (
    private readonly state:  StateService,
    private readonly api:    ApiService,
    private readonly i18n:   I18n,
  ) { }

  public results$ (routeParams: Params) {

    return combineLatest([
      this.details$(routeParams),
      this.fachgruppen$,
      this.fascs$,
    ]).pipe(
      map( results => {

        const [ details, fachgruppen, fascs ] = results;

        const suche      = this.state.get<string>('suche');
        const fasc       = fascs[details.fachabteilung?.fasc];
        const fachgruppe = this.findFachgruppe(routeParams['fagr'] ?? '', fachgruppen);
        const backRoute  = suche
          ? ['/', 'de', suche, 'suche' ]
          : ['/', 'de', fachgruppe?.slug, 'indikation' ]
        ;

        DEBUG && console.log('Detail.service.results', details, fachgruppen);

        return [details, fachgruppe, fascs, fasc, backRoute] as [IAPIDetails, IFachgruppe, IAPIFascs, IFasc, string[]];

      })
    );

  }

  private get fachgruppen$ () {
    return this.api.get<IFachgruppe[]>('/fachgruppen').pipe(map( response => response.data ));
  }

  private get fascs$ () {
    return this.api.get<IAPIFascs>('/fascs').pipe(map( response => response.data ));
  }

  private details$ (routeParams: Params) {
    return this.updateDetails$.pipe(
      switchMap( () => {
        const id_fach = routeParams['id'];
        return this.api
          .get<IAPIDetails>('/details', { id_fach })
          .pipe(
            map ( (reponse) => reponse.data ?? {} )
          )
        ;
      })
    );
  }

  public prepareQuality (details: IAPIDetails): IQuality {
    return {
      quality:     details.fachabteilung.qualitaet,
      quality_mw:  details.fachabteilung.qualitaetMw,
      bewertung:   details.bewertung,
      isGkv:       details.fachabteilung.isGkv
    };
  }

  public neighbours (idFach: number): INeighbours | undefined {

    const ergebnis = this.state.readErgebnisResult();

    DEBUG && console.log('Detail.neighbours.length', ergebnis?.fachabteilungen.length);

    if (ergebnis) {

      const clinics = ergebnis.fachabteilungen;
      const length  = clinics ? clinics.length : 0;
      const index   = clinics.findIndex( er => er.idFach === idFach );

      if (index > -1) {
        return {
          curr:  {
            id:    clinics[index - 0].idFach,
            label: clinics[index - 0].einrichtungname,
            slug: ''
          },
          next: {
            id:     index !== length - 1 ? clinics[index + 1].idFach          : undefined,
            label:  index !== length - 1 ? clinics[index + 1].einrichtungname : undefined,
            slug:   index !== length - 1 ? clinics[index + 1].slug : undefined
          },
          prev: {
            id:     index > 0 ? clinics[index - 1].idFach          : undefined,
            label:  index > 0 ? clinics[index - 1].einrichtungname : undefined,
            slug:   index > 0 ? clinics[index - 1].slug : undefined,
          }
        };
      }

    }

    return undefined;

  }

  private findFachgruppe (idFagr: string, data: IFachgruppe[]): IFachgruppe | undefined {

    let fachgruppe: IFachgruppe | undefined;

    if (!idFagr) { return undefined }

    data.forEach( fagr => {
      if (!fachgruppe) {
        if (fagr.id_fagrp === Number(idFagr)) {
          fachgruppe = fagr;
        } else if (fagr.childs) {
          fagr.childs.forEach( child => {
            if (child.id_fagrp === Number(idFagr)) {
              fachgruppe = child;
            }
          })

        }
      }

    })

    return fachgruppe;

  }

}
