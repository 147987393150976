import { Directive, Input, TemplateRef } from "@angular/core";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[template-id]'
})
export class TemplateIdDirective {
  @Input('template-id') public name: string = '';
  constructor(public template: TemplateRef<any>) {}
}
