import { Component } from '@angular/core';

@Component({
  selector: 'pr-atom-logo',
  template: `
    <div drv-row>
      <div drv-col [sm]="{ span: 4, offset: 0}">
        <div
          style="
            background-color: trasnparent;
            height: 170px; width: 300px;
            background-size: 400px 400px;
            background-repeat: no-repeat;
            background-image: url('assets/logos/logo-3.png');
            background-position: -50px 46%;
            background-clip: content-box;
          "
        ></div>
      </div>
    </div>
  `,
  styles: ['']
})
export class LogoAtom { }
