import { BrowserModule, Title  } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule, LOCALE_ID, ErrorHandler, Injector } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, ViewportScroller } from '@angular/common';
import { Router, Scroll, Event, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, pairwise } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { DrvdModule, CONFIG_TOKEN, DEFAULT_CONFIG_PRODUCTIVE } from '@drv-ds/drv-design-system-ng';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { DSModule } from '@pr/core/ds.module';
import { AtomsModule } from '@pr/atoms/atoms.module';
import { CellsModule } from '@pr/cells/cells.module';
import { I18n, II18n } from '@pr/core/i18n.module';
import { DirectivesModule } from '@pr/core/directives/directives.module';

import { ErrorHandlerFactory } from '@pr/core/error.service';
import { unifiedFactory } from "@pr/core/unified.factory";
import { ConfigService } from '@pr/core/config.service';
import { MatomoService } from '@pr/core/matomo.service';
import { NpsTimeOutService } from '@pr/core/nps-time-out.service';

import { BarrierefreiheitPage } from '@pr/pages/barrierefreiheit/barrierefreiheit.page';
import { DatenschutzPage } from '@pr/pages/datenschutz/datenschutz.page';
import { DetailPage } from '@pr/pages/detail/detail.page';
import { ErgebnisPage } from '@pr/pages/ergebnis/ergebnis.page';
import { FeedbackPage } from '@pr/pages/feedback/feedback.page';
import { FooterCell } from '@pr/cells/footer/footer.cell';
import { HeaderCell } from '@pr/cells/header/header.cell';
import { HilfePage } from '@pr/pages/hilfe/hilfe.page';
import { ImpressumPage } from '@pr/pages/impressum/impressum.page';
import { LaborPage } from '@pr/pages/labor/labor.page';
import { NotavailablePage } from '@pr/pages/notavailable/notavailable.page';
import { NotfoundPage } from '@pr/pages/notfound/notfound.page';
import { QualitaetPage } from '@pr/pages/qualitaet/qualitaet.page';
import { ResetPage } from './pages/reset/reset.page';
import { StartPage } from '@pr/pages/start/start.page';
import { ZumantragPage } from '@pr/pages/zumantrag/zumantrag.page';

const DEBUG = false;

const SPRITEPATHS = {
  pictogramSpritePath: ' ',
  spritePath: ' '
}

const CONFIG_PRODUCTIVE = { ...DEFAULT_CONFIG_PRODUCTIVE, ...SPRITEPATHS };

DEBUG && console.log('CONFIG',  CONFIG_TOKEN)
DEBUG && console.log('DEFAULT', CONFIG_PRODUCTIVE)


@NgModule({
  declarations: [
    AppComponent,
    BarrierefreiheitPage,
    DatenschutzPage,
    DetailPage,
    ErgebnisPage,
    FeedbackPage,
    FooterCell,
    HeaderCell,
    HilfePage,
    ImpressumPage,
    LaborPage,
    NotavailablePage,
    NotfoundPage,
    QualitaetPage,
    ResetPage,
    StartPage,
    ZumantragPage,
  ],
  imports: [
    AppRoutingModule,
    AtomsModule,
    BrowserAnimationsModule,
    BrowserModule,
    CellsModule,
    CommonModule,
    DirectivesModule,
    DrvdModule,
    DSModule,
    FormsModule,
    HttpClientModule,
    LeafletMarkerClusterModule,
    LeafletModule,
    ReactiveFormsModule,
  ],
  providers: [

    // page title service
    Title,

    // Cookies fpr matomo
    CookieService,

    // global ErrorHandler (PROD/DEV)
    { provide: ErrorHandler,
      useFactory: ErrorHandlerFactory,
      deps: [ConfigService, Injector]
    },

    // Markdown / Unified
    { provide: 'unified',
      useFactory: unifiedFactory,
    },

    // Design System
    { provide: { ...CONFIG_TOKEN, ...SPRITEPATHS  },
      useValue: CONFIG_PRODUCTIVE,
    },
    { provide: CONFIG_TOKEN,
      useValue: {
        spritePath: '', pictogramSpritePath: ''
      }
    },

    // Lokalisierung
    { provide: APP_INITIALIZER,
      useFactory: (i18n: II18n) => () => i18n.setLocale(),
      deps: [I18n, ActivatedRoute, Router],
      multi: true,
    },
    { provide: LOCALE_ID,
      useFactory: (i18n: I18n) => i18n.locale,
      deps: [I18n, ActivatedRoute, Router],
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  // Discussion: https://github.com/angular/angular/issues/26744
  // https://github.com/angular/angular/issues/10929
  // Solution: https://gist.github.com/iffa/9c820072135d25a6372d58075fe264dd

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private matomo: MatomoService,
    private npsTimeOutService: NpsTimeOutService,
  ) {

    // Disable automatic scroll restoration to avoid race conditions
    this.viewportScroller.setHistoryScrollRestoration('manual');
    this.handleScrollOnNavigation();

    // init NPS Timeout
    // https://jira.service.zd.drv/browse/ZVWBG2258-460
    this.npsTimeOutService.init(this.router);

    // TODO: might fit better in newer app.service
    this.matomo.activate();

  }

  /**
   * When route is changed, Angular interprets a simple query params change as "forward navigation" too.
   * Using the pairwise function allows us to have both the previous and current router events, which we can
   * use to effectively compare the two navigation events and see if they actually change route, or only
   * the route parameters (i.e. selections stored in query params).
   */
  private handleScrollOnNavigation(): void {

    // return url without query params
    function getBaseRoute (url: string): string {
      return (url || '').split('?')[0];
    }

    this.router.events.pipe(
      filter((e: Event): e is Scroll => e instanceof Scroll),
      pairwise()
      // TODO: Ang 16
    ).subscribe((events: any) => {

      const [previous, current] = events;

      if (current.position) {
        // Backward navigation
        this.viewportScroller.scrollToPosition(current.position);

      } else if (current.anchor) {
        // Anchor navigation
        this.viewportScroller.scrollToAnchor(current.anchor);

      } else {
        // Check if routes match, or if it is only a query param change
        const prevUrl = getBaseRoute((previous.routerEvent as NavigationEnd).urlAfterRedirects);
        const thisUrl = getBaseRoute((current.routerEvent as NavigationEnd).urlAfterRedirects);

        if (prevUrl !== thisUrl) {
          // Routes don't match, this is actual forward navigation
          // Default behavior: scroll to top
          this.viewportScroller.scrollToPosition([0, 0]);
          DEBUG && console.log('App.module.handleScrollOnNavigation', 'scrolled up')

        }
      }

    });
  }

}
