import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ConfigService } from '@pr/core/config.service';
import { INode } from '@pr/core/interfaces';

@Component({
  selector: '[inline]',
  templateUrl: './inline.cell.html',
  styles: [],
  encapsulation: ViewEncapsulation.None,
})
export class InlineCell {

  constructor (
    private readonly config: ConfigService,
  ) {}

  @Input('inline') node: INode  | undefined;

  // AOT safe children from the node
  get children() {
    return this.node?.children;
  }

  public linktype(url: string | undefined): 'extern' | 'intern' | 'email' | 'subpage' {
    url = (url ?? '').split('#').filter(Boolean)[0];
    return (
      (url ? url : '').startsWith('mailto') ? 'email' :
      (url ? url : '').startsWith('./')     ? 'subpage' :
      this.config.pages.includes(url ?? '') ? 'intern' :
      'extern'
    );
  }

  public isInternal(url: string | undefined): boolean {
    return url ? this.config.pages.includes(url) : false;
  }

  public getFragment(page: string | undefined): string {
    return (page ?? '').split('#').filter(Boolean)[1];
  }

  public makeInternal(page: string | undefined): string[] {
    page = (page ?? '').split('#').filter(Boolean)[0];
    if (page) {
      return ['/', 'de', page];
    } else {
      return [];
    }
  }

}
