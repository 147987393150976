import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'pr-cell-quality-tile',
  templateUrl: './quality-tile.cell.html',
  styleUrl: './quality-tile.cell.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QualityTileCell {

  
  public trans = {
    "header":       $localize`:@@page.ergebnis.quality-tile.h2:`,
    "tile2_header": $localize`:@@page.ergebnis.quality-tile.header:`,
    "tile2_items":  [
                      $localize`:@@page.ergebnis.quality-tile.absatz1:`,
                      $localize`:@@page.ergebnis.quality-tile.absatz2:`
                    ]
  }
  
}
