import { Component, Input } from '@angular/core';

import { IFeature } from '@pr/core/interfaces';

@Component({
  selector: 'pr-atom-featurelist',
  templateUrl: './featurelist.atom.html',
  styleUrls: ['./featurelist.atom.scss']
})
export class FeaturelistAtom {

  @Input() data!: IFeature[] | undefined; // decorate the property with @Input()

}
