<!-- ARIA DESCRIPTION -->
<p id="description" class="dn" i18n="@@app.table.accessibility.hint">app.table.accessibility.hint</p>

<!-- MOBILE TABLE CONTAINER -->
<div *pr-show="['ts', 'sm']">
  @if (pagination.rows) {
    <div>
      <!-- PAGINATION INFO -->
      <div class="w-100 drv-mt-sm--01 drv-mb-sm--01">
        {{ pagination.info }}
      </div>
      <!-- MOBILE TABLE -->
      @for (fa of getActivePageRows(); track fa) {
        <pr-atom-mobile-table-row
          [fa]="fa"
          [sort]="state.get('sort')"
          [expand]="true"
        ></pr-atom-mobile-table-row>
      }
      <!-- MOBILE TABLE FOOTER -->
      <div class="table-footer">
        <drv-dropdown
          id="drv-dropdown-rows"
          name="drv-dropdown-rows"
          label=""
          [required]="true"
          [ngModel]="selected.sort"
          (ngModelChange)="onSelectRowsPerPage($event, true)"
          [options]="pageSizes"
          >
        </drv-dropdown>
        <div class="fxshrink h-100" style="flex: 1px"></div>
        <drv-pagination
          style="border-left: 2px solid #b4c1d0;"
          variant="compact-no-line"
          paginationLabel="Paginierung"
          [pageText]="trans.page_text"
          [prevPageText]="trans.previous_page"
          [nextPageText]="trans.next_page"
          [pageMax]="pagination.maxPage"
          [currentPage]="pagination.activePage"
          (onSelect)="onSelectActivePage($event, true, true)"
        ></drv-pagination>
      </div>
    </div>
  }
</div>

<!-- DESKTOP TABLE CONTAINER -->
<div *pr-hide="['ts', 'sm']" class="lg-table-container">
  @if (pagination.rows) {
    <div>
      <!-- DESKTOP TABLE -->
      <drv-table
        caption=""
        class="sticky-header"
        variant="condensed"
        cellAlignmentVertical="middle"
        [hideCaption]="true"
        [sortable]="true"
        [stickyHead]="true"
        [data]="tableHeader"
        (onSort)="onSort($event)"
        >
      <tbody>
        @for (fa of getActivePageRows(); track fa) {
          <pr-atom-desktop-table-row
            [fa]="fa"
            (rowclick)="onRowClick($event)"
          ></pr-atom-desktop-table-row>
        }
      </tbody>
    </drv-table>
    <!-- DESKTOP TABLE FOOTER -->
    <div class="table-footer">
      <drv-dropdown
        id="drv-dropdown-rows"
        name="drv-dropdown-rows"
        style="max-width: 140px;"
        label=""
        [required]="true"
        [ngModel]="selected.rowsperpage"
        (ngModelChange)="onSelectRowsPerPage($event, true)"
        [options]="pageSizes"
        >
      </drv-dropdown>
      <div class="fxshrink fxgrow h-100" style="flex: 1px; height: 32px; border-right: 2px solid #6983a1;"></div>
      <drv-pagination
        style="max-width: 400px;"
        paginationLabel="Paginierung"
        [pageText]="trans.page_text"
        [prevPageText]="trans.previous_page"
        [nextPageText]="trans.next_page"
        [pageMax]="pagination.maxPage"
        [currentPage]="pagination.activePage"
        (onSelect)="onSelectActivePage($event, true, true)"
      ></drv-pagination>
    </div>
  </div>
}
</div>
