import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AppService } from './app.service';
import { ConfigService } from './core/config.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnDestroy {

  public readonly notificationId = 'prAppNotification';
  private subscriber$: Subscription | undefined;

  constructor (
    public readonly service: AppService,
    public readonly config: ConfigService,
  ) {
    this.service.initialize();
  }

  ngOnDestroy(): void {
    this.subscriber$?.unsubscribe();
    this.service.destroy();
  }

}
