import { ChangeDetectorRef, Component } from '@angular/core';
import { map } from 'rxjs';
import { Map } from 'leaflet';
import { NotificationService } from '@drv-ds/drv-design-system-ng';

import { IAPIErgebnis, IAPIFascs, IQuality, IAPIMarker, IAPIResponse, IErgFachabteilung, IMapHomeFeature, TMapEvent, IErgebnisResults } from '@pr/core/interfaces';
import fascsJson from '../../../assets/api/json/pr.fasc.de.json';
import { ApiService } from '@pr/core/api.service';
import { I18n } from '@pr/core/i18n.module';
import { StateService } from '@pr/core/state.service';

const imports: Record<string, IAPIResponse<unknown>> = {
  '/fascs':       fascsJson as IAPIResponse<IAPIFascs>,
}


@Component({
  selector: 'pr-page-labor',
  templateUrl: './labor.page.html',
  styleUrls: ['./labor.page.scss']
})
export class LaborPage {

  public response: unknown;
  public mapInfo = '';
  public markerInfo = '';
  public mapHomeDefault: IMapHomeFeature = {
    plz:    '12347',
    lat:    52.4545967334104, 
    lon:    13.4335618050316,
    radius: 100,
  }
  public mapHome: IMapHomeFeature | undefined;

  public counter = 0;
  public now = new Date();

  public dataString = '';

  public laborTitle: string = $localize`:@@app.labor.title:`;
  
  public mapTreffer = 0;
  public mapShowTable = true;
  public mapMarker: undefined | IAPIMarker = undefined;
  // public mapClusterMarker: undefined | IErgFachabteilung[] = undefined;
  public mapClusterMarker: undefined | IErgebnisResults = undefined;

  constructor (
    public readonly i18n: I18n,
    private readonly notification: NotificationService,
    private readonly api: ApiService,
    public readonly state: StateService,
    private readonly cd: ChangeDetectorRef,
  ) {
    // do nothing
    
    this.mapLoadClusterMarker();
    
  }

  changeCounter (amount: number): void {
    this.counter += amount;
    this.counter = this.counter < 0 ? 0 : this.counter;
  }

  triggerError (what: string): void {
    if (what === "error") {
      throw "That went wrong"
    } else if (what === 'apierror') {
      this.api.get('/labor', { lang: 'de' }).subscribe( stuff => {
        console.log('Labor.triggerError', stuff);
        this.response = stuff;
      })
    } else {
      console.log('wtf')
    }

  }

  showError (what: string): void {

    if (what === "error") {
      // throw "That went wrong"
      this.notification.update({
        id:'prAppNotification',
        variant: 'alert',
        title: 'Something bad happened',
        text: 'Push and then leave the building',
      })

    } else if ( what === 'warning') {
      this.notification.update({
        id:'prAppNotification',
        variant: 'success',
        title: 'You succeeded',
        text: 'Fetch some Dollars',
      });

    } else {
      this.notification.clear()
    }
  }

  onCollapsibleClick (event: Event) {
    console.log('onCollapsibleClick');
  }
  
  mapReset () {
    this.mapMarker = {
      marker: []
    };
    // this.mapClusterMarker = [];
    // map.flyTo([lat, lng], zoom);
    this.cd.detectChanges();
  }
  
  
  mapToggleTable () {
    this.mapShowTable = !this.mapShowTable;
  }
  
  mapToggleHome () {
    this.mapHome = this.mapHome === undefined ? this.mapHomeDefault : undefined;
  }
  
  mapShowTestMarker () {
    // lat:    52.4545967334104, 
    // lon:    13.4335618050316,
    
    
    const markers = [
      { lat: 52, lon: 13 },
      { lat: 52.1, lon: 13.1 },
      { lat: 52.11, lon: 13.11 },
      { lat: 52.111, lon: 13.111 },
      { lat: 52.1111, lon: 13.1111 },
      { lat: 52.11111, lon: 13.11111 },
      { lat: 52.111111, lon: 13.111111 },
      { lat: 52.1111111, lon: 13.1111111 },
      { lat: 52.11111111, lon: 13.11111111 },
      { lat: 52.111111111, lon: 13.111111111 },
    ];
    
    this.mapMarker = {
      marker: markers
    }
    
  }
  
  mapLoadMarker () { 
    this.api
      .get<IAPIMarker>('/marker')
      .pipe(map( response => response.data ))
      .subscribe( data => {
        data.marker = data.marker.slice(0, 100);
        this.mapMarker = data;
        this.cd.detectChanges();
        console.log('Labor.mapLoadMarker.data', this.mapMarker.marker.length);
      })
    ;
  }
  
  mapLoadClusterMarker () {
    
    const amount = 20000;
    const fascs = imports['/fascs'].data as IAPIFascs;
    
    this.api
      .get<IAPIErgebnis>('/ergebnisse')
      .pipe(map( response => response.data ))
      .subscribe( data => {
        const t0 = Date.now();
        console.log('Labor.mapLoadClusterMarker.in', data.fachabteilungen.slice(0, amount).length);
        // this.mapClusterMarker = data.fachabteilungen.slice(0, amount).map( fa => {
        //   fa.name_ergebnis = (fascsJson['data'] as IAPIFascs)[fa.fasc].name_ergebnis;
        //   fa.spec_ergebnis = (fascsJson['data'] as IAPIFascs)[fa.fasc].spec_ergebnis;
        //   fa.detailRoute   = ['/', 'de', fa.slug, String(fa.idFach), '0', 'details'];
        //   fa.wz_thresholds = fascs[fa.fasc].wartezeiten;
        //   return fa;
        // });
        this.cd.detectChanges();
        console.log('Labor.mapLoadClusterMarker.out', Date.now() - t0, 'ms');
      })
    ;
    
  }
  
  mapBoundingRect () {}
  
  mapEventHandler (mapEvent: TMapEvent) {
    
    if (mapEvent.type === 'mapready') {
      mapEvent.map && this.updateMapInfo(mapEvent.map);
      return;
      
    } else if (mapEvent.type === 'clusterready') {
      mapEvent.map && this.updateMapInfo(mapEvent.map);
      return;
      
    } else if (mapEvent.type === 'moveend') {
      false && console.log('Labor.mapEvents', mapEvent.payload.hits, 'hits');
      this.mapTreffer = mapEvent.payload.hits ?? 0; 
      mapEvent.map && this.updateMapInfo(mapEvent.map);
      this.cd.detectChanges();
      return;
      
    } else if (mapEvent.type === 'zoomend') {
      false && console.log('Labor.mapEvents', mapEvent.payload.hits, 'hits');
      this.mapTreffer = mapEvent.payload.hits ?? 0; 
      mapEvent.map && this.updateMapInfo(mapEvent.map);
      this.cd.detectChanges();
      return;
      
    } else if (mapEvent.type === 'markerclick') {
      mapEvent.map && this.updateMapInfo(mapEvent.map);
      return;
      
    } else if (mapEvent.type === 'rowclick') {
      console.log('Labor.mapEvents', mapEvent.payload.id, 'eid');
      mapEvent.map && this.updateMapInfo(mapEvent.map);
      return;
      
    // } else if (mapEvent.type === 'markerclick') {
    //   mapEvent.map && this.updateMarkerInfo(mapEvent);
      
    }
    
    console.log('mapEventHandler', mapEvent.type, mapEvent.payload)
    
  }
  
  updateMapInfo (m: Map) {
    const ll   = m.getCenter();
    const zoom = m.getZoom()
    this.mapInfo = `Zoom: ${zoom} Lat: ${ll.lat} Lng: ${ll.lng}`
  }
  
  updateMarkerInfo (e:TMapEvent) {
    this.markerInfo = JSON.stringify(e);
  }
  
  labor_quality (): IQuality {
    return {
      isGkv:       false,
      quality:     88, // details.fachabteilung.qualitaet,
      quality_mw:  77, //details.fachabteilung.qualitaetMw,
      bewertung:   [{"info":"Dieser Wert fasst den Erfolg der Rehabilitation aus Sicht der Rehabilitand*innen zusammen (Ergebnis der Rehabilitand*innenbefragung).","label":"Subjektiver Behandlungserfolg","mwQs":73,"neutralerWert":false,"qs":77,"sort":10},{"info":"Dieser Wert bildet die therapeutische Versorgung anhand der Leistungsdauer, -menge und -verteilung während einer Rehabilitation ab.","label":"Therapeutische Versorgung","mwQs":91,"neutralerWert":false,"qs":94,"sort":40},{"info":"Dieser Wert zeigt an, wie die Behandlung der Rehabilitand*innen in dieser Fachabteilung von leitenden Ärzt*innen anderer Reha-Fachabteilungen bewertet wurde. Dazu wurden anonymisierte Entlassungsberichte hinsichtlich aller für eine Rehabilitation wichtigen Bereiche ausgewertet.","label":"Peer Review","mwQs":77,"neutralerWert":false,"qs":87,"sort":50},{"info":"Dieser Wert fasst zusammen, inwieweit die vorgegeben Anforderungen bei ausgewählten Krankheitsbildern eingehalten wurden.","label":"Reha-Therapiestandards","mwQs":95,"neutralerWert":false,"qs":95,"sort":30},{"info":"Dieser Wert fasst die Zufriedenheit der Rehabilitand*innen mit dem Leistungsangebot der Einrichtung, der Organisation der Abläufe etc. zusammen (Ergebnis der Rehabilitand*innenbefragung).","label":"Zufriedenheit","mwQs":76,"neutralerWert":false,"qs":77,"sort":20}]
    };
    
  }

}
