<div class="page page-qualitaet drv-mt-sm--01">

  <pr-cell-header-bar>
    <drv-button
      class="back"
      style="margin-left: -1rem"
      [label]="trans.back_start_page"
      kind="tertiary"
      type="anchor"
      iconPosition="left"
      iconType="arrow-left"
      backgroundUI="light"
      [route]="['/', 'de']"
    ></drv-button>
  </pr-cell-header-bar>

  <!-- LOGO -->
  <!-- <pr-atom-logo></pr-atom-logo> -->

  <!-- CLAIM -->
  <pr-atom-claim></pr-atom-claim>

  <!-- TITLE -->
  <pr-atom-page-title  i18n="@@page.qualitaet.title.long">
    page.qualitaet.title.long
  </pr-atom-page-title>

  <!-- HEADER 3 -->
  <div drv-row>
    <div drv-col [sm]="4" [md]="{ span: 10, offset: 0 }">

      <!-- <h2 i18n="@@page.qualitaet.messung.header" class="drv-mt-sm--03"> -->
      <h2 i18n="@@page.qualitaet.messung.header">
        page.qualitaet.messung.header
      </h2>

      <div class="drv-ml-sm--02 drv-mt-sm--02 drv-mb-sm--02 quote-01">
        <drv-list type="check" [content]="[
          trans.list_messung_1,
          trans.list_messung_2,
          trans.list_messung_3,
        ]" ></drv-list>        
      </div>

      <p i18n="@@page.qualitaet.messung.line">
        page.qualitaet.messung.line
      </p>

    </div>
  </div>

  <!-- GESAMTNOE -->
  <div drv-row class="drv-mt-sm--04">
    <div drv-col [sm]="4" [md]="{ span: 10, offset: 0 }">

      <h2 i18n="@@page.qualitaet.verfahren.header" class=" drv-mb-sm--02">
        page.qualitaet.verfahren.header
      </h2>

      <p i18n="@@page.qualitaet.verfahren.text">
        page.qualitaet.verfahren.text
      </p>

    </div>
  </div>

  <!-- UNSERE VERFAHREN - HEADER 3 -->
  <div drv-row class="drv-mt-sm--04">
    <div drv-col [sm]="4">
      <h2 i18n="@@page.qualitaet.bild.header">
        page.qualitaet.bild.header
      </h2>
    </div>
  </div>

  <!-- IMAGE -->
  <div drv-row class="drv-mt-sm--01">
    <div drv-col [sm]="{ span: 4, offset: 0 }" style="padding-top: 6px">

      <a class="pmb0" href="assets/images/quality/qualitaetsparamter-schaubild.png" target="_blank" [attr.alt]="trans.link_alttext">
        <img class="pmb0" src="assets/images/quality/qualitaetsparamter-schaubild.png" width="2547" height="1042" [attr.alt]="trans.img_alttext"/>
      </a>

    </div>
  </div>

  <!-- FAQ Header -->
  <div drv-row class="drv-mt-sm--04">
    <div drv-col [sm]="4">
      <h2 i18n="@@page.qualitaet.faq.header">
        page.qualitaet.faq.header
      </h2>
    </div>
  </div>


  <!-- FAQs -->
  <div drv-row class="drv-mt-sm--02">
    <div drv-col [sm]="4" [md]="{ span: 8, offset: 0 }">

      <drv-accordion>
        @for (item of itemsFAQ; track item) {
          <drv-accordionitem title="{{ item.title }}">
            {{ item.text }}
            @if (item.href) {
              <drv-iconlink
                target="_blank"
                icon="arrow-external"
                href="{{ item.href }}"
              >{{ item.label }}</drv-iconlink>
            }
          </drv-accordionitem>
        }
      </drv-accordion>

    </div>
  </div>

  <!-- MARKDOWN -->
  <div drv-row class="drv-mt-sm--02 drv-mb-sm--02">
    <div drv-col [sm]="4" [md]="{ span: 8, offset: 0 }">
      @if (markdown) {
        <cell-markdown
          [rawmarkdown]="markdown"
        ></cell-markdown>
      }
    </div>
  </div>

</div>
