
@if (showRowSelector) {
  <div class="spacer"></div>
}

<drv-pagination
  [class.lonely] = "!showRowSelector"
  paginationLabel="Paginierung"
  [pageText]="trans.page_text"
  [prevPageText]="trans.previous_page"
  [nextPageText]="trans.next_page"
  [pageMax]="pagination?.maxPage ?? 0"
  [currentPage]="pagination?.activePage ?? 0"
  (onSelect)="onSelectActivePage($event, true, true)"
></drv-pagination>
