import { Component } from '@angular/core';
import { TrefferCountService} from './treffer-count.service';

@Component({
  selector: 'pr-cell-treffer-count',
  template: `
    <h2 class="bold nowrap" aria-live="polite" data-e2e="treffer">
      {{ (svc.info$ | async)?.treffer }}
    </h2>
    <div>
      {{ (svc.info$ | async)?.lines }}
    </div>
  `,
  styles: ['']
})
export class TrefferCountCell {
  
  constructor ( public svc: TrefferCountService) {}

}
