<div #mycontainer drv-row>

  <p id="description" class="dn" i18n="@@app.table.accessibility.hint">app.table.accessibility.hint</p>

  <drv-table drv-col #mytable *pr-hide="['ts', 'sm']"
    class="tableheight drv-mb-sm--04"
    cellAlignmentVertical="middle"
    variant="condensed"
    caption = ""
    [hideCaption] = "true"
    [sm]="{ span: 4, offset: 0}"
    [md]="{ span: 4, offset: 0}"
    >
    <tbody>
      @for (item of results?.fachabteilungen; track item) {
        <tr
          tabindex="0"
          class="pointer"
          aria-describedby="description"
          attr.data-idfach="{{ item.idFach }}"
          (click)="onRowClick(item.idFach)"
          (keyup.enter)="onRowClick(item.idFach)"
          [ngClass]="{ 'selected': item.idFach === selected.idfach }"
          >
          <td style="padding: 0 4px 0 8px; width: 60px">
            @if (item.qualitaet !== config.no_quality) {
              <pr-atom-quality-circle
                [quality]="item.qualitaet"
                [title]="item.isGkv ? trans.title_quality_vg : trans.title_quality_fa"
                [color]="item.isGkv ? '#FFF' : '#446688'"
                [lightVersion]="item.isGkv">
              </pr-atom-quality-circle>
            }
            @if (item.qualitaet === config.no_quality) {
              <span class="bold">{{ trans.qualitaet_nodata }}</span>
            }
          </td>
          <td style="padding: 8px 4px 8px 4px;">
            <div class="bold lh-130">{{ item.einrichtungname }}</div>
            <div class="fminus lh-130">{{ item.plz }} {{ item.ort }}</div>
            <div class="fminus lh-130">{{ item.strasse }} {{ item.hausnummer }}</div>
            @if (results?.showFascName) {
              <div class="fasc-name">
                {{ item.name_ergebnis }}, {{ item.spec_ergebnis }} · {{ item.durchfuehrung }}
              </div>
            }
          </td>
        </tr>
      }
    </tbody>
  </drv-table>

  <div
    drv-col
    class="cellheight"
    [sm]="{ span: 4, offset: 0 }"
    [md]="{ span: 8, offset: 0 }"
    tabindex="0"
    [attr.aria-label]="trans.accessibility_hint"
    >
    <div #leafletMap leaflet
      class="cellheight"
      aria-hidden="true"
      [leafletOptions]="leafletOptions"
      [leafletLayersControl]="leafletLayersControl"
      [leafletLayers]="leafletFeatures"
      [leafletFitBoundsOptions]="{ maxZoom: 12, animate: false }"
      (leafletMapReady)="onMapReady($event)"
    ></div>
  </div>

</div>
