import { Component } from '@angular/core';

@Component({
  selector: 'pr-cell-legend-quality',
  templateUrl: './legend-quality.cell.html',
  styleUrl: './legend-quality.cell.scss'
})
export class LegendQualityCell {

  public trans = {
    "fachabteilung":    $localize`:@@page.ergebnis.quality.legende.fachabteilung.label:`,
    "vergleichsgruppe": $localize`:@@page.ergebnis.quality.legende.vergleichsgruppe.label:`,
  }

}

// "de";"page.ergebnis.quality.legende.fachabteilung.label";"Qualitätswert der Fachabteilung"
// "de";"page.ergebnis.quality.legende.vergleichsgruppe.label";"Mittelwert einer Vergleichsgruppe von Fachabteilungen mit ähnlichem Behandlungsschwerpunkt"
