import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { DrvDropdownOption } from '@drv-ds/drv-design-system-ng';

import { ConfigService } from '@pr/core/config.service';
import { IPagination } from '@pr/core/interfaces';
import { StateService } from '@pr/core/state.service';
import { TrefferCountService } from '@pr/cells/treffer-count/treffer-count.service';
import { DOCUMENT } from '@angular/common';

const DEBUG = false;

@Component({
  selector: 'pr-cell-table-pagination',
  templateUrl: './table-pagination.cell.html',
  styleUrls: ['./table-pagination.cell.scss']
})
export class TablePaginationCell {

  private dataRows = 0;
  public pageSizes: DrvDropdownOption[] = [];
  public pagination: IPagination | undefined = undefined;

  public readonly trans = {
    "previous_page":         $localize`:@@page.ergebnis.pagination.previous.page:`,
    "next_page":             $localize`:@@page.ergebnis.pagination.next.page:`,
    "page_text":             $localize`:@@page.ergebnis.pagination.page:`,
    "zeilen":                $localize`:@@page.ergebnis.pagination.lines:`,
    "items":                 $localize`:@@page.ergebnis.pagination.items:`,
    "of":                    $localize`:@@page.ergebnis.pagination.of:`,
  };

  @Output() events: EventEmitter<IPagination> = new EventEmitter<IPagination>();
  @Input() showRowSelector = true;

  constructor (
    public readonly state: StateService,
    public readonly config: ConfigService,
    private readonly svcTreffer: TrefferCountService,
    @Inject(DOCUMENT) private readonly document: Document,

  ) {

    // translate pageSizes for dropdown
    this.pageSizes = this.config.pageSizes.map((item: DrvDropdownOption) => ({
      label: item.label?.replace('%s', this.trans.zeilen),
      value: String(item.value)
    }));

  }

  public calulate (maxRows: number): IPagination {

    const rowsperpage = parseInt(this.state.get<string>('rowsperpage'), 10);
    this.dataRows = maxRows;

    this.pagination   = Object.assign({}, this.pagination, {
      rowsperpage,
      first:        0,
      last:         Math.min(rowsperpage, maxRows) -1,
      activePage:   1,
      maxPage:      Math.ceil(maxRows / rowsperpage),
    });

    if (this.state.get('view') === 'karte') {
      this.svcTreffer.message({ treffer: maxRows, lines: [this.pagination.first +1, this.pagination.last +1]});
    }

    return this.pagination;


  }

  public onSelectRowsPerPage (event: [string], scrollTop=false) {

    if (event[0] !== this.state.get<string>('rowsperpage')) {

      const rowsperpage = parseInt(event[0], 10);

      this.pagination   = Object.assign({}, this.pagination, {
        rowsperpage,
        first:        0,
        last:         Math.min(rowsperpage, this.dataRows) -1,
        activePage:   1,
        maxPage:      Math.ceil(this.dataRows / rowsperpage),
      });

      if (this.state.get('view') === 'karte') {
        this.svcTreffer.message({ treffer: this.dataRows, lines: [this.pagination.first +1, this.pagination.last +1]});
      }

      DEBUG && console.log('Pagination.onSelectRowsPerPage', this.pagination);

      this.state.update({ rowsperpage: String(rowsperpage)});
      this.events.emit(this.pagination);

      setTimeout( () => {
        scrollTop && this.document.querySelector('h1')
        // ?.scrollIntoView({ behavior: "smooth" });
        ?.scrollIntoView();
      }, 200);
    }

  }

  public onSelectActivePage (activepage: any, detectChanges=true, scrollTop=false) {

    const activePage  = parseInt(activepage, 10);
    const rows        = this.dataRows;
    const rowsperpage = parseInt(this.state.get<string>('rowsperpage'), 10);
    const first       = (activePage -1) * rowsperpage;
    const last        = Math.min(rows, first + rowsperpage) -1;
    const maxPage     = Math.ceil(rows / rowsperpage);

    this.pagination   = Object.assign({}, this.pagination, {
      rows, rowsperpage, activePage, first, last, maxPage,
    });

    // now add UI infos
    if (this.state.get('view') === 'karte') {
      this.svcTreffer.message({ treffer: this.dataRows, lines: [this.pagination.first +1, this.pagination.last +1]});
    }

    DEBUG && console.log('Pagination.onSelectActivePage', this.pagination);

    this.state.update({ activepage: String(activepage) });
    this.events.emit(this.pagination);

    // scrollTop && this.document.querySelector('h1')?.scrollIntoView({ behavior: "smooth" });
    scrollTop && this.document.querySelector('h1')?.scrollIntoView();


  }

}
