import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';

import { FormItemMessage, DrvDropdownOption } from '@drv-ds/drv-design-system-ng';

import { IDrvComboOptions, IEVENTSearchIndikation, IEVENTSearchWord, ISelFachabteilung, ISelSchwerpunkt } from '@pr/core/interfaces';
import { IAPIFachgruppen, IFachgruppe } from '@pr/core/interfaces.api';
import { ConfigService } from '@pr/core/config.service';
import { HelperService } from '@pr/core/helper.service';
import { StateService } from '@pr/core/state.service';
import { I18n } from '@pr/core/i18n.module';

const DEBUG = false;

@Component({
  selector: 'pr-cell-search',
  templateUrl: './search.cell.html',
  styleUrls: ['./search.cell.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchCell implements AfterViewInit {

  public trans = {

    search_suche_header_1:            $localize`:@@page.start.search.suche.header:`,
    search_suche_button:              $localize`:@@page.start.search.suche.button:`,
    search_suche_placeholder:         $localize`:@@page.start.search.suche.placeholder:`,
    search_suche_tooltip:             $localize`:@@page.start.search.suche.tooltip:`,

    search_indikation_header_1:       $localize`:@@page.start.search.indikation.1.header:`,
    search_indikation_placeholder_1:  $localize`:@@page.start.search.indikation.1.placeholder:`,
    search_indikation_tooltip_1:      $localize`:@@page.start.search.indikation.1.tooltip:`,
    search_indikation_header_2:       $localize`:@@page.start.search.indikation.2.header:`,
    search_indikation_placeholder_2:  $localize`:@@page.start.search.indikation.2.placeholder:`,
    search_indikation_tooltip_2:      $localize`:@@page.start.search.indikation.2.tooltip:`,
    search_indikation_button:         $localize`:@@page.start.search.indikation.button:`,

    search_klinik_hint:               $localize`:@@page.start.search.klinik.hint:`,

    kliniksuche_link:                 $localize`:@@page.start.kliniksuche.link:`,
    kliniksuche_fragment:             $localize`:@@page.start.kliniksuche.link.fragment:`,

    acc1_title:                       $localize`:@@page.start.search.klinik.title:`,
    acc2_title:                       $localize`:@@page.start.search.indikation.title:`,

  };

  public searchDisabled = true;
  public data: IAPIFachgruppen = [];
  public fachabteilungen: Array<{ label: string, value: string, childs: IFachgruppe[] | undefined }> = [];
  public schwerpunkte:    IDrvComboOptions = [];
  public suche = {
    hasHints: false,
    hints: [] as FormItemMessage[],
  }
  
  public selected = {
    fachabteilung: [],
    schwerpunkt:   []
  } as Record<'fachabteilung' | 'schwerpunkt', DrvDropdownOption[] | []>;

  @Input() public set fachgruppen (data: IAPIFachgruppen) {
    this.data = data;
    // transforms top level entries for first combobox
    this.fachabteilungen = this.data
      .sort( (a, b) => a.sort - b.sort )
      .map( fagrp => ({
        label:  fagrp.name,
        value:  String(fagrp.id_fagrp),
        slug:   fagrp.slug,
        childs: fagrp.childs
      }) )
    ;
  }
  @Input() set hints (hints: FormItemMessage[]) {
    this.suche.hasHints = !!hints.length;
    this.suche.hints    = hints.map( m => ({ message: m.message }));
    this.hideHint       = !this.suche.hasHints;
  };

  @Output() searchByString: EventEmitter<IEVENTSearchWord> = new EventEmitter<IEVENTSearchWord>();
  @Output() searchByIndikation: EventEmitter<IEVENTSearchIndikation> = new EventEmitter<IEVENTSearchIndikation>();
  @HostBinding('class.hideHint') public hideHint: boolean = true;

  constructor (
    public  readonly i18n:   I18n,
    public readonly state:   StateService,
    public readonly config:  ConfigService,
    public readonly helper:  HelperService,
  ) { }

  ngAfterViewInit (): void {
    
    window.setTimeout( () => {
      this.update();
    }, 100);
    
  }

  public update () {
    
    const fa = this.state.get<ISelFachabteilung>('fachabteilung');
    const sw = this.state.get<ISelFachabteilung>('schwerpunkt');
    
    const labelFa: string = this.findFagr(fa[0])?.name ?? 'unknown';
    const labelSw: string = this.findFagr(sw[0])?.name ?? 'unknown';
    
    const fachabteilung = fa.length
      ? [{ value: fa[0], label: labelFa}]
      : []
    ;
    
    const schwerpunkt = sw.length
      ? [{ value: sw[0], label: labelSw}]
      : []
    ;
    
    this.selected = Object.assign( {}, this.selected, {
      fachabteilung,
      schwerpunkt
    });

    DEBUG && console.log('Search.update', this.selected);
    
    this.updateSchwerpunkte();
    this.updateSearchButton();

  }

  public onSelect ( what: 'fachabteilung' | 'schwerpunkt', event: any ): void {

    const option        = event[0];
    const fachabteilung = this.fachabteilungen.find( fa => fa.value === option.value );
    const schwerpunkt   = this.schwerpunkte.find(    sw => sw.value === option.value );

    DEBUG && console.log('Search.onSelect.in', what, 'fa', fachabteilung?.label, 'sw', schwerpunkt?.label);
    
    if (what === 'fachabteilung') {
      this.state.update({
        fachabteilung: [fachabteilung?.value ?? 'unknown'],
        schwerpunkt:   [],
      });

    } else if (what === 'schwerpunkt') {
      this.state.update( { schwerpunkt: [String(schwerpunkt?.value)] })

    } else {
      console.warn('Search.onSelect', 'unknown what', what);

    }

    this.update();

  }

  private updateSearchButton (): void {

    const schwerpunkt   = this.state.get<ISelSchwerpunkt>('schwerpunkt');
    const fachabteilung = this.state.get<ISelFachabteilung>('fachabteilung');

    // disables search buttton, if form not completed
    this.searchDisabled = !(
       (this.schwerpunkte.length     && !schwerpunkt.length) ? false :
      !((this.fachabteilungen.length && !fachabteilung.length))
    );
    
    DEBUG && console.debug('Search.updateSearchButton', 'fa', fachabteilung, 'sw', schwerpunkt);

  }

  private updateSchwerpunkte (): void {

    const fachabteilung = this.state.get<ISelFachabteilung>('fachabteilung');
    const fachgruppe    = this.findFagr(fachabteilung[0]);

    if (fachgruppe?.childs?.length) {
      this.schwerpunkte = fachgruppe.childs
        .sort( (a, b) => a.sort - b.sort )
        .map( schwerpunkt => ({ label: schwerpunkt.name, value: String(schwerpunkt.id_fagrp) }) )
      ;

    } else {
      this.schwerpunkte = [];
      this.state.update({ schwerpunkt: [] });

    }

  }
  
    
  public onHints (hint: FormItemMessage[]) {
    this.hideHint = !hint.length;
    this.hints    = hint;
  }

  public onSearchEinrichtung (suche: string) {
    
    // quick exit
    if (!suche) { return; }
    
    console.log('Search.onSearchEinrichtung.in', suche);

    if (suche.length < this.config.search_min_length) {
      // hint on too short
      this.hideHint = false;
      this.hints = [{ message: this.trans.search_klinik_hint }];

    } else {
      // emit upstream
      this.hints = [];
      this.searchByString.emit({ suche });

    }

  }

  public onSearchIndikation (): void {

    let fagr;
    const schwerpunkt   = this.state.get<ISelSchwerpunkt>('schwerpunkt');
    const fachabteilung = this.state.get<ISelFachabteilung>('fachabteilung');

    if (schwerpunkt.length) {
      fagr = schwerpunkt[0];

    } else if (fachabteilung.length ) {
      fagr = fachabteilung[0];

    } else {
      console.debug('Search.onSearchIndikation', 'nothing selected');
      return;

    }

    const slug = this.findSlug(fagr);
    this.searchByIndikation.emit({ slug, fagr });

  }

  private findSlug (idFagr?: string): string {

    const fachgruppe = this.findFagr(idFagr);
    return !fachgruppe
      ? 'notfound'
      : fachgruppe.slug.split('/').filter(Boolean).join('-')
    ;

  }

  private findFagr (idFagr?: string): IFachgruppe | undefined {

    let fachgruppe: IFachgruppe | undefined;

    if (!idFagr) { return undefined }

    this.data.forEach( fagr => {
      if (!fachgruppe) {
        if (fagr.id_fagrp === Number(idFagr)) {
          fachgruppe = fagr;
        } else if (fagr.childs) {
          fagr.childs.forEach( child => {
            if (child.id_fagrp === Number(idFagr)) {
              fachgruppe = child;
            }
          })

        }
      }

    })

    return fachgruppe;

  }

}
