import { Directive, Input, ViewContainerRef, TemplateRef, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

import { AppService } from "src/app/app.service";
import { TBreakpoint } from "../interfaces";

// https://itnext.io/css-media-queries-in-angular-directive-yep-you-heard-it-right-ef2ecf1b5088

@Directive({ selector: '[pr-show], [pr-hide]' })
export class MediaBreaksDirective implements OnInit, OnDestroy {

  private isCreated: boolean = false;
  private subscriber$: Subscription | undefined;
  private show: TBreakpoint[] = [];
  private hide: TBreakpoint[] = [];

  @Input('pr-show') set prShow(value: TBreakpoint[]) { this.show = value; }
  @Input('pr-hide') set prHide(value: TBreakpoint[]) { this.hide = value; }

  constructor (
    private readonly viewContainer: ViewContainerRef,
    private readonly template: TemplateRef<any>,
    private readonly app: AppService
  ) { }

  ngOnInit () {

    // quick exit on empty params
    if (!this.show.length && !this.hide.length) {
      return;
    }

    this.subscriber$ = this.app.media$.subscribe(this.update.bind(this));

  }

  update (breakpoint: TBreakpoint | null): void {

    if (!breakpoint) { return; }

    // TODO: refactor logic
    if ( this.show.includes(breakpoint) && !this.isCreated ) {
      const view = this.viewContainer.createEmbeddedView(this.template);
      view.markForCheck();
      this.isCreated = true;
      return;
      
    } else if (this.show.includes(breakpoint)) {
      return;
      
    }

    if ( this.hide.length && !this.hide.includes(breakpoint) && !this.isCreated ) {
      const view = this.viewContainer.createEmbeddedView(this.template);
      view.markForCheck();
      this.isCreated = true;
      return;
      
    } else if (this.hide.length && !this.hide.includes(breakpoint)) {
      return;
      
    }

    this.viewContainer.clear();
    this.isCreated = false;

  }

  ngOnDestroy(): void {
    this.subscriber$?.unsubscribe();
  }

}
