
  <div drv-row class="drv-mt-sm--02 drv-mb-sm--03">
    <div drv-col [sm]="4" [md]="{ span: 8, offset: 2 }" [lg]="{ span: 8, offset: 2 }">

      <div class="w-100 container">

        <drv-icon [type]="'maintenance'" style="margin: 0 8px"></drv-icon>

        <label class="button">
          <drv-icon type="upload"></drv-icon>
          Markdown
          <input 
            style="display:none"
            type="file" 
            (change)="readFile($event)"
            accept=".md,markdown/text"
            multiple="false"
          >
        </label>

        <label class="button" (click)="onToggle(true)">
          <drv-icon [type]="'minus'"></drv-icon>
          Einklappen
        </label>

        <label class="button" (click)="onToggle(false)">
          <drv-icon [type]="'plus'"></drv-icon>
          Ausklappen
        </label>

        <label class="button" (click)="onToggleAnchors()">
          <drv-icon [type]="'eye'"></drv-icon>
          Anker
        </label>

      </div>

    </div>
  </div>