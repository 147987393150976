import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileListItem } from '@drv-ds/drv-design-system-ng';

const DEBUG = false;
let self: MarkdownHelperAtom;

@Component({
  selector: 'pr-atom-markdown-helper',
  templateUrl: './markdown-helper.atom.html',
  styleUrls: ['./markdown-helper.atom.scss']
})
export class MarkdownHelperAtom {

  public foldin = true;
  public showAnchor = false;

  public labels = {
    foldin:  'Alle einklappen',
    foldout: 'Alle ausklappen',
    anchorsOn: 'Anker anzeigen',
    anchorsOff: 'Anker ausblenden',
  }

  @Input() public multiple: boolean = false;

  @Output() file:    EventEmitter<string | ArrayBuffer | null>  = new EventEmitter<string | ArrayBuffer | null>();
  @Output() toggle:  EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() anchors: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { self = this }

  public files = [];
  public fileList: FileListItem[] = [];
  public model: any;

  readFile (e: any) {

    const file: File = e.target.files[0];
    const fileReader: FileReader = new FileReader();

    // TODO: replace self w/ bind
    fileReader.onloadend = function(e){
      DEBUG && console.log('readFile', file.name, file.size, fileReader?.result?.toString().length);
      self.file.emit(fileReader.result);
    }

    fileReader.readAsText(file);

  }

  onToggle (foldin: boolean) {
    this.toggle.emit(foldin);
  }

  onToggleAnchors () {
    this.showAnchor = !this.showAnchor;
    this.anchors.emit(this.showAnchor);
  }

}
