@for (child of children; track child) {
  @switch (child.type) {
    <!-- HEADINGS -->
    @case ('heading') {
      <!-- H1 can't be here -->
      @if (child.depth === 1) {
        <h1>H1 not allowed in Markdown</h1>
      }
      <!-- HEADERS -->
      @if (child.depth === 2) {
        <h2
          [inline]="child"
        ></h2>
      }
      @if (child.depth === 3) {
        <h3
          [inline]="child"
        ></h3>
      }
      @if (child.depth === 4) {
        <h4
          [inline]="child"
        ></h4>
      }
    }
    <!-- PARAGRAPH -->
    @case ('paragraph') {
      <p
        [inline]="child"
      ></p>
    }
    <!-- LIST -->
    @case ('list') {
      <ul [inline]="child"></ul>
    }
    <!-- HTML -->
    @case ('html') {
      <span
        class="pr-highlight"
        [innerHtml]="sameAsHtml(child.value)"
      ></span>
    }
    <!-- ACCORDION -->
    @case ('accordion-wrapper-start') {
      <drv-accordion>
        @for (child of child.children; track child) {
          @if (show?.anchors) {
            <a
              class="slug"
              id="{{ child.anchor }}"
              [routerLink]="pageLink"
              [fragment]="child.anchor"
              >
              {{ show?.anchors ? child.anchor : '' }}
            </a>
          }
          <drv-accordionitem
            [title]="child.title ?? ''"
            [isOpen]="child.isOpen"
            [itemId]="child.anchor ?? ''"
            class="{{ 'level-' + child.level }}"
            >
            <span [inline]="child"></span>
          </drv-accordionitem>
        }
      </drv-accordion>
    }
    <!-- DEFAULT & DEBUG -->
    @default {
      <div>
        DEFAULT-BLOCK {{ child.type | json }}
      </div>
    }
  }
}
