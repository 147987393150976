import { Component, Input } from '@angular/core';
import { Params } from '@angular/router';

// https://drv-design-system-ng.eu-de.mybluemix.net/?path=/story/molecules-iconlink--router-link

@Component({
  selector: 'pr-atom-page-backlink',
  template: `
    <div drv-row>
      <div drv-col [sm]="4">
        <drv-iconlink icon="arrow-left" [route]="route" [routeExtras]="routeExtras">
          <ng-content></ng-content>
        </drv-iconlink>
      </div>
    </div>
  `,
  styles: []
})
export class PageBacklinkAtom {

  @Input() route = ['/'];
  @Input() routeExtras: Params = {};

}
