
  <div drv-row class="drv-mt-sm--02 drv-mb-sm--03">
    <div drv-col [sm]="4" [md]="{ span: 8, offset: 2 }" [lg]="{ span: 8, offset: 2 }">


      <div class="tc w-100" style="border: 1px solid darkblue">

        <label 
          style="font-size: 20px; margin: 1rem 1rem 0 0; font-weight: 600; color: darkblue; cursor: pointer; display: inline"
        >
          Markdown uploaden
          <input 
            style="display:none"
            type="file" 
            (change)="readFile($event)"
            accept=".md,markdown/text"
            multiple="false"
          >
        </label>

        <drv-icon type="upload" style="display: inline; vertical-align: sub;"></drv-icon>

      </div>

    </div>
  </div>