
<!-- @if ( npsFormAllowed ) { -->

  <h2>{{ trans.nps_header }}</h2>

  <p class="fs-110 drv-mt-sm--01 drv-mb-sm--03">
    {{ trans.nps_question }}
  </p>

  <drv-inputgroup
    grouplabel=""
    name="npsgroup"
    layout="columns"
    backgroundUI="light"
    [required]="true"
    [(ngModel)]="npsScore"
    (onChange)="npsChange()"
  >
  @for (index of helper.range(0, 11); track index) {
      <drv-radiobutton
        id="{{ 'option' + index }}"
        name="{{ 'score' + index }}"
        [value]="index"
      >{{ index }}</drv-radiobutton>
    }
  </drv-inputgroup>

  <div class="drv-mt-sm--03">
    <drv-textarea
      id="field"
      name="field"
      [label]="trans.nps_label_comment"
      [counterLabel]="trans.nps_counter_label"
      [counterLimitReachedMessage]="trans.nps_counter_message"
      [required]="true"
      [rows]="3"
      [maxLength]="config.nps_max_char"
      [hasCounter]="true"
      [placeholder]="trans.nps_placeholder_comment"
    ></drv-textarea>
  </div>

  <drv-buttonbar class="drv-mt-sm--03">

    <drv-button
      iconPosition="left"
      iconType="document-saved"
      backgroundUI="light"
      fullSizeOption='full-sm'
      [label]="trans.nps_button_label"
      [disabled]="!npsSendAllowed"
      [click]="onSend"
    ></drv-button>

  </drv-buttonbar>

<!-- } -->

<drv-notification
  type="toast"
  notificationId="prNpsNotification"
></drv-notification>
