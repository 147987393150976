import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { Subscription, tap } from 'rxjs';

import { DropdownComponent, DrvDropdownOption, FormItemMessage, SearchbarComponent } from '@drv-ds/drv-design-system-ng';

import { I18n } from '@pr/core/i18n.module';
import { ApiService } from '@pr/core/api.service';
import { IAPIStrings } from '@pr/core/interfaces';
import { StateService } from '@pr/core/state.service';
import { ConfigService } from '@pr/core/config.service';
import { HelperService } from '@pr/core/helper.service';
import { DOCUMENT } from '@angular/common';

const DEBUG = false;

/**
 * Creates autocomplete with a searchbar and a dropdown component zIndexed one over the other.
 * Essentially uses only the list from the dropdown, which is dynamically filled with options
 * from api requests.
 * 
 * TESTING : 
 * 
 * input: ENTER => hint: too short
 * input: yy  + ENTER => hint: too short
 * input: yyy + ENTER => hint: no result
 * input: yyy + ENTER => hint: no result + click x => hint removed
 * input: as + ARROW DOWN + ENTER => select item from list into searchbar input
 * input: as + ARROW DOWN + ENTER + SPACE + zu + ARROW DOWN + ENTER => 2 items in searchbar
 * input: as + ARROW DOWN + ENTER + ENTER => starts search, changes to ergebnis page
 * input: as + ARROW DOWN + ENTER + BACKSPACE + BACKSPACE ... => shows list with different results
 * List closes on any key in seachbar, ESC, SPACE, BACKSPACE + letters and numbers
 * Click on x clears search bar, closes list
 */

@Component({
  selector: 'pr-cell-search-autocomplete',
  templateUrl: './search-autocomplete.cell.html',
  styleUrls: ['./search-autocomplete.cell.scss']
})
export class SearchAutocompleteCell implements AfterViewInit, OnDestroy {
  
  private trans = {
    search_klinik_hint: $localize`:@@page.start.search.klinik.hint:`,
  }
  
  public autoproposalsDefault: DrvDropdownOption[] = [];
  public autoproposals:        DrvDropdownOption[] = this.autoproposalsDefault;
  
  // prevent spamming of api
  private throttleTimer   = 0;
  private throttleTimeout = 250;
  
  // dropdown onclose subscriber
  private dropdownSubscriber!: Subscription;
  
  @Input() public toggletipTemplate: TemplateRef<any> | undefined;
  @Input() public hasHint = false;
  @Input() public hintText: FormItemMessage[] = [];
  
  @Input() public labelText       = '';
  @Input() public buttonText      = '';
  @Input() public placeholderText = '';
  
  @Output() searchEinrichtung: EventEmitter<string> = new EventEmitter<string>();
  @Output() hints: EventEmitter<FormItemMessage[]> = new EventEmitter<FormItemMessage[]>();
  
  @ViewChild('pr_autocomplete_searchbar') private searchBar: SearchbarComponent | undefined = undefined;
  @ViewChild('pr_autocomplete_dropdown') private dropdown: DropdownComponent | undefined = undefined;
  
  constructor (
    public readonly i18n:       I18n,
    public readonly config:     ConfigService,
    public readonly helper:     HelperService,
    public readonly state:      StateService,
    private readonly api:       ApiService,
    private readonly cd:        ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) { }
  
  ngOnDestroy () {
    this.dropdownSubscriber?.unsubscribe();
  }
  
  public get enabled () {
    // only available on desktop devices
    // uses DS user agent sniffing
    return !this.dropdown?.isMobile
  }
  
  ngAfterViewInit (): void {
    
    // init list with empty array
    this.autoproposals = this.autoproposalsDefault;
    
    // so far autocomplete only on desktops
    if (this.enabled) {
      
      // disable browser autocomplete
      this.searchBar!.input.nativeElement.setAttribute('autocomplete', 'off');
    
      // monkey patch clickClearHandler
      // TODO: check reset on destroy
      const clearHandler = this.searchBar!['clickClearHandler'];
      this.searchBar!['clickClearHandler'] = () => {
        // execute original method
        clearHandler.bind(this.searchBar)();
        this.dropdown!.value = [];
        this.hints.emit([]);
        this.hasHint  = false;
        this.hintText = [];
        DEBUG && console.log('Auto.clearHandler.out');
      }

      // atttach event listeners
      this.searchBar!.input.nativeElement.addEventListener('keydown', this.onKeydown.bind(this));
      this.dropdownSubscriber = this.dropdown!.onClose.subscribe(this.onDropdownClose.bind(this));
            
      // TODO: evaluate impact of better pos handling
      this.dropdown!['calculatePosition'] = function () { }
    
      // mark hidden dropdown button
      this.document
        .querySelector('pr-cell-search-autocomplete drv-dropdown button')
        ?.setAttribute('aria-label', 'Auswahlliste')
      ;
    
    }
    
    // prefill from last search
    // TODO: Verify
    DEBUG && console.log('Auto.afterviewinit.state', this.state.get('suche'))
    this.searchBar!.input.nativeElement.value = this.state.get('suche');
    this.cd.detectChanges();

  }
  
  // searchbar
  public onSearch (searchItems: string) {
    
    DEBUG && console.log('Auto.onSearch', searchItems);
    
    searchItems = searchItems.trim();
    
    if (searchItems.length < 3) { 
      this.hasHint  = true;
      this.hintText = [{ message: this.trans.search_klinik_hint }];
      this.hints.emit(this.hintText);
      this.cd.detectChanges();
    
    } else {
      // send search to parent
      this.searchEinrichtung.emit(searchItems);
      
    }
    
  }
  
  private onKeydown (e: KeyboardEvent) {

    if (!this.dropdown) { return; }
    
    // remove infos, if user types again
    this.hints.emit([]);
    this.hasHint  = false;
    this.hintText = [];
    
    this.cd.detectChanges();
    
    // ArrowDown opens dropdown list and marks first option
    if (e.code === 'ArrowDown' && this.dropdown.isOpen) {

      // !! accesing private property from dropdown
      // https://github.com/microsoft/TypeScript/issues/19335
      this.dropdown['selectOption'](-1, 0); 
      this.dropdown['focusOption'](-1, 0); 
      this.dropdown.input.nativeElement.focus();
      
      this.cd.detectChanges();

      DEBUG && console.log('Auto.onKeydown.out', e.code, this.dropdown.templateOptions);

    } else if (e.code === 'Enter') {
      if (this.dropdown.isOpen) {
        // close dropdown list
        this.dropdown?.input.nativeElement.click();
      }
      const items = this.searchBar?.input.nativeElement.value;
      this.onSearch(items);
      this.searchBar!.input.nativeElement.focus();
      DEBUG && console.log('Auto.onKeydown.out', e.code, items);
      
    } else if (e.code === 'Escape') {
      DEBUG && console.log('Auto.onKeydown.out', e.code);
      
    } else {
      // remove value, so it's not inserted on dropdown close
      this.dropdown.value = [];
      
    }
    
  }
  
  // dropdown: copy selected to searchbar
  public onDropdownClose () {
    
    DEBUG && console.log('Auto.onDropdownClose.in', this.dropdown?.value);
    
    // quick exit, no value
    if (!this.dropdown?.value.length) { return; }
    
    // pick value from list
     const selectedOption = this.dropdown?.value[0].label ?? '';
    
    // quick exit, value empty
    if (!selectedOption) { 
      this.searchBar!.input.nativeElement.focus();
      return; 
    }
    
    const curValue: string = this.searchBar!.input.nativeElement.value;
    
    // quick exit, got value already
    if (curValue.toLocaleLowerCase().search(selectedOption.toLocaleLowerCase()) > -1) {
      return;
    }
    
    // replace input value with old and new value
    const currentItems = this.searchBar!.input.nativeElement.value.split(' ').slice(0, -1);
    const newItems     = [...currentItems, selectedOption].join(' ');
    this.searchBar!.input.nativeElement.value = newItems;
    
    // set list back to empty
    this.autoproposals  = this.autoproposalsDefault;
    this.cd.detectChanges();
    
    this.searchBar!.input.nativeElement.focus();
    
  }
  
  public onInput (e: Event) {
    
    DEBUG && console.log('Auto.onInput', this.searchBar!.input.nativeElement.value);
        
    // first close dropdown on any key
    if (this.dropdown?.isOpen) {
      this.dropdown?.toggle(false, false);
    }
    
    const text  = this.searchBar!.input.nativeElement.value;
    const suche = text.split(' ').slice(-1)[0].trim();

    // do nothing, too short
    if (suche.length < 2) { return; }
    
    // if request is wating, replace with this new one
    if (this.throttleTimer) {
      window.clearTimeout(this.throttleTimer);
    }
    
    // fire request
    this.throttleTimer = window
      .setTimeout(this.propose.bind(this, suche), this.throttleTimeout)
    ;

  }

  private propose (suche: string) {
    
    const t0         = Date.now();
    const num        = 12;
    let responseTime = 0;
    let requestTime  = 0;
        
    this.api
      .get<IAPIStrings>('/autocomplete', { suche, num })
      .pipe(
        tap( (res: any) => {

          responseTime = res.meta.responseTime;
          requestTime  = Date.now() - t0;
          
          // propagate to dropdown
          this.autoproposals = res.data.map( (text: string) => ({ value: text, label: text}));
          this.cd.detectChanges();
          
          if (this.autoproposals.length) {
            
            // open dropdown and move slightly up
            this.dropdown?.input.nativeElement.click();
            this.dropdown!.isOpen = true;
                        
          } else {
            // otherwise clear dropdown list
            this.autoproposals = this.autoproposalsDefault;
            
          }
          
          DEBUG && console.log('Auto.result', suche, 
            `${this.autoproposals.length}/${num}`, 
            'total', Date.now() - t0 , 'request', requestTime, 'response', responseTime
          );
          
        })
      ).subscribe()
    ;
      
  }
  
}
