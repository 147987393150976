import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { CollapsibleComponent } from '@drv-ds/drv-design-system-ng';

import { I18n } from '@pr/core/i18n.module';
import { ConfigService } from '@pr/core/config.service';
import { TemplateIdDirective } from '@pr/core/directives/template-id.directive';
import { TCellTemplate, TTableSort } from '@pr/core/interfaces';
import { IErgFachabteilung } from '@pr/core/interfaces.api';

const DEBUG = false;

@Component({
  selector: 'pr-atom-mobile-table-row',
  templateUrl: './mobile-table-row.atom.html',
  styleUrls: ['./mobile-table-row.atom.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileTableRowAtom implements AfterViewInit {

  public isOpen = false;
  public cells: Array<TemplateRef<any> | null> = []
  private usersort: TTableSort | undefined;

  public trans = {
    title_quality_fa:      $localize`:@@page.ergebnis.circle.title.fachabteilung:`,
    title_quality_vg:      $localize`:@@page.ergebnis.circle.title.vergleichsgruppe:`,
    reha_anschluss_false:  $localize`:@@page.ergebnis.table.ahb.no:`,
    reha_anschluss_true:   $localize`:@@page.ergebnis.table.ahb.yes:`,
    qualitaet_nodata:      $localize`:@@page.ergebnis.table.qualitaet.nodata:`,
  };

  @Input() expand = false;
  @Input() fa: IErgFachabteilung | undefined = undefined;
  @Input() set sort (sort: TTableSort) {
    DEBUG && console.log('MobileTable.sort.in', sort);
    this.usersort = sort;
    this.updateSort(sort);
  };
  @ViewChild(CollapsibleComponent) collapsible!: CollapsibleComponent;
  @ViewChildren(TemplateIdDirective) private templates!: QueryList<TemplateIdDirective>;

  constructor(
    public readonly i18n:   I18n,
    private readonly cd:    ChangeDetectorRef,
    public readonly config: ConfigService,
  ) { }

  private updateSort(sort: TTableSort) {

    this.usersort = sort;

    if (this.templates?.length) {

      const [index] = sort.split(',');
      const cells: Array<TCellTemplate> = (
        // Einrichtung
        index === '0' ? ['durchfuehrung', 'wartezeit', 'qualitaet', 'adresse'] :
        // Adresse
        index === '1' ? ['durchfuehrung', 'wartezeit', 'qualitaet', 'adresse'] :
        // Durchfuehrung
        index === '2' ? ['adresse', 'wartezeit', 'qualitaet', 'durchfuehrung'] :
        // Qualitaet
        index === '3' ? ['durchfuehrung', 'wartezeit', 'qualitaet', 'adresse'] :
        // Wartezeit
        index === '4' ? ['adresse', 'durchfuehrung', 'qualitaet', 'wartezeit'] :
          []
      );

      this.cells = cells.map(name => this.getTemplateById(name));
      this.cd.detectChanges();

      DEBUG && console.log('updateSort', sort, this.usersort, this.cells.length, this.templates?.length);

    }

  }

  private getTemplateById(name: string): TemplateRef<any> | null {
    const dir = this.templates.find(dir => dir.name === name);
    return dir ? dir.template : null;
  }

  public ngAfterViewInit() {
    DEBUG && console.log('ngAfterViewInit', this.usersort, this.cells.length, this.templates?.length);
    this.usersort && this.updateSort(this.usersort);
  }

  // DEBUG
  private  onClick (event: MouseEvent) {
    DEBUG && console.log('onClick', (event.target as HTMLElement).tagName, (event.srcElement as HTMLElement).tagName);
  }

}
