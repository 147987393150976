import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import packageJson from '../package.json';
import configJson from './assets/api/config.json';
import { environment } from './environments/environment';

import { APP_CONFIG } from './app/core/injection-tokens';
import { AppModule } from './app/app.module';
import { IConfig } from './app/core/config.service';

const prVersion = packageJson.version;
const dsVersion = packageJson.dependencies['@drv-ds/drv-design-system-ng'];
const ngVersion = packageJson.dependencies['@angular/core'];
const envname   = environment.name;
const basehref  = environment.basehref;
const versions  = `PR: ${prVersion}, DS: ${dsVersion}, NG: ${ngVersion.slice(1)}`;

const SIMULATEPROD = false;

console.log('%cDRV Public Reporting', 'color: green; font-weight: 800', envname, basehref, versions);
console.log('%cEnvironment', 'font-weight: 800; color: darkorange', { environment });

// https://offering.solutions/blog/articles/2021/11/11/loading-configuration-before-your-angular-app-starts/

void ( async () => {

  const url = environment.apifolder + '/config.json';

  await fetch(url)
    .then((res) => {
      if ( res.status !== 200 ) {
        console.error('Fetch Config failed, using default');
      }
      return res;
    })
    .then((res) => res.status === 200 ? res.json() : configJson)
    .then((config: IConfig) => {

      if (environment.enableProdMode || SIMULATEPROD) {
        enableProdMode();
      }

      // Here to delete localstorage on reload, if wanted
      // delete lcoalstorage, ergebnisse, etc if needed
      // window.localStorage.clear();

      // make config available, continue booting
      platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
        .bootstrapModule(AppModule)
        .catch((err) => console.error(err))
      ;

    })
    .catch( err => {

      if (environment.production) {
        // TODO: redirect to static Error Page
      }

      console.error(err);

    })

})()
