import { Component, Input, Inject, EventEmitter, Output } from '@angular/core';
import { FrozenProcessor } from "unified";

import { ConfigService } from '@pr/core/config.service';
import { INode } from '@pr/core/interfaces';
import { MarkdownService } from '@pr/core/markdown.service';

const DEBUG = false;

@Component({
  selector: 'cell-markdown',
  template: `
    <div class="markdown"
      [block]="procmast"
      [show]="show"
      [pageLink]="pageLink"
    ></div>
  `,
  styles: []
})
export class MarkdownCell {

  // markdown abstract syntax tree
  private mast: INode | undefined;

  // proccessed markdown abstract syntax tree
  public procmast: INode | undefined;

  public hitCount = 0;

  public show = {
    anchors: false,
  }

  // sends #hits ip to page
  @Output() hits: EventEmitter<number> = new EventEmitter<number>();

  @Input() set toggle   (foldin: boolean)    { this.toggleAccordions(foldin); }
  @Input() set anchors  (show:   boolean)    { this.show.anchors = show; }
  @Input() set fragment (fragment: string)   { this.setFragment(fragment); }
  @Input() pageLink: string[] = [];

  // accepts search string and might open/close accorions
  @Input() set search (searchstr: string) {

    if (searchstr.length >= 3) {
      this.hitCount = this.markHits(searchstr);

    } else {
      this.hitCount = 0;
      this.toggleAccordions(true);
      this.procmast && this.service.traverseNodes(
        this.procmast,
        (node) => node.type === 'text',
        (node) => delete node.highlight,
      )

    }

    // send back to parent page
    this.hits.emit(this.hitCount);

  }

  // accepts markdown/text and provides a parsed and processed AST for the template
  @Input() set  rawmarkdown (markdown: string) {

    DEBUG && console.log('Markdown.raw', markdown.length);

    // parses text to AST
    this.mast     = this.unified.parse(markdown) as INode;

    DEBUG && console.log('\nMarkdown.mast', this.mast.children.length, this.mast);
    // DEBUG && this.logNodes(0, this.mast.children)

    // looks for HTML marker and processes accordingly
    this.procmast = this.service.createProcMast(this.mast);

    DEBUG && console.log('\nMarkdown.procmast', this.procmast.children.length, this.procmast);
    DEBUG && this.service.logNodes(0, this.procmast.children)

  }

  constructor(
    @Inject('unified') private unified: FrozenProcessor,
    public readonly config: ConfigService,
    public readonly service: MarkdownService
  ) { }

  markHits (needle: string): number {

    let counter = 0;

    // case insensitive search
    function countHits (haystack: string, needle: string): number {
      const regExp = new RegExp( needle , 'gi');
      return (haystack.match(regExp) || []).length;
    }

    // child nodes iterator, returns whether at least one hit
    function searchNodes (nodes: any): boolean {
      return nodes.some( (node: INode) => {
        if (node.value && node.type === 'text') {
          const hits = countHits(node.value, needle);
          return hits;
        } else if (node.children?.length) {
          return searchNodes(node.children);
        }
        return false;
      });
    }

    // open all accordions with hits in childs
    this.procmast && this.service.traverseNodes (
      this.procmast,
      (node) => node.type === 'accordion-item' || node.type ===  'details-item',
      (node) => {
        const hits = searchNodes(node.children);
        node.isOpen = hits;
        return true;
      }
    );

    // set highlight on all width text
    this.procmast && this.service.traverseNodes (
      this.procmast,
      (node) => node.type === 'text',
      (node) => {
        const hits = countHits(node.value, needle);
        counter += hits;
        if (hits) {
          node.highlight = needle;
        } else {
          delete node.highlight;
        }
      }
    );

    DEBUG && console.log('markHits', needle, counter, 'hits');

    return counter;

  }

  toggleAccordions (foldin: boolean) {

    this.procmast && this.service.traverseNodes (
      this.procmast,
      (node) => node.type === 'accordion-item' || node.type === 'details-item',
      (node) => node.isOpen = !foldin
    )

  }

  // toggleAnchors (show: boolean) {
  //   // console.log('MarkdownCell.toggleAnchors', show);
  // }

  setFragment(fragment: string) {
    // console.log('MarkdownCell.fragment', fragment);
  }

}
