
import { NgModule } from '@angular/core';

import { MediaBreaksDirective } from './media.directive';
import { HighlightDirective } from './highlight.directive'
import { TemplateIdDirective } from './template-id.directive';
import { NgxFullscreenDirective } from './ngx-fullscreen.directive';
import { IntersectionObserverDirective } from './intersection-observer.directive';
import { SingleTileDirective } from './single-tile.directive';

@NgModule({
  declarations: [
    MediaBreaksDirective,
    HighlightDirective,
    TemplateIdDirective,
    NgxFullscreenDirective,
    IntersectionObserverDirective,
    SingleTileDirective,
  ],
  exports:[
    MediaBreaksDirective,
    HighlightDirective,
    TemplateIdDirective,
    NgxFullscreenDirective,
    IntersectionObserverDirective,
    SingleTileDirective,
  ]
 })
 export class DirectivesModule { }
