<p id="description" class="dn" i18n="@@app.table.accessibility.hint">app.table.accessibility.hint</p>

<div
  #container
  #fullscreen="ngxFullscreen"
  class="fxrow"
  [ngStyle]="styleClusterContainerLayout"
  [ngxFullscreen]="container"
  (transition)="onResize()"
  >

  <!-- MOBILE TABLE  reduce font-size for most children-->
  <div #tablecontainer class="fxcol" [ngStyle]="styleTableLayout" style="padding: 0;">

    @if (fullscreen.isFullscreen) {
      <h2 style="margin: 1rem 0;">
        {{ dataTable?.length ?? 0 }} Treffer
      </h2>
    }

    <div class="hideHint" style="margin: 0 1rem 0.5rem 0">
      <drv-dropdown
        #drv_dropdown_sortierung
        id="drv-dropdown-sortierung"
        data-e2e="comboSortierung"
        [label]="trans.label_sort"
        [required]="true"
        [options]="comboSortOptions"
        [placeholder]="trans.please_choose"
        (ngModelChange)="onSelectSort($event)"
        [ngModel]="selected.sort"
      ></drv-dropdown>
    </div>

    <div #rowcontainer class="fxgrow" style="overflow-y: scroll; overflow-x: hidden; padding-right: 1rem;">
      @for (fa of dataTablePage; track fa) {
        <pr-atom-mobile-table-row
          [fa]="fa"
          [sort]="state.get('sort')"
          [expand]="true"
        ></pr-atom-mobile-table-row>
      }
    </div>

    <pr-cell-table-pagination
      #paginator
      [showRowSelector]="false"
      (events)="updatePagination($event)"
    ></pr-cell-table-pagination>

  </div>

  <div
    class="fxgrow"
    tabindex="0"
    [attr.aria-label]="trans.accessibility_hint"
    >
    <div
      #leafletMap leaflet
      aria-hidden="true"
      [ngStyle]="styleMapLayout"
      [leafletOptions]="leafletOptions"
      [leafletLayersControl]="leafletLayersControl"
      [leafletLayers]="leafletFeatures"
      [leafletFitBoundsOptions]="leafletFitBoundsOptions"
      (leafletMapReady)="onMapReady($event)"
    ></div>

    <leaflet-fullscreen-control
      [map]="map"
      [options]="fullscreenOptions"
    ></leaflet-fullscreen-control>

  </div>

</div>
