import { Injectable } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { combineLatest, filter, first, map, pairwise, skip, zip } from 'rxjs';

import { ConfigService } from './config.service';
import { MetaService } from './meta.service';

const DEBUG = false;
const DEBUGSTYLE = 'color: #7777cc; font-weight: 800';

@Injectable({
  providedIn: 'root'
})
export class MatomoService {

  public cookieConsent : boolean | undefined = undefined;
  private base = '';

  //https://developer.matomo.org/guides/spa-tracking#measuring-single-page-apps-complete-example

  constructor (
    private readonly cookies: CookieService,
    private readonly config:  ConfigService,
    private readonly router:  Router,
    private readonly meta:    MetaService,
  ) {

    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/doNotTrack
    const doNotTrack = navigator.doNotTrack === '1' ? true : false;

    this.cookieConsent = this.consent;
    this.base = this.config.environment.basehref === '/' ? '' : this.config.environment.basehref;

    console.log('%cMatomo.Consent', DEBUGSTYLE, this.base, this.cookieConsent, doNotTrack ? 'DNT' : '');

  }
  
  get consent ():boolean | undefined {
    
    const doNotTrack = navigator.doNotTrack === '1' ? true : false;
    
    return (
      this.cookies.get('mtm_consent')          ? true  :
      this.cookies.get('mtm_consent_removed')  ? false :
      doNotTrack                               ? false :
        undefined
    )
    
    
  }

  public activate () {

    // Triggers on the very first page load of a session
    // and after metags have been updated
    combineLatest([
      this.router.events.pipe(
        filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd),
        first(),
        map( (current) => current.urlAfterRedirects ),
      ),
      this.meta.metaUpdated$.pipe(
        filter(Boolean),
        first(),
      )
    ])
    .subscribe( ([currentUrl, metaTags]) => {

      const urlPrefix = this.stripTrailingSlash(location.host + this.base);

      DEBUG && console.log('%cMatomo.first', DEBUGSTYLE, urlPrefix, currentUrl, metaTags?.page_title);

      if (document.referrer) {
        (window as any)._paq.push(['setReferrerUrl',   document.referrer]);
      }
      (window as any)._paq.push(['setCustomUrl',     urlPrefix + currentUrl]);
      (window as any)._paq.push(['setDocumentTitle', metaTags?.page_title]);
      (window as any)._paq.push(['trackPageView']);

    });

    // Triggers at each page load starting with second
    // and after metags have been generated
    zip([
      this.router.events.pipe(
        filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd),
        pairwise(),
        map( ([previous, current]) => [previous.urlAfterRedirects, current.urlAfterRedirects] ),
      ),
      this.meta.metaUpdated$.pipe(
        filter(Boolean),
        skip(1),
      )
    ])
    .subscribe(results => {

      const [[ previousUrl, currentUrl ], metaTags] = results;
      const urlPrefix = this.stripTrailingSlash(location.host + this.base);

      DEBUG && console.log('%cMatomo.second', DEBUGSTYLE, urlPrefix, currentUrl, metaTags?.page_title);

      (window as any)._paq.push(['setReferrerUrl',   urlPrefix + previousUrl]);
      (window as any)._paq.push(['setCustomUrl',     urlPrefix + currentUrl]);
      (window as any)._paq.push(['setDocumentTitle', metaTags?.page_title]);
      (window as any)._paq.push(['deleteCustomVariables', 'page']);
      (window as any)._paq.push(['trackPageView']);

      // new or altered content
      (window as any)._paq.push(['enableLinkTracking']);

    });

  }

  private stripTrailingSlash (str: string) {
    return str.endsWith('/') ? str.slice(0, -1) : str;
  };

  private getBaseRoute(url: string): string {
    // Only debuggging, return url without query params
    return this.base + url.split('?')[0].split('/').filter(Boolean).join('/');
  }

  public rememberConsent () {
    (window as any)._paq.push(['rememberConsentGiven']);
    console.log('%cMatomo.rememberConsent', DEBUGSTYLE);
  }

  public forgetConsent () {
    (window as any)._paq.push(['forgetConsentGiven']);
    console.log('%cMatomo.forgetConsent', DEBUGSTYLE);
  }

  public trackEvent (trackCategory: string, trackType: string, trackName?: string, trackValue?: string | number) {
    if (this.cookieConsent) { 
      (window as any)._paq.push(['trackEvent', trackCategory, trackType, trackName ?? '', trackValue ?? '']);
      DEBUG && console.log('%cMatomo.trackEvent', DEBUGSTYLE, 'trackEvent', trackType, trackValue ?? 0);
    }
  }

  // public forceEvent (trackCategory: string, trackType: string, trackName?: string, trackValue?: string | number) {
  //   (window as any)._paq.push(['trackEvent', trackCategory, trackType, trackName ?? '', trackValue ?? '']);
  //   DEBUG && console.log('%cMatomo.sendEvent', DEBUGSTYLE, 'trackEvent', trackType, trackValue ?? 0);
  // }

  // public sendEvent (trackCategory: string, trackType: string, trackName?: string, trackValue?: string | number) {
  //   if (this.cookieConsent) { 
  //     (window as any)._paq.push(['trackEvent', trackCategory, trackType, trackName ?? '', trackValue ?? '']);
  //     DEBUG && console.log('%cMatomo.sendEvent', DEBUGSTYLE, 'trackEvent', trackType, trackValue ?? 0);
  //   }
  // }

  public trackSearch (searchItem: string, searchType: string, hits: number) {

    if (this.cookieConsent) {
      (window as any)._paq.push(['trackSiteSearch', searchItem, searchType, hits]);
      DEBUG && console.log('%cMatomo.trackSearch', DEBUGSTYLE, searchType, searchItem, hits);
      // ZVWBG2258-264
      this.trackEvent('Start', 'suche', searchItem + '#' + String(hits), hits);
    }
    
  }

}
