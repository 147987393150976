import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'pr-atom-symbols',
  templateUrl: './symbols.atom.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: []
})
export class SymbolsAtom { }

// fixes firefox bug with CSP loading SVGs
// https://developer.mozilla.org/en-US/docs/Web/Security/Practical_implementation_guides/CSP
