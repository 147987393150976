<h2 class="drv-mt-sm--04">{{ trans.header }}</h2>
<div drv-row class="drv-mt-sm--01 drv-mt-md--02 drv-bg--ui-05" style="padding: 1rem 0; margin-right: 0; margin-left: 0;">
  <div drv-col [sm]="4" [md]="6" [lg]="3" class="group g1">
    {{ trans.short}}
  </div>
  <div drv-col [sm]="4" [md]="6" [lg]="3" class="group g2">
    {{ trans.moderat }}
  </div>
  <div drv-col [sm]="4" [md]="6" [lg]="3" class="group g3">
    {{ trans.long }}
  </div>
  <div drv-col [sm]="4" [md]="6" [lg]="3" class="group g5">
    {{ trans.gesperrt }}
  </div>
</div>
