import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

import { DSModule } from '@pr/core/ds.module';
import { DirectivesModule } from '@pr/core/directives/directives.module'

import { BrowseDetailsAtom } from './browse-details/browse-details.atom';
import { BuehneAtom } from './buehne/buehne.atom';
import { FeaturelistAtom } from './featurelist/featurelist.atom';
import { LoadingAtom } from './loading/loading.atom';
import { LogoAtom } from './logo/logo.atom';
import { ClaimAtom } from './claim/claim.atom';
import { MapAtom } from './map/map.atom';
import { MapPopupAtom } from './map-popup/map-popup.atom';
import { MarkdownHelperAtom } from './markdown-helper/markdown-helper.atom';
import { MobileTableRowAtom } from './mobile-table-row/mobile-table-row.atom';
import { PageBacklinkAtom } from './page-backlink/page-backlink.atom';
import { PageTitleAtom } from './page-title/page-title.atom';
import { PictogramListAtom } from './pictogram-list/pictogram-list.atom';
import { QualityCircleAtom } from './quality-circle/quality-circle.atom';
import { UploadFilesAtom } from './upload-files/upload-files.atom';
import { WartezeitAtom } from './wartezeit/wartezeit.atom';
import { DesktopTableRowAtom } from './desktop-table-row/desktop-table-row.atom';
import { SymbolsAtom } from './symbols/symbols.atom';

@NgModule({
  declarations: [
    BrowseDetailsAtom,
    BuehneAtom,
    FeaturelistAtom,
    LoadingAtom,
    LogoAtom,
    ClaimAtom,
    MapAtom,
    MapPopupAtom,
    MarkdownHelperAtom,
    MobileTableRowAtom,
    PageBacklinkAtom,
    PageTitleAtom,
    PictogramListAtom,
    QualityCircleAtom,
    UploadFilesAtom,
    WartezeitAtom,
    DesktopTableRowAtom,
    SymbolsAtom
  ],
  imports: [
    CommonModule,
    DSModule,
    FormsModule,
    LeafletModule,
    RouterModule,
    DirectivesModule,
  ],
  exports: [
    BrowseDetailsAtom,
    BuehneAtom,
    DesktopTableRowAtom,
    FeaturelistAtom,
    LoadingAtom,
    LogoAtom,
    ClaimAtom,
    MapPopupAtom,
    MarkdownHelperAtom,
    MobileTableRowAtom,
    PageBacklinkAtom,
    PageTitleAtom,
    PictogramListAtom,
    QualityCircleAtom,
    UploadFilesAtom,
    WartezeitAtom,
    SymbolsAtom,
  ]
})
export class AtomsModule { }
