
<div class="page page-detail drv-mt-sm--01">

  <pr-cell-tooltips #tooltipTemplates file="tooltips.md"></pr-cell-tooltips>

  <!-- BUTTONS -->
  <div drv-row>
    <div drv-col [sm]="{ span: 4, offset: 0 }">
      <pr-cell-header-bar class="drv-mt-sm--01">

        <drv-button
          class="back"
          style="margin-left: -1rem"
          [label]="trans.back_link"
          kind="tertiary"
          type="anchor"
          iconPosition="left"
          iconType="arrow-left"
          backgroundUI="light"
          [route]="backRoute"
        ></drv-button>

        <drv-button
          class="print"
          label="{{ trans.btn_print_label }}"
          kind="tertiary"
          iconPosition="left"
          iconType="print"
          backgroundUI="light"
          (onClick)="onNavigate('print')"
        ></drv-button>

        <!-- <drv-button
          class="antrag"
          label="{{ trans.btn_antrag_label }}"
          iconPosition="left"
          iconType="document-saved"
          backgroundUI="light"
          (onClick)="onNavigate('antrag')"
        ></drv-button> -->

      </pr-cell-header-bar>
    </div>
  </div>

  <!-- CLAIM -->
  <pr-atom-claim></pr-atom-claim>

  <!-- LOADER -->
  @if (!dataReady) {

    <div drv-row>
      <div drv-col [sm]="4" class="fxrow-center">
        <pr-atom-loading [size]="120"></pr-atom-loading>
      </div>
    </div>

    <!-- DATA-CONTENT -->
  } @else {

    <!-- TITLE -->
    <pr-atom-page-title>
      {{ data?.einrichtung?.name }}
    </pr-atom-page-title>

    <!-- NEXT/PREV -->
    <pr-atom-browse-details class="drv-mt-sm--02"
      [next]="neighbours?.next"
      [prev]="neighbours?.prev"
      (browse)="navigateNeighbour($event)"
    ></pr-atom-browse-details>

    <!-- INFO-KONTAKT -->
    <div drv-row>
      <div drv-col [sm]="4">

        <h2 class="drv-mb-sm--02 drv-mt-sm--04" i18n="@@page.detail.header.kontakt">
          page.detail.header.kontakt
        </h2>

        <ul class="infobox drv-bg--ui-05" style="padding: 1rem 0.5rem">
          <li>
            <div i18n="@@page.detail.stadt.label">page.detail.stadt.label</div>
            <div>{{ data?.fachabteilung?.plz }}&nbsp;{{ data?.fachabteilung?.ort }}</div>
          </li>
          <li>
            <div i18n="@@page.detail.strasse.label">page.detail.strasse.label</div>
            <div>{{ data?.fachabteilung?.strasse }}&nbsp;{{ data?.fachabteilung?.hausnummer }}</div>
          </li>
          @if ( data?.fachabteilung?.telefon ) {
            <li>
              <div i18n="@@page.detail.telefon.label">page.detail.telefon.label</div>
              <div>{{ data?.fachabteilung?.telefon }}</div>
            </li>
          }
          @if ( data?.fachabteilung?.email ) {
            <li>
              <div i18n="@@page.detail.email.label">page.detail.email.label</div>
              <drv-iconlink
                icon="mail"
                href="mailto:{{ data?.fachabteilung?.email }}"
              >{{ data?.fachabteilung?.email }}</drv-iconlink>
            </li>
          }
          @if ( data?.fachabteilung?.web ) {
            <li>
              <div i18n="@@page.detail.webseite.label">page.detail.webseite.label</div>
              <drv-iconlink
                target="_blank"
                icon="arrow-external"
                href="{{ helper.sanitizeUrl(data?.fachabteilung?.web) }}"
              >{{ helper.shortUrl(data?.fachabteilung?.web) }}</drv-iconlink>
            </li>
          }
        </ul>

      </div>
    </div>

    <!-- INFO-ALLGEMEIN -->
    <div drv-row>
      <div drv-col [sm]="4">

        <h2 class="drv-mb-sm--02 drv-mt-sm--04" i18n="@@page.detail.header.info">
          page.detail.header.info
        </h2>

        <ul class="infobox drv-bg--ui-05"  style="padding: 1rem 0.5rem">
          <li>
            <div i18n="@@page.detail.stammabteilung.label">page.detail.stammabteilung.label</div>
            <div>{{ fasc?.name_detail }}</div>
          </li>
          <li>
            <div i18n="@@page.detail.fachabteilung.label">page.detail.fachabteilung.label</div>
            <div>{{ fasc?.spec_detail }}</div>
          </li>
          <li>
            <div i18n="@@page.detail.durchfuehrung.label">page.detail.durchfuehrung.label</div>
            <div>{{ i18n.translate(data?.fachabteilung?.durchfuehrung ?? '') }}</div>
          </li>
          @if ( !!data?.fachabteilung?.isAhb ) {
            <li>
              <div i18n="@@page.detail.reha.label">page.detail.reha.label</div>
              <div>{{ data?.fachabteilung?.isAhb === undefined ? trans.wz_noinfo : data?.fachabteilung?.isAhb === true ? 'Ja' : 'Nein'}}</div>
            </li>
          }
          <li style="margin-top: -4px;">
            <div class="dib" i18n="@@page.detail.wartezeit.label">
              page.detail.wartezeit.label
            </div>
            <drv-toggletip style="margin-left: 0.4rem;">
              <ng-container
                [ngTemplateOutlet]="(tooltipTemplates.get('detail.legend.wartezeit') | async) ?? null"
              ></ng-container>
            </drv-toggletip>
            <pr-atom-wartezeit class="bold" [wzinfo]="[data?.fachabteilung?.wartezeit, 'short']"></pr-atom-wartezeit>
          </li>
          <li>
            <div i18n="@@page.detail.erkrankungen.label">page.detail.erkrankungen.label</div>
            <div>{{ fasc?.detail_erkrankungen }}</div>
          </li>
        </ul>
        <ul class="infobox drv-bg--ui-05" style="padding: 0 0.5rem 1rem">
          <li class="tl" i18n="@@page.detail.hinweis.krankheitsbild" style="background-color: #f0f2f5;">
            page.detail.hinweis.krankheitsbild
          </li>
        </ul>

      </div>
    </div>

    <!-- QUALITÄT-STANDORT -->
    <div drv-row id="qualitaet">

      <!-- QUALITÄT -->
      <div drv-col [sm]="4" [lg]="{ span: 6, offset: 0 }">

        <h2 class="drv-mb-sm--01 drv-mt-sm--04" i18n="@@page.detail.header.qualitaet">
          page.detail.header.qualitaet
        </h2>

        <pr-cell-quality-details
          style="background-color: #e1e6ec; padding: 1.5rem"
          [quality]="quality"
        ></pr-cell-quality-details>

      </div>

      <!-- MAP -->
      <div drv-col [sm]="4" [lg]="{ span: 6, offset: 0 }">

        <h2 class="drv-mb-sm--01 drv-mt-sm--04" i18n="@@page.detail.header.standort">
          page.detail.header.standort
        </h2>

        <div class="map"
          leaflet
          [leafletOptions]="leafletOptions"
          [leafletLayersControl]="leafletLayersControl"
          [leafletLayers]="leafletLayers"
          (leafletMapReady)="onMapReady($event)"
          [attr.aria-label]="trans.aria_label_map + ' ' + data?.einrichtung?.name"
        ></div>

      </div>
    </div>

    <!-- SONDERANFORDERUNGEN -->
    <div drv-row id="sonderanforderungen">
      <div drv-col [sm]="4">

        <h2 class="drv-mb-sm--02 drv-mt-sm--04" i18n="@@page.detail.header.sondermerkmale">
          page.detail.header.sondermerkmale
        </h2>

        @if (data?.sondermerkmale?.length) {
          <pr-atom-pictogram-list
            [features]="data?.sondermerkmale"
          ></pr-atom-pictogram-list>
        } @else {
          <span i18n=@@page.detail.sondermerkmale.nodata>
            page.detail.sondermerkmale.nodata
          </span>
        }

      </div>
    </div>

    <!-- WEITERE FAS -->
    @if (data?.uniqueFachabteilungen?.length) {
      <div drv-row>
        <div drv-col [sm]="4">

          <h2 class="drv-mb-sm--02 drv-mt-sm--06" i18n="@@page.detail.header.weiterefachabteilungen">
            page.detail.header.weiterefachabteilungen
          </h2>

          <ul style="padding-left: 1.5rem;">
            @for (fachabteilung of data?.uniqueFachabteilungen; track fachabteilung) {
              <li style="margin-bottom: 1rem;">
                {{ fachabteilung }}
              </li>
            }
          </ul>

        </div>
      </div>
    }

    <!-- NEST STEPS -->
    <div drv-row class="drv-mt-sm--06 drv-mm-sm--04">
      <div drv-col [sm]="4">
        <h2 id="nextsteps"  i18n="@@page.detail.nextsteps.header">page.detail.nextsteps.header</h2>
        <pr-cell-button-tile>
          <h3 i18n="@@page.detail.nextsteps.tile1.header">page.detail.nextsteps.tile1.header</h3>
          <p class="first drv-mt-sm--01" i18n="@@page.detail.nextsteps.tile1.absatz1">
            page.detail.nextsteps.tile1.absatz1
          </p>
          <p class="second drv-mt-sm--01"  i18n="@@page.detail.nextsteps.tile1.absatz2">
            page.detail.nextsteps.tile1.absatz2
          </p>
          <drv-button
            iconPosition="left"
            iconType="arrow-external"
            backgroundUI="light"
            fullSizeOption='full'
            type="anchor"
            target="_blank"
            [label]="trans.tile1_btn_label"
            [href]="trans.tile1_url"
          ></drv-button>
        </pr-cell-button-tile>
      </div>
      <div drv-col [sm]="4">
        <pr-cell-button-tile>
          <h3 i18n="@@page.detail.nextsteps.tile2.header">page.detail.nextsteps.tile2.header</h3>
          <p class="first drv-mt-sm--01" i18n="@@page.detail.nextsteps.tile2.absatz1">
            page.detail.nextsteps.tile2.absatz1
          </p>
          <p class="second drv-mt-sm--01"  i18n="@@page.detail.nextsteps.tile2.absatz2">
            page.detail.nextsteps.tile2.absatz2
          </p>
          <drv-button
            iconPosition="left"
            iconType="arrow-external"
            backgroundUI="light"
            fullSizeOption='full'
            type="anchor"
            target="_blank"
            [label]="trans.tile2_btn_label"
            [href]="trans.tile2_url"
          ></drv-button>
        </pr-cell-button-tile>
      </div>
    </div>

    <!-- NPS SCORE -->
    <!-- <div drv-row class="drv-mt-sm--06 drv-mm-sm--04">
      <div drv-col [sm]="4">

        <pr-cell-nps-form></pr-cell-nps-form>

      </div>
    </div> -->

  }

</div>
