import { Injectable } from '@angular/core';

import { IRecord } from '@pr/core/interfaces'

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor () { }

  public slugify (str: string) {

    // TODO: check: https://github.com/simov/slugify/blob/master/slugify.js

    return (str.normalize().trim().toLowerCase()
      .replace('ß', 'ss')
      .replace('ä', 'ae')
      .replace('ö', 'oe')
      .replace('ü', 'üe')
      .replace(/[^a-z0-9\s]/g, '')
      .split(' ')
      .filter( s => s.length > 2)
      .join('-')
    );

  }

  // deletes properties from Records, return new Record
  public shrinkRecord (obj: IRecord, props: string[]): IRecord {
    const newObj: IRecord = { ...obj };
    props.forEach( (prop: string) => delete newObj[prop]);
    return newObj;
  }

  public getDistance (lat1: number, lon1: number, lat2: number, lon2: number) {

    // https://git.drv.drv/projects/PR/repos/pr-backend-consumer/browse/geodaten/osm_lookup.py?at=refs%2Fheads%2Ffeature%2FZVWBG2258-198-geodaten-skript-erstellen-und-dokumentieren
    // https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates

    const radlat1  = Math.PI * lat1 / 180;
    const radlat2  = Math.PI * lat2 / 180;
    const theta    = lon1 - lon2;
    const radtheta = Math.PI * theta / 180;

    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515 * 1.609344;

    return dist;

  }

  public sanitizeUrl (url: string | undefined ): string {

    if (url?.startsWith("http://") || url?.startsWith("https://")) {
      return url;

    } else {
      return 'https://' + url;

    }

  }
  public shortUrl (longUrl: string | undefined ): string {

    try {

      // try some sanitizing
      longUrl = longUrl?.split(' ')[0];

      if (!longUrl) {
        return '';

      } else if (longUrl.startsWith("http//")) {
        longUrl = longUrl?.split('http//').filter(Boolean)[0];
        return (new URL('http://' + longUrl)).host;

      } else if (longUrl.startsWith("https//")) {
        longUrl = longUrl?.split('https//').filter(Boolean)[0];
        return (new URL('https://' + longUrl)).host;

      } else if (longUrl.startsWith("http://") || longUrl.startsWith("https://")) {
        return (new URL(longUrl)).host;

      } else {
        return (new URL('https://' + longUrl)).host

      }

    } catch (e) {
      console.warn('Helperservice.shortUrl.illegal', longUrl);
      return longUrl ?? '';

    }

  }

  public splitAbbreviation (searchitem: string): [string, string] {

    const p1 = searchitem.indexOf('(');
    const p2 = searchitem.indexOf(')');

    return [
      searchitem.slice(0     , p1).trim(),
      searchitem.slice(p1 + 1, p2).trim(),
    ];

  }

  public extractAbbreviation (searchitem: string): string {

    const p1 = searchitem.indexOf('(');
    const p2 = searchitem.indexOf(')');

    if (p1 > -1 && p2 > p1) {
      return searchitem.slice(0, p1).trim()
    } else {
      return searchitem;
    }

  }
  
  range (start=0, stop=start, step=1) {
    
    // similar to Python
  
    if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
        return [];
    }

    const result = [];
    
    for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
      result.push(i);
    }

    return result;
    
  }
  
  
  getScrollPercent(): number {
    
    // https://stackoverflow.com/questions/2387136/cross-browser-method-to-determine-vertical-scroll-percentage-in-javascript
    
    const 
      h = document.documentElement, 
      b = document.body,
      st = 'scrollTop',
      sh = 'scrollHeight';
      
    return (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
    
}

}
