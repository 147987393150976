
export function customParser(this: any) {

  const customMarker = new RegExp(`<ACCORDION></ACCORDION>`);

  function customTokenizer(eat: any, value: any, silent: any) {

    const keep = value.match(customMarker);

    if (!keep || keep.index !== 0) return;

    const add = eat('<HELP_SEARCH_BAR></HELP_SEARCH_BAR>');

    return add({
      type: 'customMarkdown',
      children: [],
      data: {},
    });

  }

  const Parser: any = this.Parser;

  // Append our custom block to the parser
  const blockTokenizers = Parser.prototype.blockTokenizers;
  const blockMethods = Parser.prototype.blockMethods;
  blockTokenizers.customBlock = customTokenizer;
  blockMethods.splice(blockMethods.indexOf('list') + 1, 0, 'customBlock');

}
