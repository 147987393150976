
<div class="page page-start">
  
  <!-- BÜHNE -->
  <pr-atom-buehne>
    <h1 class="drv-title-large" i18n="@@page.start.title.long">page.start.title.long</h1>
    <span i18n="@@page.start.subtitle">page.start.subtitle</span>
    <img src="assets/images/buehne-illu-min.opt.png" alt="" />
  </pr-atom-buehne>

  <!-- SEARCH -->
  <div drv-row class="drv-mt-sm--04 drv-mt-md--07 drv-mt-lg--08">
    <div drv-col [sm]="4">

      <pr-cell-search
        [hints]="resultMessages"
        [fachgruppen]="fachgruppen"
        (searchByString)="onSearchString($event)"
        (searchByIndikation)="onSearchIndikation($event)"
      ></pr-cell-search>

    </div>
  </div>

  <!-- INFOBOX -->
  <div drv-row class="drv-mt-sm--04 drv-mt-md--07 drv-mt-lg--08 drv-bg--ui-05 drv-col-sm-bleed--left drv-col-sm-bleed--right">

    <!-- HEADER -->
    <div drv-col [sm]="4">
      <h2 class="drv-mt-sm--04" i18n="@@page.start.infobox1.header">
        page.start.infobox1.header
      </h2>
    </div>

  <!-- PARAGRAPH I -->
    <div drv-col [sm]="4" [md]="{ span: 10, offset: 0 }" class="drv-mt-sm--04">
      <p i18n="@@page.start.infobox1.info.qualitaet">
        page.start.infobox1.info.qualitaet
      </p>
    </div>

  <!-- ICON TEXT LISTE -->
    <div drv-col [sm]="4" [md]="{ span: 4, offset: 0 }" class="drv-mt-sm--04">
      <div class="fxrow">
        <drv-icon type="burger-search"></drv-icon>
        <p i18n="@@page.start.infobox1.info.list.alle-reha">
          page.start.infobox1.info.list.alle-reha
        </p>
      </div>
    </div>

    <div drv-col [sm]="4" [md]="{ span: 4, offset: 0 }" class="drv-mt-sm--04">
      <div class="fxrow">
        <drv-icon type="eye"></drv-icon>
        <p i18n="@@page.start.infobox1.info.list.vergleichen">
          page.start.infobox1.info.list.vergleichen
        </p>
      </div>
  </div>

    <div drv-col [sm]="4" [md]="{ span: 4, offset: 0 }" class="drv-mt-sm--04">
      <div class="fxrow">
        <drv-icon type="book-preview"></drv-icon>
        <p i18n="@@page.start.infobox1.info.list.bewertungen">
          page.start.infobox1.info.list.bewertungen
        </p>
      </div>
    </div>

    <!-- PARAGRAPH II -->
    <div drv-col [sm]="4" [md]="{ span: 10, offset: 0 }" class="drv-mt-sm--04 drv-mb-sm--055">
      <p class="" i18n="@@page.start.infobox1.info.informieren">
        page.start.infobox1.info.informieren
      </p>
    </div>

  </div>


  <!-- WUNSCH UND WAHLRECHT -->
  <div drv-row class="drv-mt-sm--07 drv-mt-md--075">

    <div drv-col [sm]="4">
      <h2 i18n="@@page.start.infobox2.header">
        page.start.infobox2.header
      </h2>
    </div>

    <div drv-col [sm]="4" [md]="{ span: 6, offset: 0 }" class="drv-mt-sm--02">
      <img src="assets/images/photos/foto-start-stock.small.opt.jpg" width="1204" height="804" [alt]="trans.foto_alt" />
    </div>

    <div drv-col [sm]="4" [md]="{ span: 6, offset: 0 }" class="drv-mt-sm--01">
      <p i18n="@@page.start.infobox2.info.recht">
        page.start.infobox2.info.recht
      </p>
      <p class="drv-mt-sm--01">
        <drv-iconlink
          i18n="@@page.start.infobox2.info.recht.link.label"
          icon="info"
          (onClick)="openModal()"
          data-e2e="buttonModal"
        >
          page.start.infobox2.info.recht.link.label
        </drv-iconlink>
      </p>
    </div>

  </div>


  <!-- INFOS QUALUITÄTSSTANDARDS -->
  <div drv-row class="drv-mt-sm--07 drv-mt-md--075">

    <!-- HEADER -->
    <div drv-col [sm]="4">
      <h2 class="drv-mt-sm--01" i18n="@@page.start.tiles.header">
        page.start.tiles.header
      </h2>
    </div>

    <!-- KACHELN -->
    <div drv-col [sm]="4" class="drv-mt-sm--02">

      <div class="drv-tiles">
        <drv-tile
          pictogramType="union"
          [href]="trans.tile1_link_href"
          [title]="trans.tile1_header"
          [items]="trans.tiles[0]"
          [isExternal]="true"
          linkTarget="_blank"
        ></drv-tile>
        <drv-tile
          pictogramType="perfomance"
          [title]="trans.tile2_header"
          [items]="trans.tiles[1]"
          [route]="['/', 'de', 'qualitaet']"
        ></drv-tile>
        <drv-tile
          pictogramType="doctor"
          [href]="trans.tile4_link_href"
          [title]="trans.tile4_header"
          [items]="trans.tiles[3]"
          [isExternal]="true"
          linkTarget="_blank"
        ></drv-tile>
      </div>

    </div>
  </div>

  <!-- POPUP -->
  <drv-modal
    data-e2e="modal"
    [modalId]="modal.id"
    [modalTitle]="trans.modal_title"
    [closeButtonLabel]="trans.modal_button_label"
    (onClose)="modal.open = false"
  >
    <drv-modal-content i18n="@@page.start.modal.text">
      page.start.modal.text
    </drv-modal-content>
  </drv-modal>

</div>
