import { ChangeDetectorRef, Component, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Subscription } from 'rxjs';

import { I18n } from '@pr/core/i18n.module';
import { ApiService } from '@pr/core/api.service';
import { MetaService } from '@pr/core/meta.service';
import { StateService } from '@pr/core/state.service';
import { ConfigService } from '@pr/core/config.service';

interface IFAQItem {
  title: string
  text: string
  href: string
  label: string
}

@Component({
  selector: 'pr-page-qualitaet',
  templateUrl: './qualitaet.page.html',
  styleUrls: ['./qualitaet.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QualitaetPage implements OnDestroy {

  public trans = {
    'app_title':         $localize`:@@app.title:`,
    'title':             $localize`:@@app.page.qualitaet.title:`,
    'back_link':         $localize`:@@app.backlink.start.label:`,

    'link_alttext':      $localize`:@@page.qualitaet.link.alttext:`,
    'img_alttext':       $localize`:@@page.qualitaet.img.alttext:`,

    'faq_link1_href':    $localize`:@@page.qualitaet.accordion1.link.href:`,
    'faq_link2_href':    $localize`:@@page.qualitaet.accordion2.link.href:`,
    'faq_link3_href':    $localize`:@@page.qualitaet.accordion3.link.href:`,
    'faq_link4_href':    $localize`:@@page.qualitaet.accordion4.link.href:`,
    'faq_link5_href':    $localize`:@@page.qualitaet.accordion5.link.href:`,

    'faq_link1_label':   $localize`:@@page.qualitaet.accordion1.link.label:`,
    'faq_link2_label':   $localize`:@@page.qualitaet.accordion2.link.label:`,
    'faq_link3_label':   $localize`:@@page.qualitaet.accordion3.link.label:`,
    'faq_link4_label':   $localize`:@@page.qualitaet.accordion4.link.label:`,
    'faq_link5_label':   $localize`:@@page.qualitaet.accordion5.link.label:`,

    'faq_title1':        $localize`:@@page.qualitaet.accordion1.header:`,
    'faq_title2':        $localize`:@@page.qualitaet.accordion2.header:`,
    'faq_title3':        $localize`:@@page.qualitaet.accordion3.header:`,
    'faq_title4':        $localize`:@@page.qualitaet.accordion4.header:`,
    'faq_title5':        $localize`:@@page.qualitaet.accordion5.header:`,

    'faq_text1':         $localize`:@@page.qualitaet.accordion1.text:`,
    'faq_text2':         $localize`:@@page.qualitaet.accordion2.text:`,
    'faq_text3':         $localize`:@@page.qualitaet.accordion3.text:`,
    'faq_text4':         $localize`:@@page.qualitaet.accordion4.text:`,
    'faq_text5':         $localize`:@@page.qualitaet.accordion5.text:`,

    'bottom_href':       $localize`:@@page.qualitaet.bottom.link.href:`,
    'back_start_page':   $localize`:@@app.backlink.start.label:`,
    
    'list_messung_1':    $localize`:@@page.qualitaet.messung.punkt1:`,
    'list_messung_2':    $localize`:@@page.qualitaet.messung.punkt2:`,
    'list_messung_3':    $localize`:@@page.qualitaet.messung.punkt3:`,

  }

  public itemsFAQ: IFAQItem[] = [
    { title: this.trans.faq_title1, text: this.trans.faq_text1, href: this.trans.faq_link1_href, label: this.trans.faq_link1_label  },
    { title: this.trans.faq_title2, text: this.trans.faq_text2, href: this.trans.faq_link2_href, label: this.trans.faq_link2_label  },
    { title: this.trans.faq_title4, text: this.trans.faq_text4, href: this.trans.faq_link4_href, label: this.trans.faq_link4_label  },
    { title: this.trans.faq_title3, text: this.trans.faq_text3, href: this.trans.faq_link3_href, label: this.trans.faq_link3_label  },
    { title: this.trans.faq_title5, text: this.trans.faq_text5, href: this.trans.faq_link5_href, label: this.trans.faq_link5_label  },
  ];

  public markdown = '';

  private subscriber$: Subscription | undefined;

  constructor (
    public readonly config: ConfigService,
    public readonly i18n:   I18n,
    public readonly state:  StateService,
    private readonly meta:  MetaService,
    private readonly api:   ApiService,
    private readonly route: ActivatedRoute,
    private readonly cd:    ChangeDetectorRef,
  ) {

    this.meta.addTags('qualitaet');

    this.subscriber$ = this.route.data.subscribe((data: Data) => {

      const root   = this.config.environment.markdownroot;
      const locale = this.i18n.locale;
      const url    = `${root}/${locale}/${data['markdown'] as string}`;

      this.api.markdown(url).subscribe( markdown => {
        this.markdown = markdown;
        this.cd.detectChanges();
      });

    });

  }

  public ngOnDestroy (): void {
    this.subscriber$?.unsubscribe();
  }

}
