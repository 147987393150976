import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';

import { IErgFachabteilung } from '@pr/core/interfaces';
import { MapPopupAtom } from './map-popup.atom';

const DEBUG = false;

@Injectable({ providedIn: 'root' })
export class MapPopupService {

  constructor (
    private readonly injector: Injector,
    private readonly applicationRef: ApplicationRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver
  ) {}

  htmlPopup (einrichtung: IErgFachabteilung): HTMLElement  {

    // Create element
    const popup = document.createElement('popup-component');

    // Create the component and wire it up with the element
    const factory = this.componentFactoryResolver.resolveComponentFactory(MapPopupAtom);
    const popupComponentRef = factory.create(this.injector, [], popup);

    // Attach to the view so that the change detector knows to run
    this.applicationRef.attachView(popupComponentRef.hostView);

    // Set the message
    popupComponentRef.instance.item = einrichtung;

    DEBUG && console.log('MapPopupService', einrichtung);
    
    // Return rendered Component
    return popup;

  }

}
