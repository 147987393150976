import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileListItem } from '@drv-ds/drv-design-system-ng';

let self: any;

@Component({
  selector: 'pr-atom-upload-files',
  templateUrl: './upload-files.atom.html',
  styleUrls: ['./upload-files.atom.scss']
})
export class UploadFilesAtom {

  @Input() public multiple: boolean = false;
  @Input() public label = 'Label';

  @Output() file: EventEmitter<string> = new EventEmitter<string>();

  constructor() { self = this }

  public files = [];
  public fileList: FileListItem[] = [];
  public model: any;

  readFile (e: any) {

    const file: File = e.target.files[0]; 
    const fileReader: FileReader = new FileReader();

    fileReader.onloadend = function(e){
      console.log('readFile', file.name, file.size, fileReader?.result?.toString().length);
      self.file.emit(fileReader.result);
    }

    fileReader.readAsText(file);


  }

}
