import { Component, Input } from '@angular/core';
import { IconType } from '@drv-ds/drv-design-system-ng/lib/types/icons';
import { ConfigService } from '@pr/core/config.service';
import { I18n } from '@pr/core/i18n.module';
import { IFeature } from '@pr/core/interfaces';

const DEBUG = false;

@Component({
  selector: 'pr-atom-pictogram-list',
  templateUrl: './pictogram-list.atom.html',
  styleUrls: ['./pictogram-list.atom.scss']
})
export class PictogramListAtom {

  public data: IFeature[] = [];

  @Input() set features (features: IFeature[] | undefined) {
    
    const mapper = this.config.pictogramMapper;

    if (features && features.length) {

      this.data = features.filter( (item: IFeature) => item.childs.length)

      this.data.forEach( (entry: IFeature) => {
        const icon = mapper[this.i18n.translate(entry.label)];
        if (icon) {
          entry.icon = icon as IconType;
        } else {
          entry.icon = 'family';
          DEBUG && console.log('pictogramMapper.notfound', entry.label)
        }
      });

    }

    DEBUG && console.log('featureMapper', this.data, features?.length)

  };

  constructor ( 
    private readonly config: ConfigService, 
    public readonly i18n: I18n 
  ) { }

}
