<drv-collapsible
  buttonText=""
  collapsibleOnly="only-sm"
  [fullWidthStretch]="true"
  [activeStateTemplate]="activeStateTemplate"
>
  <ng-content select="h2"></ng-content>
  <ng-content select="pr-cell-filtercollection"></ng-content>
  <ng-content select="drv-button"></ng-content>
  <ng-content select="p"></ng-content>
</drv-collapsible>

<ng-template #activeStateTemplate>

  <!-- MOBILE FILTER COLLAPSIBLE BUTTON -->
  <div class="button fxrow w-100">
    
    <span class="drv-btn__text">
      <h2 class="di">{{ trans.filter_possibilities }}</h2>
    </span>
    
    <!-- SPACER -->
    <div class="fxgrow" style="flex: 1rem;"></div>
    
    <!-- COUNT FILTERS, BUTTON CLOSED ONLY -->
    @if (!isOpen) {
      <ng-content select="span.drv-btn__counter"></ng-content>
    }

  </div>

</ng-template>
