
<ul role="list">
  @for (item of data; track item) {
    <li>
      <div class="fxrow">
        <drv-icon
          [type]="item.icon"
        ></drv-icon>
        <div>
          <span>
            {{ i18n.translate(item.label) }}
          </span>
          @if (item.childs.length) {
            <ul role="list">
              @for (feature of item.childs; track feature) {
                <li>
                  {{ i18n.translate(feature) }}
                </li>
              }
            </ul>
          }
        </div>
      </div>
    </li>
  }
</ul>
