import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { I18n } from '@pr/core/i18n.module';
import { StateService } from '@pr/core/state.service';
import { ConfigService } from '@pr/core/config.service';
import { ApiService } from '@pr/core/api.service';
import { MetaService } from '@pr/core/meta.service';

const DEBUG = false;

@Component({
  selector: 'pr-page-impressum',
  templateUrl: './impressum.page.html',
  styleUrls: ['./impressum.page.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ImpressumPage implements OnDestroy {

  public trans = {
    'app_title':        $localize`:@@app.title:`,
    'back_start_page':  $localize`:@@app.backlink.start.label:`,
    'title':            $localize`:@@app.page.impressum.title:`
  };

  public markdown = '';

  private subscriber$: Subscription | undefined;

  constructor (
    public readonly i18n:     I18n,
    public readonly config:   ConfigService,
    public readonly state:    StateService,
    private readonly meta:    MetaService,
    private readonly route:   ActivatedRoute,
    private readonly api:     ApiService,
    private readonly cd:      ChangeDetectorRef,
  ) {

    this.meta.addTags('impressum');

    this.subscriber$ = this.route.data.subscribe(data => {

      const root   = this.config.environment.markdownroot;
      const locale = this.i18n.locale;
      const url    = `${root}/${locale}/${data['markdown'] as string}`;

      this.api.markdown(url).subscribe( markdown => {
        this.markdown = markdown;
        this.cd.detectChanges();
      });

    });

  }

  public ngOnDestroy (): void {
    this.subscriber$?.unsubscribe();
  }

  // from markdown helpers
  onFile (markdown: string | ArrayBuffer | null) {
    DEBUG && console.log('PageImpressum.onFile', String(markdown).length);
    this.markdown = String(markdown);
  }

}
