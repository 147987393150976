import { Component } from '@angular/core';

@Component({
  selector: 'pr-atom-buehne',
  templateUrl: 'buehne.atom.html',
  styleUrls: ['./buehne.atom.scss']
})
export class BuehneAtom {

  public width: number = window.innerWidth;

  public trans = {
    'app_title':          $localize`:@@app.title:`,
  };

  onResize () {
    this.width = window.innerWidth;
  }

}
