import { DOCUMENT } from "@angular/common";
import { Directive, Output, EventEmitter, ElementRef, Inject, OnDestroy } from "@angular/core";

// https://stackoverflow.com/questions/37962701/emit-event-from-directive-to-parent-element
// https://stackoverflow.com/questions/67272516/intersection-observer-in-angular

export type TScrollInfo = [
  boolean, 
  'up' | 'down'
];

@Directive({
  selector: '[pr-intersection]',
})
export class IntersectionObserverDirective implements OnDestroy {
  
  private observer: IntersectionObserver;
  private scrollPosition  = 0;
  private scrollDirection: 'up' | 'down' = 'down';

  private callback: ConstructorParameters<typeof IntersectionObserver>[0] = 
    (entries) => entries
      .forEach((entry) => {
        this.intersection.emit([entry.isIntersecting, this.scrollDirection]);
      })
    ;
    
  @Output() intersection = new EventEmitter<TScrollInfo>();

  constructor(
    public el: ElementRef,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.observer = new IntersectionObserver(this.callback, { 
      rootMargin: '20px', 
      threshold:  0.5, 
      root:       null
    });
    this.observer.observe(this.el.nativeElement);
    this.document.addEventListener('scroll', this.onContentScrolled);
  }

  onContentScrolled = (e: any) => {
    
    this.scrollDirection = window.scrollY > this.scrollPosition
      ? 'down'
      : 'up'
    ;
    
    this.scrollPosition = window.scrollY;
    
  }
    
  public ngOnDestroy() {
    this.document.removeEventListener('scroll', this.onContentScrolled);
    this.observer.unobserve(this.el.nativeElement);
  }

}
