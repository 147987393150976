import { Directive, ElementRef, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { AppService } from "src/app/app.service";

// directive works on drv-tile and 
// adds class 'horizontal', for md, lg and greater breakpoints
// class defined in styles.scss

@Directive({
  selector: 'drv-tile[pr-single-tile]',
})
export class SingleTileDirective implements OnInit, OnDestroy {
    
  private subscriber$: Subscription | undefined;
  
  constructor (
    private readonly tileRef: ElementRef,
    private readonly app: AppService
  ) { }

  public ngOnDestroy (): void {
    this.subscriber$?.unsubscribe();
  }
  
  public ngOnInit (): void {
    
    this.subscriber$ = this.app.media$.subscribe( (breakpoint) => {
      
      // remove all and then add specific
      const classlist = this.tileRef.nativeElement.classList;
      classlist.remove("horizontal", "ts", "sm", "md", "lg", "xlg", "max");
      if (!['ts', 'sm'].includes(breakpoint ?? '')) {
        classlist.add("horizontal", breakpoint);
      }
      
    });
    
  }    

}
