import { Component } from '@angular/core';

@Component({
  selector: 'pr-cell-button-tile',
  templateUrl: './button-tile.cell.html',
  styleUrl: './button-tile.cell.scss'
})
export class ButtonTileCell {

}
