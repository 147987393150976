
import { ChangeDetectionStrategy, Component, OnInit, ViewChild, viewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import { MetanavLinkItem, MainNavLinkItem, HeaderComponent  } from '@drv-ds/drv-design-system-ng';

import { I18n } from '@pr/core/i18n.module';
import { ConfigService } from '@pr/core/config.service';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { TBreakpoint } from '@pr/core/interfaces';


@Component({
  selector: 'pr-cell-header',
  template: `
    <drv-header
      [isLoggedIn]="false"
      [logoAltText]="trans.logo_alt"
      [headerLogoPath]="headerLogoPath"
      [headerLogoSmallPath]="headerLogoSmallPath"
      [metaItems]="headerItems"
      [menuButtonLabel]="trans.menu_label"
      [logoLinkRoute]="['reset']"
      (onItemClick)=onItemClick($event)
    ></drv-header>`,
  styleUrls: ['./header.cell.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class HeaderCell implements OnInit {

  public trans = {
    helpButtonLabel: $localize`:@@header.hilfe.button.label:`,
    helpButtonLink:  $localize`:@@header.hilfe.button.page:`,
    menu_label:      $localize`:@@header.menu.label:`,
    logo_alt:        $localize`:@@header.logo.alt:`,
  };

  public headerLogoPath = '';
  public headerLogoSmallPath = '';

  // TODO: MainNavLinkItem
  public headerItems: MetanavLinkItem [] = [];
  
  private readonly itemsDesktop: MetanavLinkItem [] = [];
  private readonly itemsMobile: MetanavLinkItem  [] = [];
  
  private subscriber$: Subscription | undefined;
  
  // @ViewChild(HeaderComponent) private compHeader: HeaderComponent | undefined;

  constructor (
    private readonly config: ConfigService,
    private readonly router: Router,
    public readonly i18n: I18n,
    private readonly app: AppService
  ) {

    this.itemsDesktop =  [
      {
        htmltitle:  $localize`:@@header.link.gebaerdensprache.title:`,
        text:       $localize`:@@header.link.gebaerdensprache.label:`,
        href:       $localize`:@@header.link.gebaerdensprache.href:`,
        icon:       'sign-language',
        htmltarget: '_blank',
      },
      {
        text:       $localize`:@@header.link.leichtesprache.label:`,
        htmltitle:  $localize`:@@header.link.leichtesprache.title:`,
        href:       $localize`:@@header.link.leichtesprache.href:`,
        icon:       'easy-language',
        htmltarget: '_blank',
      },
      {
        text:       $localize`:@@header.link.hilfe.label:`,
        htmltitle:  $localize`:@@header.link.hilfe.title:`,
        route:      ['/', 'de', $localize`:@@header.link.hilfe.page:`],
        icon:       'question-mark',
      },
    ];

    this.itemsMobile =  [ // Qual, Hilfe, DGS, LS
      {
        text:       $localize`:@@header.link.quality.label:`,
        htmltitle:  $localize`:@@header.link.quality.title:`,
        route:      ['/', 'de', $localize`:@@header.link.quality.page:`],
        icon:       $localize`:@@header.link.quality.icon:`,
      },
      {
        text:       $localize`:@@header.link.hilfe.label:`,
        htmltitle:  $localize`:@@header.link.hilfe.title:`,
        route:      ['/', 'de', $localize`:@@header.link.hilfe.page:`],
        icon:       'question-mark',
      },
      {
        htmltitle:  $localize`:@@header.link.gebaerdensprache.title:`,
        text:       $localize`:@@header.link.gebaerdensprache.label:`,
        href:       $localize`:@@header.link.gebaerdensprache.href:`,
        icon:       'sign-language',
        htmltarget: '_blank',
      },
      {
        text:       $localize`:@@header.link.leichtesprache.label:`,
        htmltitle:  $localize`:@@header.link.leichtesprache.title:`,
        href:       $localize`:@@header.link.leichtesprache.href:`,
        icon:       'easy-language',
        htmltarget: '_blank',
      }
    ];

  }

  public ngOnInit (): void {
    this.updateLinks();
    this.subscriber$ = this.app.media$.subscribe(this.update.bind(this));
  }

  private update (breakpoint: TBreakpoint | null): void {
    
    // hier include
    if (['ts', 'sm', 'md'].includes(breakpoint || '')) {
      this.headerItems = this.itemsMobile.slice();
      
    } else {      
      this.headerItems = this.itemsDesktop.slice();
      
    }
  }

  private updateLinks (): void {

    const base = this.config.environment.basehref;

    this.headerLogoPath      = `assets/images/logos/drv-logo--large.svg`;
    this.headerLogoSmallPath = `assets/images/logos/drv-logo--small@2x.half.png`;

    let page: string;
    if (base === '/') {
      page = location.pathname.split('/').filter(Boolean).slice(1, 2)[0];

    } else {
      page = location.pathname.split('/').filter(Boolean).slice(2, 3)[0];

    }

    const menuText = (locale: string): string => {
      // TODO: This is so wrong
      return (
        locale === 'de' ? $localize`:@@header.locale.de.long:` :
        locale === 'en' ? $localize`:@@header.locale.en.long:` :
        locale === 'xx' ? $localize`:@@header.locale.xx.long:` :
        'unknown'
      );
    };

    // add more languages, if needed
    if (this.config.availableLocale.length > 1) {
      this.config.availableLocale.forEach( locale => {

        this.headerItems.push({
          text:  menuText(locale),
          htmltarget: '',
          htmltitle: $localize`:@@header.hilfe.button.title:`,
          route: ['de', page],
          icon: 'easy-language'
        })

      })
    }

  }

  public onItemClick (item: MetanavLinkItem | MainNavLinkItem): void {

    // DS Hamburger Menu ads strange class
    // GRRRRR
    // document.body.classList.remove("drv-prevent-scroll");
      
    // if (item.route) {
    //   // TODO: detect lang switch, lang interceptor to reload
    //   void this.router.navigate(item.route, item.routeExtras);
    //   location.reload();

    // } else {
    //   console.error('onItemClick', 'no route', item);

    // }

  }

}
