import { ViewportScroller } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { NotificationService } from '@drv-ds/drv-design-system-ng';

import { ConfigService } from './config.service';


// https://medium.com/agilix/provide-different-angular-service-based-on-condition-at-runtime-6846d6c9f33
// https://stackoverflow.com/questions/39038791/inheritance-and-dependency-injection

export function ErrorHandlerFactory (
    config: ConfigService,
    injector: Injector,
    viewportScroller: ViewportScroller
  ) {

  const useDevService = false && config.environment.development

  // console.log('%cErrorHandler', 'color: darkorange; font-weight: 800', useDevService ? 'DEV' : 'PROD' );

  return useDevService
    ? new DevErrorService(injector)
    : new ProdErrorService(injector, viewportScroller)
  ;

}

export abstract class PRErrorHandler {

  abstract handleError(error: unknown): void;
  protected readonly notification: NotificationService;

  constructor(injector: Injector){
    this.notification = injector.get(NotificationService);
  }

}


@Injectable({
  providedIn: 'root'
})
export class ProdErrorService extends PRErrorHandler implements ErrorHandler {

  constructor (
    private readonly injector: Injector,
    private readonly viewportScroller: ViewportScroller,
  ) {
    super(injector);
  }

  handleError(error: any) {

    console.error('ErrorService.handleError', error);

    if (error instanceof HttpErrorResponse) {

      this.notification.clear();

      // happens with markdown
      if (error?.error?.text) {
        this.notification.update({
          id:'prAppNotification',
          variant: 'alert',
          title: `${error.status} - HTTP Fehler aufgetreten`,
          text: JSON.stringify(error.error ?? 'error.error unknown', null, 2),
        });

      // all other
      } else {
        this.notification.update({
          id:'prAppNotification',
          variant: 'alert',
          title: `${error.status} - HTTP Fehler aufgetreten`,
          text: JSON.stringify(error.message ?? 'error.message unknown', null, 2),
        });

      }

    } else if (error instanceof TypeError) {
      this.notification.update({
        id:'prAppNotification',
        variant: 'alert',
        title: 'TypeError: ' + error.message,
        text: String(error.stack),
      });

    } else {

      this.notification.update({
        id:'prAppNotification',
        variant: 'alert',
        title: 'Globaler Fehler aufgetreten',
        text: JSON.stringify(error, null, 2),
      });

    }

    // scroll up, so user sees message
    this.viewportScroller?.scrollToPosition([0, 0]);

  }

}

@Injectable({
  providedIn: 'root'
})
export class DevErrorService extends PRErrorHandler{

  constructor ( injector: Injector ) {
    super(injector);
  }

  handleError(error: unknown) {
    // just throw and log
    console.error('ErrorService.handleError', error);
    throw error;
  }

}
