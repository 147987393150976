import { Component, EventEmitter, Input, Output } from '@angular/core';
import { I18n } from '@pr/core/i18n.module';
import { INeighbour } from '@pr/core/interfaces';
import { StateService } from '@pr/core/state.service';

const DEBUG = false;

@Component({
  selector: 'pr-atom-browse-details',
  template: `
    <div drv-row>
      <!-- NO COL -->
      <div class="fxrow w-100 fxwrap" style="align-items:flex-end;">
        @if (!!prev?.id) {
          <drv-button
            style="flex-shrink: 1"
            [label]="trans.label_left"
            kind="tertiary"
            type="anchor"
            iconPosition="left"
            iconType="arrow-left"
            backgroundUI="light"
            (onClick)="goprev()"
          ></drv-button>
        }
        <div class="fxgrow"></div>
        @if (!!next?.id) {
          <drv-button
            style="align-self: flex-end; flex-shrink: 1"
            [label]="trans.label_right"
            kind="tertiary"
            type="anchor"
            backgroundUI="light"
            iconPosition="right"
            iconType="arrow-right"
            (onClick)="gonext()"
          ></drv-button>
        }
      </div>
      <!-- NO COL -->
    </div>
    `,
  styleUrls: ['./browse-details.atom.scss']
})
export class BrowseDetailsAtom {

  @Input() public next: INeighbour | undefined;
  @Input() public prev: INeighbour | undefined;
  @Output() browse: EventEmitter<INeighbour> = new EventEmitter<INeighbour>();

  public trans = {
    label_left:  $localize`:@@page.detail.prev.fachabteilung:`,
    label_right: $localize`:@@page.detail.next.fachabteilung:`,
  };

  constructor (
    public readonly  i18n: I18n,
    public readonly  state: StateService,
  ) { }

  public routeExtras (neighbour: INeighbour| undefined) {
    // return { queryParams: { ...this.state.queryparams(), id_fach: neighbour?.id } };
  }

  public gonext (): void {
    DEBUG && console.log('Atom.browse.next', this.next);
    this.browse.emit(this.next);
  }

  public goprev (): void {
    DEBUG && console.log('Atom.browse.prev', this.prev);
    this.browse.emit(this.prev);
  }

}
