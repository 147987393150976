import { Component, Input } from '@angular/core';
import { IErgFachabteilung } from '@pr/core/interfaces';

@Component({
  selector: 'pr-atom-map-popup',
  template: `
    <div class="map-popup" style="min-width: 300px">

      <a [routerLink]="item?.detailRoute || []">{{ item?.einrichtungname }}</a>
      
      <div class="map-popup-Strasse fplus">{{ item?.strasse }} {{ item?.hausnummer }}</div>
      <div class="map-popup-Ort fplus">{{ item?.plz }} {{ item?.ort }}</div>

      <div class="fasc-name">{{ item?.name_ergebnis }}</div>
      <div class="fasc-spec">{{ item?.spec_ergebnis }}</div>

    </div>
  `,
})
export class MapPopupAtom {

  @Input() item: IErgFachabteilung | null = null;

}
