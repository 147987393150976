import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Subject, takeUntil, tap} from 'rxjs';

import { TBreakpoint } from '@pr/core/interfaces';
import { ConfigService } from '@pr/core/config.service';

const DEBUG = false;

const INITMEDIA = (
  window.innerWidth <  320 ?  'ts' :
  window.innerWidth <  600 ?  'sm' :
  window.innerWidth < 1025 ?  'md' :
  window.innerWidth < 1440 ?  'lg' :
  window.innerWidth < 1800 ? 'xlg' :
    'max'
);

// https://drvd-ds.dev.ibmix.systems/?path=/docs/tokens-grid--angular#breakpoints
enum EBreaks {
  ts  =  319,
  sm  =  599,
  md  = 1024,
  lg  = 1439,
  xlg = 1799, 
}

const epsilon = 1;

const MatchMediaQueries = [
  { breakpoint: 'ts',  query: `(max-width: ${ EBreaks.ts            }px)`},
  { breakpoint: 'sm',  query: `(min-width: ${ EBreaks.ts  + epsilon }px) and (max-width: ${ EBreaks.sm  }px)`},
  { breakpoint: 'md',  query: `(min-width: ${ EBreaks.sm  + epsilon }px) and (max-width: ${ EBreaks.md  }px)`},
  { breakpoint: 'lg',  query: `(min-width: ${ EBreaks.md  + epsilon }px) and (max-width: ${ EBreaks.lg  }px)`},
  { breakpoint: 'xlg', query: `(min-width: ${ EBreaks.lg  + epsilon }px) and (max-width: ${ EBreaks.xlg }px)`},
  { breakpoint: 'max', query: `(min-width: ${ EBreaks.xlg + epsilon }px)`},
] as { breakpoint: TBreakpoint, query: string }[];

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private readonly destroy$: Subject<void> = new Subject<void>();
  public media$ = new BehaviorSubject<TBreakpoint | null>(INITMEDIA);
  
  constructor (private readonly config: ConfigService ) { }

  public initialize (): void {

    DEBUG && console.log('media$: ', INITMEDIA)

    const queries    = MatchMediaQueries.map( q => window.matchMedia(q.query));
    const matches    = queries.map( q => q.matches );
    const breakpoint = MatchMediaQueries[matches.findIndex(Boolean)].breakpoint;

    fromEvent(queries, 'change')
      .pipe(
        takeUntil<any>(this.destroy$),
        tap<MediaQueryListEvent>( event => this.updateMedia(event) )
      )
      .subscribe()
    ;

    // TODO: Solve Initial Null Problem
    https://blog.lacolaco.net/2020/02/async-pipe-initial-null-problem-en/
    this.media$.next(breakpoint);
    
  }

  private updateMedia (event: MediaQueryListEvent) {

    if (event.matches) {
      const breakpoint = MatchMediaQueries.find( q => q.query === event.media )?.breakpoint ?? null;
      this.media$.next(breakpoint);
      if (this.config.isTestDomain) {
        document.body.style.backgroundColor = (breakpoint === 'ts'
          ? '#fdd'
          : '#fff'
        );
      }
      DEBUG && console.log('AppService.updateMedia', breakpoint);
    }

  }

  public destroy () {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
